import { Lightbulb, ExclamationTriangleFill } from "react-bootstrap-icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useSpaceByIndex } from "../../../store/spaces/spacesSlice";
import Box from "../../../components/Box";
import { ButtonGroup, ClickButton, InternalLinkButton } from "../../../components/Buttons";
import CmdCommand from "../../../components/CmdCommand";
import Heading from "../../../components/Heading";
import { TabMenuSimple } from "../../../components/TabMenu";
import { useToast } from "../../../components/Toast/Toasts";
import Quote from "../../../components/Quote";
import StartHubParams from "./StartHubParams";
import StartHubConfig from "./StartHubConfig";
import { useAppDispatch } from "../../../store";
import { createSpaceAccessKey } from "../../../store/accessKeys/accessKeySlice";
import { useSpaceContext } from "../../SpacesPage";
import { getPlatformConfig, getHubConfigFile } from "./hubConfig";
import BootstrapIcon from "../../../components/BootstrapIcon";
import { useAccessKeysBySpaceId } from "../../../store/accessKeys/accessKeysDataSlice";
import { ACCESS_KEY_LIMIT } from "../../../utils/constants";

const ConnectHubGuide: React.FC = () => {
    const { space } = useSpaceContext();
    const dispatch = useAppDispatch();
    const [isHidden, setIsHidden] = useState(true);
    const [apiKey, setApiKey] = useState("GENERATED_API_KEY");
    const platformConfig = getPlatformConfig(space?.id, apiKey);
    const hubConfigFile = getHubConfigFile(platformConfig);
    const { accessKeys } = useAccessKeysBySpaceId(space.id);
    const createKey = createSpaceAccessKey({ spaceId: space.id });
    const showToast = useToast();
    const generateKey = async () => {
        try {
            const response = await dispatch(createKey).unwrap();

            showToast({
                text: "The access key was successfully generated",
                variant: "success"
            });
            setApiKey(response.accessKey);
        } catch (err: any) {
            console.error("Subscription info cannot be retrieved", err);

            showToast({
                text: "The was an error when generate access key",
                variant: "error"
            });
        }
        setIsHidden(false);
    };

    return (
        <Box outline="shadow">
            <div className="hub-add">
                <Heading level="h2" marginBottom={2} text="Self-Hosted Hub Configuration"/>
                <div className="hub-add__start">
                    <p className="body-1 margin--t-1">
                        Instructions on how to connect on premise Hub (Self Hosted Hub) to Scramjet Cloud Platform. {" "}
                        The below command-lines must be executed in your terminal.
                    </p>
                    <p className="body-2 margin--t-1">
                        <Lightbulb size={15} /> Please go to {" "}
                        <Link to="/cloud-platform-cli">CLI Settings</Link> if the environment needs to be set up.
                    </p>
                    <ol className="list">
                        <li>
                            <Heading
                                level="h3"
                                marginTop={2}
                                marginBottom={1}
                                className="subtitle-1"
                                text="Install Scramjet Transform Hub using CLI"
                            />
                            <CmdCommand command="npm i -g @scramjet/sth" />
                        </li>
                        <li>
                            <Heading
                                level="h3"
                                marginTop={2}
                                marginBottom={1}
                                className="subtitle-1"
                                text="Start and register Self Hosted Hub"
                            />
                            <div className="hub-add__key">
                                <p className="body-1">
                                    The "Generate CLI Commands" button will generate an access key {" "}
                                    and combine it with a <code>sth</code> command.
                                </p>
                                <p>
                                    Click the button in order to retrieve a ready-to-use command {" "}
                                    that will start Self Hosted Hub.
                                </p>
                                <ButtonGroup className="margin--t-2 margin--b-2">
                                    <ClickButton text="Generate CLI Commands" variant="secondary" onClick={() => generateKey()} disabled={accessKeys.length === ACCESS_KEY_LIMIT} />
                                </ButtonGroup>
                                {accessKeys.length === ACCESS_KEY_LIMIT && (
                                    <Quote marginBottom={1} icon={<ExclamationTriangleFill />} text={<>Keys limit has been reached. Please delete a key in <Link to="/cloud-platform-cli">CLI Settings</Link> or use the already generated one.</>} variant="error" />
                                )}
                                <div hidden={isHidden}>
                                    <Quote marginBottom={2} icon={<ExclamationTriangleFill size={25}/>}
                                        text={<>Please make sure to <strong>save</strong> {" "}
                                        the Generated Access Key on your drive for future reuse.</>}
                                        variant="warning"/>
                                    <p className="margin--b-2 body-1">
                                        Manage your access keys in <Link to="/cloud-platform-cli">"CLI Settings section"</Link>
                                    </p>
                                    <TabMenuSimple
                                        variant="transparent"
                                        orientation="horizontal"
                                        tabList={[
                                            "Command using parameters",
                                            "Command using config"
                                        ]}
                                        tabPanels={[
                                            <StartHubParams platform={platformConfig}/>,
                                            <StartHubConfig hubConfig={hubConfigFile}/>
                                        ]}
                                    />
                                </div>
                            </div>
                        </li>
                    </ol>
                </div>
                <div className="hub-add__read border--t">
                    <p className="body-1 margin--t-1">
                        Read more about  {" "}
                        <a target="_blank" href="https://docs.scramjet.org/platform/self-hosted-installation">
                            Self Hosted Hub
                        </a>
                    </p>
                    <p className="margin--t-2">
                        Are you into Raspberry PI? We encourage you to{" "}
                        <a href="https://github.com/scramjetorg/raspberry-pi-dash" target="_blank">try our RPI example</a>!
                    </p>
                </div>
            </div>
            <ButtonGroup className="hub-add__buttons" align="right">
                <InternalLinkButton
                    text="Go to Space"
                    variant="secondary" margin="margin--t-2"
                    icon={<BootstrapIcon ico="dashboard" size={15} fill="icon-primary" />}
                    to="/space/hubs"/>
            </ButtonGroup>
        </Box>
    );
};

export default ConnectHubGuide;
