import { FileEarmarkCodeFill, Server } from "react-bootstrap-icons";
import Box from "../../../components/Box";
import Status from "../../../components/Status";
import "./Hub.scss";
import HubPopover from "./HubPopover";

const Hub: React.FC<HubProps> = ({
    id,
    isConnectionActive,
    instancesNumber,
    sequencesNumber,
    selfHosted,
    healthy,
    dataQA,
}) => {
    const hubType = selfHosted ? "Self Hosted" : "Scramjet Cloud";
    const HealthStatus = <Status variant="health" status={healthy} />;
    const ConnectionStatus = <Status variant="connection" status={isConnectionActive} />;
    const HubStatus = selfHosted ? ConnectionStatus : HealthStatus;

    return (
        <Box
            className="hub"
            variant="item"
            outline="solid"
            linkTo={`/space/hubs/${id}/basic-info`}
            title={hubType}
            titleChildren={HubStatus}
            dataQA={dataQA}
        >
            <HubPopover>
                <table className="hub__table">
                    <tbody>
                        <tr>
                            <td colSpan={2} className="subtitle-2">
                                ID: {id}
                            </td>
                        </tr>
                        <tr className="body-1">
                            <td>Sequences</td>
                            <td align="center">
                                <FileEarmarkCodeFill className="icon--seq" size="2rem" /> {sequencesNumber}
                            </td>
                        </tr>
                        <tr className="body-1">
                            <td>Instances</td>
                            <td align="center">
                                <Server className="icon--inst" size="2rem" /> {instancesNumber}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </HubPopover>
        </Box>
    );
};

export default Hub;
