import { useSelector } from "react-redux";
import fastDeepEqual from "fast-deep-equal";
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getMiddlewateClient } from "../../components/MiddlewareClientProvider";
import { fetchSpaceAccessKeysData } from "./accessKeysDataSlice";
import { sendEvent } from "../../utils/gtmEvents";

const sliceName = "accessKey";
const accessKeyAdapter = createEntityAdapter<AccessKey>({
    selectId: accessKey => accessKey.accessKey,
});
const initialState: AccessKeyState = accessKeyAdapter.getInitialState({ status: {} });
const selectSelf = (state: RootState): AccessKeyState => state[sliceName];

export const { selectAll: selectAccessKey, selectById: selectAccessKeyById } =
    accessKeyAdapter.getSelectors<RootState>(selectSelf);

export const selectAccessKeysState = (spaceId: SpaceId) =>
    createSelector(selectSelf, state => {
        return state.status[spaceId];
    });

export const createSpaceAccessKey = createAsyncThunk<
    AccessKey,
    { spaceId: SpaceId; description?: string },
    { state: RootState }
>(`${sliceName}/createKeyBySpaceId`, async ({ spaceId, description }, { dispatch }) => {
    sendEvent("console_generate_hub_key");

    const spaceAccessKey = await getMiddlewateClient().createAccessKey(spaceId, { description });

    dispatch(fetchSpaceAccessKeysData({ spaceId, refetch: true }));

    return spaceAccessKey as AccessKey;
});

export const accessKeySlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(createSpaceAccessKey.pending, (state, action) => {
                state.status[action.meta.arg.spaceId] = "pending";
            })
            .addCase(createSpaceAccessKey.rejected, (state, action) => {
                state.status[action.meta.arg.spaceId] = "rejected";
            })
            .addCase(createSpaceAccessKey.fulfilled, (state, action) => {
                accessKeyAdapter.addOne(state, action.payload);
            });
    },
});

export const useAccessKeyBySpaceId = (spaceId: SpaceId) => {
    const accessKey = useSelector(selectAccessKeysState(spaceId), fastDeepEqual);

    return { accessKey };
};

export default accessKeySlice.reducer;
