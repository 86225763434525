import { createAsyncThunk, createSelector, createSlice } from "@reduxjs/toolkit";
import { ClientUtils } from "@scramjet/client-utils";
import { middlewareUrl } from "../../components/MiddlewareClientProvider";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "..";

const sliceName = "promoCodes";
const initialState: PromoCodesState = {
    status: "idle",
    coupon: null,
    discount: null,
};
const selectSelf = (state: RootState): PromoCodesState => state[sliceName];

export const selectDiscount = () =>
    createSelector(selectSelf, state => {
        return state.discount;
    });

export const fetchAllPromoCodes = createAsyncThunk<
    { discount: PaymentDiscount | null },
    undefined,
    { state: RootState }
>(
    `${sliceName}/fetchAllPromoCodes`,
    async () => {
        const clientUtils = new ClientUtils(middlewareUrl);
        const paymentsUser = await clientUtils.get<PaymentsUser>("/api/v1/payment-user");
        const userDiscount = paymentsUser?.discount ?? null;

        return { discount: userDiscount };
    }
);

export const applyPromoCode = createAsyncThunk<
    { discount: PaymentDiscount | null },
    { promocode: string },
    { state: RootState }
>(
    `${sliceName}/applyPromoCode`,
    async ({ promocode }, { rejectWithValue }) => {
        try {
            const clientUtils = new ClientUtils(middlewareUrl);
            const paymentsUser = await clientUtils.post<PaymentsUser>(
                `/api/v1/redeem-promocode/${promocode}`,
                {},
                {},
                { json: true, parse: "json" }
            );
            const userDiscount = paymentsUser?.discount;

            return { discount: userDiscount || null };
        } catch (e) {
            console.error(e);

            throw rejectWithValue("Invalid or expired code.");
        }
    }
);

export const promoCodesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllPromoCodes.pending, state => {
                state.status = "pending";
            })
            .addCase(fetchAllPromoCodes.fulfilled, (state, action) => {
                state.status = "fulfilled";
                state.discount = action.payload.discount;
            })
            .addCase(fetchAllPromoCodes.rejected, state => {
                state.status = "rejected";
            })
            .addCase(applyPromoCode.pending, state => {
                state.status = "pending";
            })
            .addCase(applyPromoCode.fulfilled, (state, action) => {
                state.status = "fulfilled";
                state.discount = action.payload.discount;
            })
            .addCase(applyPromoCode.rejected, state => {
                state.status = "rejected";
            });
    },
});

export function usePromoCodes() {
    const dispatch = useDispatch();

    function initializePromoCode() {
        dispatch(fetchAllPromoCodes());
    }

    const discount = useSelector(selectDiscount());

    return { initializePromoCode, discount };
}

export default promoCodesSlice.reducer;

