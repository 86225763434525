import { useEffect } from "react";
import { useSelector } from "react-redux";
import fastDeepEqual from "fast-deep-equal";
import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, useAppDispatch } from "..";
import { useSpaces } from "../spaces/spacesSlice";
import { getMiddlewateClient } from "../../components/MiddlewareClientProvider";
import AccessKeysDataMap from "./AccessKeysDataMap";

const sliceName = "accessKeysData";
const AccessKeysDataAdapter = createEntityAdapter<AccessKeyData>({
    selectId: accessKey => accessKey.created,
    sortComparer: (a, b) => (a.created < b.created ? 1 : 0),
});
const initialState: AccessKeyDataState = AccessKeysDataAdapter.getInitialState({ status: {} });
const selectSelf = (state: RootState): AccessKeyDataState => state[sliceName];

export const { selectAll: selectAccessKeys, selectById: selectAccessKeyById } =
    AccessKeysDataAdapter.getSelectors<RootState>(selectSelf);

export const selectAccessKeysDataStatus = (spaceId: SpaceId) =>
    createSelector(selectSelf, state => {
        return state.status[spaceId];
    });

export const selectAccessKeysDataBySpaceId = (spaceId: SpaceId) =>
    createSelector(selectAccessKeys, keys => keys.filter(key => key.spaceId === spaceId));

export const fetchSpaceAccessKeysData = createAsyncThunk<AccessKeyData[], { spaceId: SpaceId; refetch?: boolean }>(
    `${sliceName}/fetchBySpaceId`,
    async ({ spaceId }) => {
        // TODO: make the client up-to-date again
        const accessKeysData = await getMiddlewateClient().listAccessKeys(spaceId);

        return accessKeysData.accessKeys.map(keyData => AccessKeysDataMap.toDTO(keyData, spaceId));
    },
    {
        // Cache between page loads and until refetch
        condition: ({ spaceId, refetch }, { getState }) => {
            const status = selectAccessKeysDataStatus(spaceId)(getState() as RootState);

            return !!spaceId && (refetch || (status !== "fulfilled" && status !== "pending"));
        },
    }
);

export const revokeAccessKey = createAsyncThunk<
    AccessKeyData,
    { spaceId: SpaceId; created: string; refetch?: boolean },
    { state: RootState }
>(`${sliceName}/revokeKeyBySpaceId`, async ({ spaceId, created }, { rejectWithValue }) => {
    // pass timestamp to x-revoke
    try {
        const response = await getMiddlewateClient().revokeAccessKey(spaceId, created);

        return response;
    } catch (err: unknown) {
        return rejectWithValue(err);
    }
});

export const accessKeysDataSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchSpaceAccessKeysData.pending, (state, action) => {
                state.status[action.meta.arg.spaceId] = "pending";
            })
            .addCase(fetchSpaceAccessKeysData.fulfilled, (state, action) => {
                state.status[action.meta.arg.spaceId] = "fulfilled";
                AccessKeysDataAdapter.upsertMany(state, action.payload);
            })
            .addCase(fetchSpaceAccessKeysData.rejected, (state, action) => {
                state.status[action.meta.arg.spaceId] = "rejected";
            })
            .addCase(revokeAccessKey.fulfilled, (state, action) => {
                AccessKeysDataAdapter.removeOne(state, action.meta.arg.created);
            });
    },
});

export const useAccessKeysBySpaceId = (spaceId: SpaceId) => {
    const { spaces } = useSpaces();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchSpaceAccessKeysData({ spaceId }));
    }, [spaces]);

    const status = useSelector(selectAccessKeysDataStatus(spaceId), fastDeepEqual);
    const accessKeys = useSelector(selectAccessKeysDataBySpaceId(spaceId), fastDeepEqual);

    return { status, accessKeys };
};

export default accessKeysDataSlice.reducer;
