import DateTime from "../../../components/DateTime";
import BootstrapIcon from "../../../components/BootstrapIcon";
import Status from "../../../components/Status";
import Tag from "../../../components/Tags";

const HubBasicInfo: React.FC<HubBasicInfoProps> = ({ hub }) => {
    const { id, selfHosted, info, healthy, description, tags, isConnectionActive, instances, sequences } = hub;

    return (
        <div className="flex tab-menu__context basic-info">
            <div className="tab-menu__content">
                <h2 className="subtitle-2 margin--t-1">ID</h2>
                <p>{id}</p>
                <h2 className="subtitle-2 margin--t-1">Hub Type</h2>
                <p>{selfHosted ? "Self Hosted" : "Scramjet Cloud"}</p>
                {description && (
                    <>
                        <h2 className="subtitle-2 margin--t-1">Description</h2>
                        <p>{description}</p>
                    </>
                )}
                <h2 className="subtitle-2 margin--t-1">Status</h2>
                <Status variant="health" status={healthy} tooltip={false} />
                <h2 className="subtitle-2 margin--t-1">Created at</h2>
                <DateTime started={info.created} created={info.created} />
                <h2 className="subtitle-2 margin--t-1">Connection</h2>
                <Status variant="connection" status={isConnectionActive} tooltip={false} />
                {info.lastConnected && (
                    <DateTime label="Last connected:" started={info.lastConnected} created={info.lastConnected} />
                )}
                {info.lastDisconnected && (
                    <DateTime label="Last disconnected:" started={info.lastDisconnected} created={info.lastDisconnected} />
                )}
            </div>
            <div className="tab-menu__content">
                <div className="margin--t-1">
                    <h2 className="subtitle-2">No. of Sequences</h2>
                    <p className="basic-info__seq">
                        <BootstrapIcon ico="sequence" size={20} />
                        <span>({sequences.length})</span>
                    </p>
                </div>
                <div className="margin--t-1">
                    <h2 className="subtitle-2">No. of Instances</h2>
                    <p className="basic-info__inst">
                        <BootstrapIcon ico="instance" size={20} />
                        <span>({instances.length})</span>
                    </p>
                </div>
                { tags && tags?.length > 0 && (
                    <>
                        <h2 className="subtitle-2 margin--t-1">Tags</h2>
                        <Tag margin="margin--t-1" tags={tags} />
                    </>
                )}
            </div>
        </div>
    );
};

export default HubBasicInfo;
