import { PropsWithChildren } from "react";
import { usePopover } from "../../../store/onboarding/onboardingSlice";
import { InternalLinkButton } from "../../../components/Buttons";

const AddSeqButtonPopover: React.FC<PropsWithChildren> = ({ children }) => {
    const { popoverData, displayPopover, nextPopover, Popover } = usePopover("first-sequence");

    return (
        <Popover
            title={popoverData?.title as string}
            text={popoverData?.text as string}
            position={popoverData?.position as PositionProps}
            content={
                <InternalLinkButton variant="primary" text="Next" to="/space/sequences/add" onClick={() => {
                    nextPopover();
                }} />
            }
            counter={5}
            display={displayPopover}
        >
            {children}
        </Popover>
    );
};

export default AddSeqButtonPopover;
