import { Link } from "react-router-dom";
import "./ButtonCircle.scss";

const ButtonCircle: React.FC<ButtonCircleProps> = ({ active = false, disabled = false, children, onClick, to }) => {
    const handleEvent = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        if (typeof onClick === "function") {
            onClick(e);
        }
    };

    return (
        <>
            {to ? (
                <Link className="btn-circle" to={to}>
                    {" "}
                    {children}{" "}
                </Link>
            ) : (
                <button className={"btn-circle" + (active ? " active" : "")} onClick={handleEvent} disabled={disabled}>
                    {" "}
                    {children}{" "}
                </button>
            )}
        </>
    );
};

export default ButtonCircle;
