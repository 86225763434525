import { useEffect } from "react";
import { useAppDispatch } from "../../store";
import SourceFetch from "./SourceFetch";
import { PropsWithChildren } from "react";

const SyncStore: React.FC<PropsWithChildren> = ({ children }) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        const sourceFetch = new SourceFetch();

        sourceFetch.onHubsUpdate(hubs => dispatch({ type: "hubs/syncUpdate", payload: hubs }));
        sourceFetch.onInstancesUpdate(instances => dispatch({ type: "instances/syncUpdate", payload: instances }));
        sourceFetch.onSequencesUpdate(sequences => dispatch({ type: "sequences/syncUpdate", payload: sequences }));
        sourceFetch.onTopicsUpdate(topics => dispatch({ type: "topics/syncUpdate", payload: topics }));
        sourceFetch.onEntitiesUpdate(entities => dispatch({ type: "entities/syncUpdate", payload: entities }));

        return () => {
            sourceFetch.destroy();
        };
    }, []);

    return <>{children}</>;
};

export default SyncStore;
