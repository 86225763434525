import { TabMenuWithRouter } from "../../../components/TabMenu";
import BasicInfo from "./BasicInfo";
import SequenceInstancesList from "./SequenceInstancesList";
import SeqTopSection from "./SeqTopSection";
import { useSequenceContext } from "../../../routers/RouteLoader";
import SpaceButtonsPortal from "../../SpacesPage/components/SpaceButtonsPortal";
import { ShowLogsAddSequenceButtons } from "../../SpacesPage/components/SpaceButtons";
import TabLazy from "../../../components/TabMenu/TabLazy";

const SequenceDetails: React.FC = () => {
    const { sequence } = useSequenceContext();

    return (
        <>
            <SpaceButtonsPortal>
                <ShowLogsAddSequenceButtons />
            </SpaceButtonsPortal>
            <SeqTopSection
                sequence={sequence}
            />
            <TabMenuWithRouter
                orientation="horizontal"
                tabList={["Basic info", "List of Instances"]}
                tabPanels={[
                    <BasicInfo sequence={sequence}/>,
                    <TabLazy index={1}><SequenceInstancesList sequence={sequence} /></TabLazy>,
                ]}
                flex
            />
        </>
    );
};

export default SequenceDetails;
