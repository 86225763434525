import { useState } from "react";

export function useCopy(textToCopy?: string, onCopy?: () => void) {
    const [isCopied, setIsCopied] = useState(false);
    const copyCommand = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        let copiedCmd = textToCopy ? textToCopy : event.currentTarget.previousSibling?.textContent;

        if (onCopy) onCopy();
        setIsCopied(false);
        if (copiedCmd) await navigator.clipboard.writeText(copiedCmd.replace(/\s+/g, " ").trim());
        setTimeout(() => setIsCopied(true), 200);
    };

    return { isCopied, copyCommand };
}
