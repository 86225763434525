import { ChangeEventHandler } from "react";
import { ClickButton } from "../Buttons";
import "./UploadButton.scss";

export const UploadButton: React.FC<UploadButtonProps> = ({ onSuccess, ...rest }) => {
    const handleChange: ChangeEventHandler<HTMLInputElement> = evt => {
        const files = evt.target.files;

        if (files && files.length === 0) return;

        if (typeof onSuccess === "function") onSuccess(files || null);
    };

    return (
        <div className="upload-button">
            <ClickButton {...rest} onClick={() => {}} />
            <label>
                <input type="file" onChange={handleChange}></input>
            </label>
        </div>
    );
};
