import { useCallback, useState } from "react";
import { deleteSequence, fetchSpaceSequences } from "../store/sequences/sequencesSlice";
import { deleteStoreItem } from "../store/storeItems/storeItemsSlice";
import { useAppDispatch } from "../store";
import { useToast } from "../components/Toast/Toasts";

export function useDeleteSequence() {
    const showToast = useToast();
    const dispatch = useAppDispatch();
    const [status, setStatus] = useState<"idle" | "pending" | "fulfilled" | "rejected">("idle");
    const handleDeleteSequence = useCallback(
        async (id: SequenceId, spaceId: SpaceId, location: string) => {
            setStatus("pending");
            try {
                if (location !== "store") {
                    await dispatch(
                        deleteSequence({
                            spaceId: spaceId,
                            hubId: location,
                            sequenceId: id,
                        })
                    ).unwrap();
                    showToast({
                        text: `The sequence ${id} was successfully deleted`,
                        variant: "success"
                    });
                } else {
                    await dispatch(
                        deleteStoreItem({
                            spaceId: spaceId,
                            sequenceId: id,
                        })
                    ).unwrap();
                    await dispatch(fetchSpaceSequences({ spaceId, refetch: true }));
                    showToast({
                        text: `The sequence ${id} waiting to be deleted`,
                        variant: "success"
                    });
                }
                setStatus("fulfilled");
            } catch (err: any) {
                showToast({
                    text: err?.exitcode ? err.message : `Failed to delete ${id} sequence`,
                    variant: "error"
                });
                setStatus("rejected");
            }
        }, []
    );

    return { handleDeleteSequence, status };
}
