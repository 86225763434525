import { MouseEventHandler } from "react";
import BootstrapIcon from "../../components/BootstrapIcon";
import { MainNavLink } from "./MainNav";

const MainNavLinkHref: React.FC<
    Omit<MainNavLink, "to" | "disabled"> & { className: string; href: string; }
> = ({ className, onClick, href, label, icon, target }) => {
    const onClickAction = onClick
        // eslint-disable-next-line no-sequences
        ? (() => (onClick(), 1)) as MouseEventHandler<HTMLAnchorElement>
        : undefined;

    return (
        <a className={className} onClick={onClickAction} href={href} aria-label={label} target={target}>
            <BootstrapIcon ico={icon} />
            <span className="nav__label">{label}</span>
        </a>
    );
};

export default MainNavLinkHref;
