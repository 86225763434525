import "../Button.scss";

import { Link } from "react-router-dom";
import { PropsWithChildren } from "react";
import ButtonContent from "../ButtonContent";

const InternalLinkButton: React.FC<PropsWithChildren<InternalLinkButtonProps>> = ({
    variant = "primary",
    disabled = false,
    addClass = "",
    text,
    icon,
    onClick,
    to,
    margin = "",
    width,
    children,
    ...rest
}) => {
    const classes = `btn btn--${variant} ${margin} ${addClass}${disabled ? " btn--disabled" : ""}`;
    const style = width ? { width: `${width}em` } : undefined;

    return (
        <Link className={classes} style={style} to={to} onClick={onClick} {...rest}>
            <ButtonContent text={text} icon={icon}>
                {children}
            </ButtonContent>
        </Link>
    );
};

export default InternalLinkButton;
