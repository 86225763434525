import { ClientUtils } from "@scramjet/client-utils";
import { useCallback, useState } from "react";
import { sendErrorEvent, sendEvent } from "../utils/gtmEvents";
import { middlewareUrl } from "../components/MiddlewareClientProvider";

export function useExpireCheckoutSession() {
    const [status, setStatus] = useState<"idle" | "loading" | "success" | "error">("idle");
    const [error, setError] = useState<string | null>(null);
    const expire = useCallback(async (paymentSessionId: string) => {
        try {
            setStatus("loading");
            setError(null);

            const clientUtils = new ClientUtils(middlewareUrl);

            await clientUtils.post(
                `/api/v1/expire-checkout-session/${paymentSessionId}`,
                {},
                {},
                { json: true, parse: "json" }
            );

            sendEvent("console_checkout_cancel");

            setStatus("success");
        } catch (e) {
            console.error(e);
            sendErrorEvent("console_checkout_cancel", e);

            setStatus("error");
            setError("Error while setting up the trial subscription.");
        }
    }, []);

    return { expire, status, error };
}
