import * as React from "react";
import { ClientUtils } from "@scramjet/client-utils";
import { MiddlewareClient } from "@scramjet/middleware-api-client";

export const middlewareUrl = process.env.REACT_APP_MIDDLEWARE || "";
export const middlewareHybridUrl = process.env.REACT_APP_MIDDLEWARE_HYBRID || "";

let middlewareClient = new MiddlewareClient(middlewareUrl + "/api/v1");
let tokenStr = "";

const defaultContextValue = { middlewareClient };
const MiddlewareClientContext = React.createContext<MiddlewareClientContextType>(defaultContextValue);
const MiddlewareClientProvider: React.FC<React.PropsWithChildren<MiddlewareClientProviderProps>> = ({
    children,
    token,
}) => {
    ClientUtils.setDefaultHeaders({
        Authorization: `Bearer ${token}`,
    });

    tokenStr = token;
    middlewareClient = new MiddlewareClient(middlewareUrl + "/api/v1");

    return <MiddlewareClientContext.Provider value={{ middlewareClient }}>{children}</MiddlewareClientContext.Provider>;
};

function useMiddlewareClient(): MiddlewareClient {
    const context = React.useContext(MiddlewareClientContext);

    if (context === undefined) {
        throw new Error("useMiddlewareClient must be used within a MiddlewareClientProvider");
    }

    return context.middlewareClient;
}

function getMiddlewateClient(): MiddlewareClient {
    return middlewareClient;
}

function getToken(): string {
    return tokenStr;
}

export { MiddlewareClientProvider, useMiddlewareClient, MiddlewareClientContext, getMiddlewateClient, getToken };
