import "./List.scss";

const ListItem: React.FC<ListItemProps> = ({ text, icon, size }) => {
    return (
        <li className={`list__item ${size}`}>
            {icon}
            <p className="list__text">{text}</p>
        </li>
    );
};
const List: React.FC<ListProps> = ({ textList, icon, className = "", size = "body-1", marginLeft = 0 }) => {
    return (

        <ul className={`list ${icon && "list--icons"} ${className} ${size} margin--l-${marginLeft}`}>
            {textList.map((text: string, key: number) => (
                <ListItem size={size} key={key} text={text} icon={icon} />
            ))}
        </ul>
    );
};

export default List;
