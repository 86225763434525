import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SequenceInfo } from "@scramjet/types";
import { DeleteSequenceResponse, SendSequenceResponse, StartSequencePayload } from "@scramjet/types/rest-api-sth";
import fastDeepEqual from "fast-deep-equal";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Readable } from "stream";
import { RootState, useAppDispatch } from "..";
import { getMiddlewateClient } from "../../components/MiddlewareClientProvider";
import InstanceMap from "../instances/InstanceMap";
import SequenceMap from "./SequenceMap";
import { useSpaces } from "../spaces/spacesSlice";
import { useHubById } from "../hubs/hubsSlice";

const sliceName = "sequences";
const sequencesAdapter = createEntityAdapter<Sequence>({
    selectId: sequence => sequence.uuid,
});
const initialState: SequencesState = sequencesAdapter.getInitialState({
    status: {},
});
const selectSelf = (state: RootState): SequencesState => state[sliceName];

export const { selectAll: selectSequences, selectById: selectSequenceById } =
    sequencesAdapter.getSelectors<RootState>(selectSelf);

export const selectSequencesStatus = (spaceId: SpaceId) =>
    createSelector(selectSelf, state => {
        return state.status[spaceId] || "idle";
    });

export const selectSequencesByHubId = (hubId: HubId) =>
    createSelector(selectSequences, sequences => sequences.filter(sequence => sequence.location === hubId));

export const selectSequencesByGroupId = (sequenceId: SequenceId) =>
    createSelector(selectSequences, sequences => sequences.filter(sequence => sequence.id === sequenceId));

export const fetchSpaceSequences = createAsyncThunk<
    Sequence[],
    { spaceId: SpaceId; refetch?: boolean },
    { state: RootState }
>(
    `${sliceName}/fetchBySpaceId`,
    async ({ spaceId }) => {
        const spaceClient = getMiddlewateClient().getManagerClient(spaceId);
        const sequences = (await spaceClient.getAllSequences())
            .filter(SequenceMap.filterInvalidSequences)
            .map(seq => SequenceMap.toDTO(seq as unknown as SequenceInfo, spaceId));

        return sequences;
    },
    {
        // Cache between page loads and until refetch
        condition: ({ spaceId, refetch }, { getState }) => {
            const status = selectSequencesStatus(spaceId)(getState() as RootState);

            return !!spaceId && (refetch || (status !== "fulfilled" && status !== "pending"));
        },
    }
);

export const startSequence = createAsyncThunk<
    Instance,
    { spaceId: SpaceId; hubId: HubId; sequenceId: SequenceId; payload: StartSequencePayload },
    { state: RootState }
>(`${sliceName}/startSequence`, async ({ spaceId, hubId, sequenceId, payload }, { rejectWithValue }) => {
    try {
        const spaceClient = getMiddlewateClient().getManagerClient(spaceId);
        const hubClient = spaceClient.getHostClient(hubId);
        const sequenceClient = hubClient.getSequenceClient(sequenceId);
        const startResults = await sequenceClient.start(payload);
        const newInstance = InstanceMap.toDTO(await startResults.getInfo(), hubId, spaceId);

        return newInstance;
    } catch (err: unknown) {
        return rejectWithValue(err);
    }
});

export const deleteSequence = createAsyncThunk<
    DeleteSequenceResponse,
    { spaceId: SpaceId; hubId: HubId; sequenceId: SequenceId },
    { state: RootState }
>(`${sliceName}/deleteSequence`, async ({ spaceId, hubId, sequenceId }, { rejectWithValue }) => {
    try {
        const spaceClient = getMiddlewateClient().getManagerClient(spaceId);
        const hubClient = spaceClient.getHostClient(hubId);
        const deleteResults = (await hubClient.deleteSequence(sequenceId)) as DeleteSequenceResponse;

        return deleteResults;
    } catch (err: unknown) {
        return rejectWithValue(err);
    }
});

export const sendSequence = createAsyncThunk<
    Sequence,
    { spaceId: SpaceId; hubId: HubId; file: File },
    { state: RootState }
>(`${sliceName}/sendSequence`, async ({ spaceId, hubId, file }, { rejectWithValue }) => {
    try {
        const spaceClient = getMiddlewateClient().getManagerClient(spaceId);
        const hubClient = spaceClient.getHostClient(hubId);
        const sendResults = (await hubClient.sendSequence(file as unknown as Readable)) as SendSequenceResponse;
        // TODO: GetSequenceResponse type is override due to readonly value in response
        const getResults = (await hubClient.getSequence(sendResults.id)) as GetSequenceResponse;

        return SequenceMap.toDTO({ ...getResults, instances: getResults.instances }, spaceId);
    } catch (err: unknown) {
        return rejectWithValue(err);
    }
});

export const sequencesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        syncUpdate: (state, action: PayloadAction<Sequence[]>) => {
            const sequencesForRemoval = state.ids.filter(
                sequenceId => !action.payload.find(search => search.id === sequenceId)
            );
            /**
             * fix sequence.isStarting overwriting with initial value from syncUpdate while actually starting
             */
            const fixedSequences = [...action.payload];

            fixedSequences.forEach(sequence => {
                if (state.entities[sequence.id]) {
                    sequence.isStarting = (state.entities[sequence.id] as Sequence).isStarting;
                }
            });

            sequencesAdapter.removeMany(state, sequencesForRemoval);
            sequencesAdapter.upsertMany(state, fixedSequences);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSpaceSequences.pending, (state, action) => {
                state.status[action.meta.arg.spaceId] = "pending";
            })
            .addCase(fetchSpaceSequences.fulfilled, (state, action) => {
                state.status[action.meta.arg.spaceId] = "fulfilled";
                sequencesAdapter.removeAll(state);
                sequencesAdapter.addMany(state, action.payload);
            })
            .addCase(fetchSpaceSequences.rejected, (state, action) => {
                state.status[action.meta.arg.spaceId] = "rejected";
            })
            .addCase(startSequence.pending, (state, action) => {
                sequencesAdapter.updateOne(state, {
                    id: action.meta.arg.sequenceId,
                    changes: {
                        isStarting: true,
                    },
                });
            })
            .addCase(startSequence.fulfilled, (state, action) => {
                sequencesAdapter.updateOne(state, {
                    id: action.meta.arg.sequenceId,
                    changes: {
                        isStarting: false,
                        instances: [
                            ...(state.entities[action.meta.arg.sequenceId]?.instances || []),
                            action.payload.id,
                        ],
                    },
                });
            })
            .addCase(startSequence.rejected, (state, action) => {
                sequencesAdapter.updateOne(state, {
                    id: action.meta.arg.sequenceId,
                    changes: {
                        isStarting: false,
                    },
                });
            })
            .addCase(deleteSequence.pending, (state, action) => {
                sequencesAdapter.updateOne(state, {
                    id: action.meta.arg.sequenceId,
                    changes: {
                        isStarting: true,
                    },
                });
            })
            .addCase(deleteSequence.fulfilled, (state, action) => {
                sequencesAdapter.removeOne(state, action.meta.arg.sequenceId);
            })
            .addCase(deleteSequence.rejected, (state, action) => {
                sequencesAdapter.updateOne(state, {
                    id: action.meta.arg.sequenceId,
                    changes: {
                        isStarting: false,
                    },
                });
            })
            .addCase(sendSequence.fulfilled, (state, action) => {
                sequencesAdapter.addOne(state, action.payload);
            });
    },
});

export const useSequencesBySpaceId = (spaceId: SpaceId) => {
    const { spaces } = useSpaces();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchSpaceSequences({ spaceId }));
    }, [spaces]);

    const sequences = useSelector(selectSequences, fastDeepEqual);
    const status = useSelector(selectSequencesStatus(spaceId), fastDeepEqual);

    return { sequences, status };
};

export const useSequencesByHubId = (hubId: HubId) => {
    const hub = useHubById(hubId);
    const spaceId = hub?.spaceId || "";
    const { status } = useSequencesBySpaceId(spaceId);
    const sequences = useSelector(selectSequencesByHubId(hubId), fastDeepEqual);

    return { sequences, status };
};

export const useSequencesByGroupId = (sequenceId: SequenceId) =>
    useSelector(selectSequencesByGroupId(sequenceId), fastDeepEqual);

export default sequencesSlice.reducer;
