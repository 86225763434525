import "../Button.scss";

import { PropsWithChildren } from "react";
import ButtonContent from "../ButtonContent";

const ExternalLinkButton: React.FC<PropsWithChildren<ExternalLinkButtonProps>> = ({
    variant = "primary",
    disabled = false,
    addClass = "",
    text,
    icon,
    onClick,
    href,
    target = "_blank",
    margin = "",
    width,
    children,
    img,
    ...rest
}) => {
    const classes = `btn btn--${variant} ${margin} ${addClass} ${disabled ? "btn--disabled" : ""} ${img ? "btn--image" : ""}`;
    const style = width ? { width: `${width}em` } : undefined;

    return (
        <a
            className={classes + (disabled ? " btn--disabled" : "")}
            style={style}
            href={href}
            target={target}
            rel="nofollow"
            onClick={onClick}
            {...rest}
        >
            <ButtonContent text={text} icon={icon} img={img}>
                {children}
            </ButtonContent>
        </a>
    );
};

export default ExternalLinkButton;
