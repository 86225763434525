import "./Toast.scss";
import React, { useEffect, useState } from "react";
import { ClickButton } from "../Buttons";
import { Alert } from "@reach/alert";

const Toast: React.FC<ToastProps> = ({ text, variant, closeButton = true }) => {
    const [closingClass, setClosingClass] = useState("");
    const [isClosed, setIsClosed] = useState(false);

    function close() {
        if (!closingClass) {
            setClosingClass("toast--closing");
            setTimeout(() => setIsClosed(true), 500);
        }
    }

    useEffect(() => {
        setClosingClass("");
        setTimeout(close, 15000);
    }, []);

    if (isClosed) return <></>;

    return (
        <Alert className={`toast toast--${variant} ${closingClass}`} type="assertive">
            <p className="toast__text">
                {closeButton && (
                    <span className="toast__buttons">
                        <ClickButton text="CLOSE" variant="leave" onClick={close} />
                    </span>
                )}
                {text}
            </p>
        </Alert>
    );
};

export default Toast;
