import Box from "../Box";
import { ClickButton } from "../Buttons";
import Label from "../Label";
import "./InfoBox.scss";

const InfoBox: React.FC<InfoBoxProps> = ({ label, children, btn, marginBottom = "", marginTop = "" }) => {
    return (
        <Box
            outline="shadow"
            className={`info-box ${marginBottom && "margin--b-" + marginBottom} 
            ${marginTop && "margin--t-" + marginTop}`}
        >
            <div className="info-box__text">
                <Label color="blue" scale="big" text={label} position="t-left" />
                {children}
            </div>
            {btn && (
                <ClickButton
                    addClass="info-box__btn"
                    margin={btn.margin}
                    variant={btn.variant}
                    text={btn.text}
                    onClick={btn.onClick}
                />
            )}
        </Box>
    );
};

export default InfoBox;
