import { useSpaceContext } from "../pages/SpacesPage";
import { useEntitiesBySpaceId } from "../store/entities/entitiesSlice";

export function useAddSequenceAvailable() {
    const { space } = useSpaceContext();
    const { entities } = useEntitiesBySpaceId(space.id);
    const addSequenceAvailable = !!entities && entities.hubs.length > 0;

    return addSequenceAvailable;
}
