import { useState } from "react";
import { StopFill, X } from "react-bootstrap-icons";
import BootstrapIcon from "../../components/BootstrapIcon";
import { ButtonGroup, ClickButton } from "../../components/Buttons";
import { DialogModal } from "../../components/Modal";
import { TabMenuWithRouter } from "../../components/TabMenu";
import dialogs from "../../data/modals/Dialogs.json";
import TopSection from "../../layout/TopSection";
import { useAppDispatch } from "../../store";
import { killInstance, stopInstance } from "../../store/instances/instancesSlice";
import InstanceArgs from "./InstanceArgs";
import InstanceInfo from "./InstanceInfo";
import InstanceLogs from "./InstanceLogs";
import { useInstanceContext } from "../../routers/RouteLoader";
import SpaceButtonsPortal from "../SpacesPage/components/SpaceButtonsPortal";
import { ShowLogsAddSequenceButtons } from "../SpacesPage/components/SpaceButtons";
import TabLazy from "../../components/TabMenu/TabLazy";

const InstanceDetails: React.FC = () => {
    const { title, desc, variant, buttons } = dialogs["kill-instance"];
    const [instanceToKill, setInstanceToKill] = useState<Instance | null>(null);
    const dispatch = useAppDispatch();
    const { instance } = useInstanceContext();
    const handleStop = (instance: Instance) => {
        dispatch(stopInstance({ spaceId: instance.spaceId, hubId: instance.hubId, instanceId: instance.id }));
    };
    const handleOpenKillModal = (instance: Instance) => { setInstanceToKill(instance); };
    const handleCloseKillModal = () => { setInstanceToKill(null); };
    const handleKill = () => {
        if (!instanceToKill) return;
        dispatch(
            killInstance({
                spaceId: instanceToKill.spaceId,
                hubId: instanceToKill.hubId,
                instanceId: instanceToKill.id,
            })
        );
        handleCloseKillModal();
    };

    function actionsDisabled(status: InstanceStatus) {
        return status !== "running";
    }

    return (
        <>
            <SpaceButtonsPortal>
                <ShowLogsAddSequenceButtons />
            </SpaceButtonsPortal>
            <TopSection
                variant="subtitle"
                title={instance.id}
                marginTop={2}
                marginBottom={2}
                icon={<BootstrapIcon ico="instance" size={25} /> }
            >
                <ButtonGroup align="left">
                    <ClickButton
                        variant="secondary"
                        icon={<StopFill size="2.5rem" />}
                        text="Stop"
                        onClick={() => handleStop(instance)}
                        disabled={actionsDisabled(instance.status)}
                    />
                    <ClickButton
                        variant="secondary"
                        icon={<X size="2.5rem" />}
                        text="Kill"
                        onClick={() => handleOpenKillModal(instance)}
                        disabled={actionsDisabled(instance.status)}
                    />
                </ButtonGroup>
                <DialogModal
                    title={title}
                    variant={variant as ModalVariant}
                    onClose={handleCloseKillModal}
                    open={!!instanceToKill}
                    buttons={
                        <>
                            <ClickButton
                                text={buttons.cancel.text}
                                variant={buttons.cancel.variant as ButtonVariant}
                                onClick={handleCloseKillModal}
                                disabled={actionsDisabled(instance.status)}
                            />
                            <ClickButton
                                text={buttons.kill.text}
                                variant={buttons.kill.variant as ButtonVariant}
                                onClick={handleKill}
                            />
                        </>
                    }
                >
                    {desc.replaceAll("${name}", instanceToKill?.id || "")}
                </DialogModal>
            </TopSection>
            <TabMenuWithRouter
                orientation="horizontal"
                tabList={instance.hasLogs ? ["Basic info", "Args", "Logs"] : ["Basic info", "Args"]}
                tabPanels={[
                    <InstanceInfo instance={instance} />,
                    <InstanceArgs args={instance.args} />,
                    <TabLazy index={2}>
                        <InstanceLogs spaceId={instance.spaceId} hubId={instance.hubId} instanceId={instance.id} />
                    </TabLazy>,
                ]}
                flex
            />
        </>
    );
};

export default InstanceDetails;
