import JSONEditor from "../../components/JSONHighlighter";
import Quote from "../../components/Quote";
import DescriptionData from "../../data/Description.json";

const InstanceArgs: React.FC<InstanceArgsProps> = ({ args }) => {
    return (
        <div className="tab-menu__content">
            <Quote text={DescriptionData.InstanceDetailsArguments} />
            {args.length > 0 ? (
                <JSONEditor data={args} readonly={true} height="40vh" labelText="Instance started wih arguments"/>
            ) : (
                <>
                    <h2 className="subtitle-2 margin--t-1 margin--b-1">No arguments were provided</h2>
                    <a
                        target="_blank"
                        href="https://docs.scramjet.org/platform/sequence-setup/#sequence-instance"
                    >
                        Read about instance arguments
                    </a>
                </>
            )}
        </div>
    );
};

export default InstanceArgs;
