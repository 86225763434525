import "./Tags.scss";

const Tag: React.FC<TagsProps> = ({ tags, margin }) => {
    return (
        <p className={`tag ${margin}`}>
            {tags.map((tag, id) => {
                return (
                    <span key={`${id}`} className="tag__item">
                        #{tag}
                    </span>
                );
            })}
        </p>
    );
};

export default Tag;
