import "./CmdCommand.scss";
import { Clipboard, ClipboardCheck } from "react-bootstrap-icons";
import React from "react";
import { useCopy } from "../../hooks/useCopy";
import Tooltip from "../Tooltip";

const CmdCommand: React.FC<CmdCommandProps> = ({ description, command, wrap, fullWidth, onCopy, className = "", variant = "light" }) => {
    const { isCopied, copyCommand } = useCopy(undefined, onCopy);

    return (
        <div className={`cmd ${wrap ? "cmd--wrap" : ""} ${fullWidth ? "cmd--full-width" : ""} ${className} cmd--${variant}`}>
            {description && <p className="cmd__caption body-1">{description}</p>}
            <div className="cmd__command">
                <code className="cmd__code">{command}</code>
                <button className="cmd__copy" onClick={copyCommand} aria-label="Copy the text">
                    {isCopied ? (
                        <Tooltip text="Copied" aria-label="Copied" position="above">
                            <ClipboardCheck size={20} aria-hidden="true" />
                        </Tooltip>
                    ) : (
                        <Tooltip text="Copy" aria-label="Copy" position="above">
                            <Clipboard size={20} aria-hidden="true" />
                        </Tooltip>
                    )}
                </button>
            </div>
        </div>
    );
};

export default CmdCommand;
