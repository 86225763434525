import { formatDistanceToNowStrict } from "date-fns";
import BootstrapIcon from "../../components/BootstrapIcon";
import Box from "../../components/Box";
import { ButtonGroup, InternalLinkButton } from "../../components/Buttons";
import DateTime from "../../components/DateTime";
import InstanceRenderStatus from "../InstancePage/InstanceRenderStatus";
import CopyText from "../../components/CopyText";

const InstanceInfo: React.FC<InstanceInfoProps> = ({ instance }) => {
    const { id, sequence, hubId, started, created, status, terminated, hasLogs } = instance;
    const runningTime = () => {
        if (started && status === "running") {
            const startedDate = typeof started === "string" ? new Date(started) : started;
            const runningTimeInt = parseInt(formatDistanceToNowStrict(startedDate, { unit: "minute" }), 10);

            return `${Math.floor(runningTimeInt / 60)} h ${runningTimeInt % 60} min`;
        }
        return "Not running";
    };

    return (
        <>
            <div className="tab-menu__content">
                <h2 className="subtitle-2">ID</h2>
                <p>{id}<CopyText text={id} /></p>
                <h2 className="subtitle-2 margin--t-1">Created on Hub</h2>
                <p>{hubId}</p>
                <h2 className="subtitle-2 margin--t-1">Created at</h2>
                <DateTime started={started} created={created} />
                <h2 className="subtitle-2 margin--t-1">Status</h2>
                <InstanceRenderStatus instanceId={id} status={status} variant="details" terminated={terminated} hasLogs={hasLogs} />
                <h2 className="subtitle-2 margin--t-1">Running time</h2>
                <p>{runningTime()}</p>
            </div>
            <div className="tab-menu__content">
                {sequence && (
                    <>
                        <h2 className="subtitle-1 margin--b-1">
                            <BootstrapIcon ico="sequence" />
                            <span className="margin--l-1">Started from Sequence</span>
                        </h2>
                        <Box outline="shadow">
                            <p className="subtitle-2 margin--t-1">
                                Name: {sequence.config.name}
                            </p>
                            <p className="margin--t-1">{sequence.config.description}</p>
                            <ButtonGroup marginTop={2}>
                                <InternalLinkButton
                                    variant="secondary"
                                    text="Show details"
                                    to={`/space/sequences/${sequence.id}/basic-info`}
                                />
                            </ButtonGroup>
                        </Box>
                    </>
                )}
            </div>
        </>
    );
};

export default InstanceInfo;
