import { format, differenceInCalendarYears } from "date-fns";

const Europe = "dd MMMM yyyy";
const MonthDay = "MMM dd";
const Year = "yyyy";

export type Unit = "second" | "minute" | "hour" | "day" | "month" | "year";
export type Duration = {
    days: number;
    hours: number;
    minutes: number;
    months: number;
    seconds: number;
    years: number;
};

function formatDate(date: number, pattern: string) {
    return format(new Date(date * 1000), pattern);
}

export function showFullDate(date: number) {
    return formatDate(date, Europe);
}

export function showShortDate(date: number) {
    return formatDate(date, MonthDay);
}

export function showYear(date: number) {
    return format(new Date(date), Year);
}

export function displayLongDateIfYearsDiffers(startDate: number, endDate: number, showDate: number) {
    const differenceInYears = differenceInCalendarYears(new Date(startDate), new Date(endDate));

    return differenceInYears > 0 ? showFullDate(showDate) : showShortDate(showDate);
}
