import { Navigate, useLocation } from "react-router-dom";
import { useSubscriptions } from "../store/subscriptinos/subscriptionsSlice";
import { useInvoices } from "../store/invoices/invoicesSlice";
import FullscreenLoader from "../components/FullscreenLoader";
import { PaymentsLoaderContent } from "../components/PaymentsLoaderContent";
import { useLogoutDeletedAccount } from "../hooks/useLogoutDeletedAccount";
import { useSpaceByIndex } from "../store/spaces/spacesSlice";
import SpacesLoaderTexts from "../components/SpacesLoaderTexts";
import ErrorLayout from "../layout/Error";
import TopBar from "../layout/TopBar";

type ProtectedRouteProps = {
    isNotAllowed?: "active" | "inactive" | null;
    redirectPath?: string;
    layout: React.ReactNode;
};

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ isNotAllowed = null, redirectPath = "/", layout }) => {
    const { paymentsStatus, latestSubscription } = useSubscriptions();
    const { status: invoicesStatus, invoices } = useInvoices();
    const { status: spacesStatus } = useSpaceByIndex(0);
    const location = useLocation();
    const status = "";

    useLogoutDeletedAccount(status);

    if (paymentsStatus === "loading" || invoicesStatus === "pending" || spacesStatus === "pending") return (
        <>
            <TopBar />
            <ErrorLayout>
                <FullscreenLoader>
                    <SpacesLoaderTexts/>
                </FullscreenLoader>
            </ErrorLayout>
        </>
    );
    if (paymentsStatus === "inprogress-retry") return <FullscreenLoader><PaymentsLoaderContent /></FullscreenLoader>;

    if (!latestSubscription && invoices.length === 0 && location.pathname !== "/start-trial") {
        return <Navigate to="/start-trial" replace />;
    }

    if (paymentsStatus === isNotAllowed) return <Navigate to={redirectPath} replace />;

    return <>{layout}</>;
};

export default ProtectedRoute;
