import InfoBox from ".";
import { usePaymentsPortalCallback } from "../../hooks/usePaymentsPortalCallback";
import { useAddPayment } from "../../hooks/useAddPayment";
import EndOfTrialDateInfo from "../../pages/SubscriptionPage/EndOfTrialDateInfo";
import { useSubscriptions } from "../../store/subscriptinos/subscriptionsSlice";

const SubscriptionInfoBox = () => {
    const { paymentMethods, latestSubscription } = useSubscriptions();
    const { portalUrlCallback } = usePaymentsPortalCallback();
    const trialEnd = latestSubscription?.trialEnd;
    const today = Date.now();
    const TrialInfoBox = () => (
        <>
            {paymentMethods ? (
                trialEnd && (
                    <InfoBox marginTop={1} marginBottom={1} label="Trial Plan">
                        <EndOfTrialDateInfo today={today} trialEnd={trialEnd} />
                    </InfoBox>
                )
            ) : (
                <InfoBox
                    marginTop={1}
                    marginBottom={1}
                    label="Trial Plan"
                    btn={{
                        variant: "primary",
                        text: "Upgrade",
                        margin: "margin--l-1",
                        onClick: () => {
                            useAddPayment();
                        },
                    }}
                >
                    { trialEnd && <EndOfTrialDateInfo today={today} trialEnd={trialEnd} /> }
                </InfoBox>
            )}
        </>
    );
    const PastDueInfoBox = () => (
        <InfoBox
            marginTop={1}
            marginBottom={1}
            label="Monthly subscription"
            btn={{
                variant: "primary",
                text: "Go to payments",
                margin: "margin--l-1",
                onClick: () => {
                    portalUrlCallback();
                },
            }}
        >
            <p className="body-1">Your payment didn't go through, please update your card details</p>
        </InfoBox>
    );

    switch (latestSubscription?.status) {
        case "trialing":
            return <TrialInfoBox />;
        case "past_due":
            return <PastDueInfoBox />;
        default:
            return <></>;
    }
};

export default SubscriptionInfoBox;
