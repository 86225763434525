import { useEffect, useRef, useState } from "react";
import "./ConsoleLog.scss";
import { throttle } from "throttle-debounce";
import { Download } from "react-bootstrap-icons";
import { ButtonGroup, ExternalLinkButton } from "../Buttons";
import { FixedSizeList as List, ListChildComponentProps } from "react-window";

// TODO: use https://github.com/bvaughn/react-window for optimalization

let logString: string[] = [];

function createObjectUrl(str: string) {
    const blob = new Blob([str], { type: "octet/stream" });
    const url = URL.createObjectURL(blob);

    return url;
}

const ConsoleLog: React.FC<ConsoleLogProps> = ({ logStream, autoscroll = true }) => {
    const [logs, setLogs] = useState<string[]>([...logString]);
    const scrollView = useRef<any>(null);
    const ConsoleLogRow = ({ index, style }: ListChildComponentProps) => (
        <pre key={index} style={style}>
            {logs[index]}
        </pre>
    );

    useEffect(() => {
        setTimeout(async () => {
            try {
                const updateLogs = throttle(1, () => {
                    setLogs([...logString]);
                });

                if (!logStream.locked) {
                    const logStreamReader = logStream.pipeThrough(new TextDecoderStream()).getReader();

                    let leftovers: string | undefined = "";

                    while (logStreamReader) {
                        // eslint-disable-line
                        const { value, done } = await logStreamReader.read();

                        if (value) {
                            let lines: string[] = (value + leftovers).split("\n");

                            if (value.slice(-1) === "\n") {
                                leftovers = lines.pop();
                            }

                            logString = logString.concat(lines);

                            updateLogs();
                        }

                        if (done) break;
                    }
                }
            } catch (e) {
                console.log(e);
            }
        }, 1);
    }, []);

    useEffect(() => {
        if (scrollView.current && autoscroll) {
            scrollView.current.scrollTop = scrollView.current.scrollHeight;
        }
    }, [logs]);

    return (
        <div className="console-log">
            <div className="console-log__header">
                <div className="console-log__tabs">
                    <div className="console-log__tab">Logs</div>
                </div>
                <div className="console-log__menu">
                    <ButtonGroup align="right">
                        <ExternalLinkButton
                            href={createObjectUrl(logs.join("\n"))}
                            icon={<Download />}
                            text="Download"
                            variant="action"
                            download="logs.txt"
                        />
                    </ButtonGroup>
                </div>
            </div>
            <List
                outerRef={scrollView}
                height={500}
                width={"100%"}
                itemCount={logs.length}
                itemSize={33}
                style={{
                    margin: "0.5em 0.5em",
                    padding: "0.5em 0.5em",
                    borderBottom: "1px solid var(--bg-border)",
                    width: "auto",
                    minWidth: "100%",
                }}
            >
                {ConsoleLogRow}
            </List>
        </div>
    );
};

export default ConsoleLog;
