import ContentLoader from "react-content-loader";

const MainNavSkeleton: React.FC = () => (
    <ContentLoader
        speed={2}
        width={220}
        height={50}
        viewBox="0 0 220 50"
        backgroundColor="#fbfbfb"
        foregroundColor="#002d52"
    >
        <circle cx="29" cy="26" r="10" />
        <rect x="55" y="19" rx="5" ry="5" width="123" height="15" />
    </ContentLoader>
);

export default MainNavSkeleton;
