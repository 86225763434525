import { ArrowClockwise, CheckCircleFill, CheckLg, ExclamationOctagonFill } from "react-bootstrap-icons";
import { InternalLinkButton } from "../../components/Buttons";
import Tooltip from "../../components/Tooltip";
import instanceStatus from "../../data/InstanceStatus.json";
import "./InstanceRenderStatus.scss";
import { useEffect, useRef, useState } from "react";
import { useToast } from "../../components/Toast/Toasts";
const showToast = useToast();
const InstanceRenderStatusLoading: React.FC<InstanceStatusProps> = ({ status, variant = "default" }) => (
    <div className={`instance-status instance-status--${variant}`}>
        <ArrowClockwise className="icon--danger icon--rotating" size="2rem" />
        <span>{instanceStatus[status]}</span>
    </div>
);
const InstanceRenderStatusStarting: React.FC<InstanceStatusProps> = ({ status, variant = "default" }) => (
    <div className={`instance-status instance-status--${variant}`}>
        <ArrowClockwise className="icon--success icon--rotating" size="2rem" />
        <span>{instanceStatus[status]}</span>
    </div>
);
const InstanceRenderStatusRunning: React.FC<InstanceStatusProps> = ({ status, variant = "default" }) => (
    <Tooltip text="Instances are checked every 30ms" position="above">
        <div className={`instance-status instance-status--${variant}`}>
            <CheckCircleFill className="icon--success" size="2rem" />
            <span>{instanceStatus[status]}</span>
        </div>
    </Tooltip>
);
const InstanceRenderStatusStopping: React.FC<InstanceStatusProps> = ({ status, variant = "default" }) => (
    <div className={`instance-status instance-status--${variant}`}>
        <ArrowClockwise className="icon--danger icon--rotating" size="2rem" />
        <span>{instanceStatus[status]}</span>
    </div>
);
const InstanceRenderStatusCompleted: React.FC<InstanceStatusProps> = ({ status, variant = "default" }) => (
    <div className={`instance-status instance-status--${variant}`}>
        <CheckLg className="icon--healthy" size="2rem" />
        <span>{instanceStatus[status]}</span>
    </div>
);
const InstanceRenderStatusErrored: React.FC<InstanceStatusProps> = ({
    instanceId,
    status,
    terminated,
    hasLogs,
    variant = "default",
}) => {
    const logsButton = <InternalLinkButton text="Show logs" variant="link" to={`/space/instances/${instanceId}/logs`} />;
    const reason = terminated?.reason;
    const reasonElement = <div className="instance-status__error">{terminated?.reason}</div>;
    const statusElement = (
        <div className={`instance-status instance-status--${variant}`}>
            <ExclamationOctagonFill className="icon--unhealthy" size="2rem" />
            <div>
                {instanceStatus[status]}
                {(variant === "default" || !reason) && hasLogs && logsButton}
                {variant === "details" && !!reason && reasonElement}
            </div>
        </div>
    );

    if (variant === "default") {
        return (
            <Tooltip text={reason} position="above">
                {statusElement}
            </Tooltip>
        );
    }

    return <>{statusElement}</>;
};
const InstanceRenderStatus: React.FC<InstanceStatusProps> = props => {
    const prevStatus = useRef(props.status);

    useEffect(() => {
        if (prevStatus.current !== "completed" && props.terminated && prevStatus.current !== props.status) {
            showToast({
                text: `The instance ${props.instanceId} was successfully ${props.terminated.reason.split(" ")[1]}`,
                variant: "success",
            });
        }
    }, [props.status]);
    switch (props.status) {
        case "loading":
            return <InstanceRenderStatusLoading {...props} />;
        case "running":
            return <InstanceRenderStatusRunning {...props} />;
        case "killing":
        case "stopping":
            return <InstanceRenderStatusStopping {...props} />;
        case "completed":
            return <InstanceRenderStatusCompleted {...props} />;
        case "errored":
            return <InstanceRenderStatusErrored {...props} />;
        case "initializing":
        case "starting":
        default:
            return <InstanceRenderStatusStarting {...props} />;
    }
};

export default InstanceRenderStatus;
