import { useState } from "react";
import { useNavigate } from "react-router-dom";
import ModalDialog from "../../../data/modals/Dialogs.json";
import { ClickButton } from "../../../components/Buttons";
import { DialogModal, StatusModal } from "../../../components/Modal";
import StatusModalData from "../../../data/modals/StatusModals.json";
import { useDeleteSequence } from "../../../hooks/useDeleteSequence";
import Select from "../../../components/Select";
import { Server } from "react-bootstrap-icons";

const sequencesListUrl = "/space/sequences";
const DeleteModal: React.FC<DeleteModalProps> = ({ openModal, setOpenModal, sequences }) => {
    const navigate = useNavigate();
    const { modal, content } = openModal;
    const currentDialogModalContent = modal === "dialog" ? content as DeleteSeqDialogModal["content"] : "delete-sequence";
    const { title, buttons, variant, desc } = ModalDialog[currentDialogModalContent];
    const { id, spaceId, instances, isStarting, location } = sequences[0];
    const { handleDeleteSequence, status: deleteStatus } = useDeleteSequence();
    const [selectedLocation, setSelectedLocation] = useState<string>(location);
    const buttonsDisabled = deleteStatus === "pending";
    const selectHub = (value: any) => setSelectedLocation(value);
    const selectOptions = sequences.map(
        (seq) => seq.instances.length > 0 ? {
            opsName: seq.location,
            disabled: true,
            icon: <span className="select__icon"><Server/>&nbsp;({seq.instances.length})</span>
        } : {
            opsName: seq.location,
            disabled: false
        });

    async function handleDelete() {
        if (!(id && spaceId && selectedLocation)) return;

        handleDeleteSequence(id, spaceId, selectedLocation).then(() => {
            setOpenModal({ openModal: null });
            navigate(sequencesListUrl);
        });
    }

    return (
        <>
            <DialogModal
                title={title}
                alignContent="left"
                variant={variant as ModalVariant}
                onClose={() => setOpenModal({ openModal: null })}
                open={openModal.modal === "dialog"}
                buttons={
                    <>
                        <ClickButton
                            text={buttons.cancel.text}
                            variant={buttons.cancel.variant as ButtonVariant}
                            onClick={() => setOpenModal({ openModal: null })}
                            disabled={buttonsDisabled}
                        />
                        <ClickButton
                            text={buttons.delete.text}
                            variant={buttons.delete.variant as ButtonVariant}
                            disabled={isStarting || buttonsDisabled}
                            onClick={handleDelete}
                        />
                    </>
                }
            >
                {desc.replaceAll("${name}", id)}
                {
                    openModal.content === "choose-seq-to-delete" &&
                    <Select
                        marginTop={1}
                        marginBottom={1}
                        variant="big"
                        currentValue={selectedLocation}
                        options={selectOptions}
                        label="Select Location"
                        name="select-location"
                        onChange={selectHub}
                    />
                }
            </DialogModal>
            <StatusModal
                image="error"
                title={StatusModalData["deny-sequence-delete"].title}
                variant="error"
                onClose={() => setOpenModal({ openModal: null })}
                open={openModal.modal === "error"}
                buttons={
                    <ClickButton
                        text={"Show List of Instances"}
                        variant={StatusModalData["deny-sequence-delete"].buttons.cancel.variant as ButtonVariant}
                        onClick={() => {
                            navigate(`${sequencesListUrl}/${id}/list-of-instances`);
                            setOpenModal({ openModal: null });
                        }}
                    />}
            >
                Sequence {id} has {instances?.length} Instance running
            </StatusModal>
        </>
    );
};

export default DeleteModal;
