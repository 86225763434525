import { Link } from "react-router-dom";
import { useState } from "react";
import Box, { BoxList } from "../../components/Box";
import Hub from "./Hub";
import HubAddBox from "./HubAddBox";
import { BasicModal, PromoModal } from "../../components/Modal";
import { ButtonGroup, ClickButton } from "../../components/Buttons";
import PopUpNewsletter from "../../assets/images/popup-newsletter.svg";
import PromoData from "../../data/modals/Promotional.json";
import Video from "../../components/Video";
import Heading from "../../components/Heading";
import { useOnboarding } from "../../store/onboarding/onboardingSlice";
import { useHubsBySpaceId } from "../../store/hubs/hubsSlice";
import HubsTableLoader from "./HubsTableLoader";

const HubsTab: React.FC<HubsTabProps> = ({ spaceId }) => {
    const { hubs, status } = useHubsBySpaceId(spaceId);
    const [showPromo, setShowPromo] = useState(!localStorage.getItem("promoPopupDismissed"));
    const [videoPopup, setVideoPopup] = useState(!localStorage.getItem("videoPopupDismissed"));
    const { setPopoversVisible, currentPopover } = useOnboarding();
    const onboardingEnded = currentPopover === "ended";
    const handleEndPromo = () => {
        localStorage.setItem("promoPopupDismissed", "true");
        setShowPromo(false);
    };
    const handleEndVideoPopup = () => {
        localStorage.setItem("videoPopupDismissed", "true");
        setVideoPopup(false);
        if (!onboardingEnded) setPopoversVisible(true);
    };
    const limitedOffer = PromoData["limited-offers"];

    if (videoPopup) {
        setPopoversVisible(false);
    }

    const renderContent = () => {
        switch (status) {
            case "idle":
            case "pending":
                return <HubsTableLoader />;
            case "rejected":
            case "fulfilled":
                if (hubs.length === 0) {
                    return <p className="margin-b--2">
                        There are no Hubs on Space. The Hub after the crash should respawn. If the problem persists, {" "}
                        <Link to="/support">please contact the support team.</Link>
                    </p>;
                }
                return <BoxList>
                    { hubs.map((hub, index) => <Hub key={hub.id} instancesNumber={hub.instances.length} sequencesNumber={hub.sequences.length} dataQA={`hubs/${index}`} {...hub} />) }
                    <HubAddBox />
                </BoxList>;
            default:
                return "";
        }
    };

    return (
        <div className="tab-menu__content">
            <>
                {limitedOffer.active && (
                    <PromoModal
                        variant="occasional"
                        title={limitedOffer.title}
                        banner={PopUpNewsletter}
                        text={limitedOffer.text}
                        open={false}
                        buttons={
                            <ButtonGroup align="center">
                                <ClickButton
                                    text={limitedOffer.buttons[0].text} variant={"primary"} onClick={handleEndPromo} />
                            </ButtonGroup>
                        }
                        legal={limitedOffer.legal}
                        onClose={handleEndPromo}
                    />
                )}
                <BasicModal
                    variant="onboarding"
                    open={videoPopup && !onboardingEnded}
                    onClose={()=>{}}
                    hiddenX={true}
                >
                    <Heading level="h2" size="subtitle-1" marginTop={2} marginBottom={1} text="HOW TO GET STARTED?" />
                    <Video className="video--onboarding" link="https://www.youtube.com/embed/lvZQipPQ-Sc" width="100%" height="auto"/>
                    <ButtonGroup marginTop={2} marginBottom={1} align="center">
                        <ClickButton
                            text="Continue exploring" variant={"primary"} onClick={handleEndVideoPopup} />
                    </ButtonGroup>
                </ BasicModal>
            </>
            <Box outline="shadow">
                {renderContent()}
            </Box>
        </div>
    );
};

export default HubsTab;
