import PricingInfoBox from "./PricingInfoBox";
import ConnectHubGuide from "./ConnectHubGuide";
import CongratulationModal from "./CongratulationModal";
import { sendEvent } from "../../../utils/gtmEvents";
import SpaceButtonsPortal from "../../SpacesPage/components/SpaceButtonsPortal";
import { ShowLogsAddSequenceButtons } from "../../SpacesPage/components/SpaceButtons";

const HubAdd: React.FC = () => {
    sendEvent("console_add_hub_click");

    return (
        <>
            <SpaceButtonsPortal>
                <ShowLogsAddSequenceButtons />
            </SpaceButtonsPortal>
            <PricingInfoBox />
            <ConnectHubGuide />
            <CongratulationModal />
        </>
    );
};

export default HubAdd;
