import "./ErrorInfo.scss";
import AccessibleImg from "../../layout/AccessibleImg";

const ErrorInfo: React.FC<ErrorInfoProps> = ({ standardImg = "", contrastImg = "", title, description, children }) => {
    return (
        <>
            <div className="error-info">
                <AccessibleImg standardImg={standardImg} contrastImg={contrastImg} className="error-info__image" />
                <h3 className="error-info__title">{title}</h3>
                <p className="error-info__desc">{description}</p>
            </div>
            {children}
        </>
    );
};

export default ErrorInfo;
