import { Lightbulb } from "react-bootstrap-icons";
import CmdCommand from "../../../components/CmdCommand";
import Heading from "../../../components/Heading";
import JSONEditor from "../../../components/JSONHighlighter";

const StartHubConfig: React.FC<StartHubConfigProps> = (jsonConfigFileData) => {
    return (
        <>
            <CmdCommand
                className="margin--t-2"
                description="Use command that will start Hub with config from root path"
                command="scramjet-transform-hub --config <config-file.json>"
            />
            <p className="margin--t-1">
                <Lightbulb />{" "}Please, remember to add the config file to the root directory of the installed STH.
            </p>
            <JSONEditor height="410px" data={jsonConfigFileData.hubConfig} readonly={true} labelText="Config file in JSON format" />
        </>
    );
};

export default StartHubConfig;
