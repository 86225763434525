import { Link } from "react-router-dom";
import Quote from "../../../components/Quote";

const AddSequenceTips: React.FC = () => {
    const seqInfo = <>
        You can use {" "}
        <a className="quote__link" target="__blank" href="https://docs.scramjet.org/platform/samples/">ready-to-use samples, {" "}</a>
        <a className="quote__link" target="__blank" href="https://docs.scramjet.org/platform/templates/">templates, {" "}</a>
        <a className="quote__link" target="__blank" href="https://docs.scramjet.org/platform/sequence-introduction/">or write a sequence from scratch. {" "}</a>
    </>;
    const cliInfo = <>
        To use CLI <Link className="quote__link" to={"/cloud-platform-cli"}>install Scramjet CLI</Link> and generate token in CLI Setup section.
    </>;

    return (
        <>
            <Quote marginTop={2} marginBottom={1} text={seqInfo} wide />
            <Quote marginBottom={2} text={cliInfo} wide />
        </>
    );
};

export default AddSequenceTips;
