import { TabPanels, TabPanel } from "@reach/tabs";
import Error404 from "../ErrorInfo/Error404";

type TabContentParams = {
    tabExists: boolean;
    tabPanels: JSX.Element[];
    slugs: string[],
    flex: boolean,
};

const TabContent: React.FC<TabContentParams> = ({ tabExists, tabPanels, slugs, flex })=>{
    if (!tabExists){
        return <Error404 />;
    }
    return (
        <TabPanels className="tab-menu__container">
            {tabPanels.map((context, index) => {
                return (
                    <TabPanel key={index} wd-data={slugs[index]}>
                        <div className={`${flex ? "flex" : ""} tab-menu__context`}>{context}</div>
                    </TabPanel>
                );
            })}
        </TabPanels>
    );
};

export default TabContent;
