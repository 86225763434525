import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import fastDeepEqual from "fast-deep-equal";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "..";
import { getMiddlewateClient } from "../../components/MiddlewareClientProvider";
import { selectHubsBySpaceId, useHubById, useHubsBySpaceId } from "../hubs/hubsSlice";
import { startSequence } from "../sequences/sequencesSlice";
import InstanceMap from "./InstanceMap";

const stopTimeout = 3000;
const sliceName = "instances";
const instancesAdapter = createEntityAdapter<Instance>();
const initialState: InstancesState = instancesAdapter.getInitialState({
    status: {},
});
const selectSelf = (state: RootState): InstancesState => state[sliceName];

export const { selectAll: selectInstances, selectById: selectInstanceById } =
    instancesAdapter.getSelectors<RootState>(selectSelf);
export const selectInstancesStatusByHubId = (hubId: HubId) =>
    createSelector(selectSelf, state => {
        return state.status[hubId] || "idle";
    });
export const selectInstancesStatus = () =>
    createSelector(selectSelf, state => {
        const statuses = Object.values(state.status);

        if (statuses.includes("idle")) return "idle";
        if (statuses.includes("pending")) return "pending";
        if (statuses.includes("fulfilled")) return "fulfilled";
        if (statuses.includes("rejected")) return "rejected";

        return "idle";
    });
export const selectInstancesByHubId = (hubId: HubId) =>
    createSelector(selectInstances, instances => instances.filter(instance => instance.hubId === hubId));
export const selectInstancesBySpaceId = (spaceId: SpaceId) => (state: RootState) => {
    const hubIds = selectHubsBySpaceId(spaceId)(state).map(hub => hub.id);
    const instances = selectInstances(state);

    return instances.filter(instance => hubIds.includes(instance.hubId));
};

export const fetchSpaceInstances = createAsyncThunk<
    Instance[],
    { spaceId: SpaceId; hubId: HubId; refetch?: boolean },
    { state: RootState }
>(
    `${sliceName}/fetchBySpaceId`,
    async ({ spaceId, hubId }) => {
        const spaceClient = getMiddlewateClient().getManagerClient(spaceId);
        const hubClient = spaceClient.getHostClient(hubId);
        const instances = (await hubClient.listInstances()).map(inst => InstanceMap.toDTO(inst, hubId, spaceId));

        return instances;
    },
    {
        // Cache between page loads and until refetch
        condition: ({ hubId, refetch }, { getState }) => {
            const status = selectInstancesStatusByHubId(hubId)(getState());

            return !!hubId && (refetch || (status !== "fulfilled" && status !== "pending"));
        },
    }
);

export const killInstance = createAsyncThunk<
    Instance,
    { spaceId: SpaceId; hubId: HubId; instanceId: InstanceId; refetch?: boolean },
    { state: RootState }
>(`${sliceName}/killInstance`, async ({ spaceId, hubId, instanceId }) => {
    const spaceClient = getMiddlewateClient().getManagerClient(spaceId);
    const hubClient = spaceClient.getHostClient(hubId);
    const instanceClient = hubClient.getInstanceClient(instanceId);

    await instanceClient.kill();

    const newInstance = InstanceMap.toDTO(await instanceClient.getInfo(), hubId, spaceId);

    newInstance.status = "loading";

    return newInstance;
});

export const stopInstance = createAsyncThunk<
    Instance,
    { spaceId: SpaceId; hubId: HubId; instanceId: InstanceId; refetch?: boolean },
    { state: RootState }
>(`${sliceName}/stopInstance`, async ({ spaceId, hubId, instanceId }) => {
    const spaceClient = getMiddlewateClient().getManagerClient(spaceId);
    const hubClient = spaceClient.getHostClient(hubId);
    const instanceClient = hubClient.getInstanceClient(instanceId);

    await instanceClient.stop(stopTimeout, false);

    const newInstance = InstanceMap.toDTO(await instanceClient.getInfo(), hubId, spaceId);

    newInstance.status = "loading";

    return newInstance;
});

export const instancesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        syncUpdate: (state, action: PayloadAction<Instance[]>) => {
            const instancesForRemoval = state.ids.filter(
                instanceId => !action.payload.find(search => search.id === instanceId)
            );

            instancesAdapter.removeMany(state, instancesForRemoval);
            instancesAdapter.upsertMany(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSpaceInstances.pending, (state, action) => {
                state.status[action.meta.arg.hubId] = "pending";
            })
            .addCase(fetchSpaceInstances.fulfilled, (state, action) => {
                state.status[action.meta.arg.hubId] = "fulfilled";
                instancesAdapter.upsertMany(state, action.payload);
            })
            .addCase(fetchSpaceInstances.rejected, (state, action) => {
                state.status[action.meta.arg.hubId] = "rejected";
            })
            .addCase(killInstance.pending, (state, action) => {
                instancesAdapter.updateOne(state, { id: action.meta.arg.instanceId, changes: { status: "loading" } });
            })
            .addCase(killInstance.fulfilled, (state, action) => {
                instancesAdapter.upsertOne(state, action.payload);
            })
            .addCase(killInstance.rejected, (state, action) => {})
            .addCase(stopInstance.pending, (state, action) => {
                instancesAdapter.updateOne(state, { id: action.meta.arg.instanceId, changes: { status: "loading" } });
            })
            .addCase(stopInstance.fulfilled, (state, action) => {
                instancesAdapter.upsertOne(state, action.payload);
            })
            .addCase(stopInstance.rejected, (state, action) => {})
            .addCase(startSequence.fulfilled, (state, action) => {
                instancesAdapter.upsertOne(state, action.payload);
            });
    },
});

export const useInstancesBySpaceId = (
    spaceId: SpaceId
): { instances: Instance[]; status: "idle" | "pending" | "fulfilled" | "rejected" } => {
    const { hubs } = useHubsBySpaceId(spaceId);
    const dispatch = useAppDispatch();

    useEffect(() => {
        for (const hub of hubs) {
            dispatch(fetchSpaceInstances({ spaceId, hubId: hub.id }));
        }
    }, [hubs]);

    const instances = useSelector(selectInstancesBySpaceId(spaceId), fastDeepEqual);
    const status = useSelector(selectInstancesStatus(), fastDeepEqual);

    return { instances, status };
};

export const useInstancesByHubId = (hubId: HubId) => {
    const hub = useHubById(hubId);
    const spaceId = hub?.spaceId || "";
    const { instances, status } = useInstancesBySpaceId(spaceId);
    const hubInstances = useSelector(selectInstancesByHubId(hubId), fastDeepEqual);

    return { instances: hubInstances, status };
};

export const useInstancesBySequenceId = (sequenceId: SequenceId) => {
    const instances = useSelector(selectInstances, fastDeepEqual);

    return instances.filter(instance => instance.sequence.id === sequenceId);
};

export default instancesSlice.reducer;
