import { useEffect, useState } from "react";

const SpacesLoaderTexts: React.FC = () => {
    const texts = [
        "",
        "preparing users infrastructure",
        "setting up users database",
        "configuring space"
    ];
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrent((current + 1) % texts.length);
        }, 3000);

        return () => {
            clearInterval(interval);
        };
    });

    return <>{texts[current]}</>;
};

export default SpacesLoaderTexts;
