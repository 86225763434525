import { useState } from "react";
import { ClickButton } from "../Buttons";
import { ClipboardCheck, Clipboard } from "react-bootstrap-icons";

export const CopyButton: React.FC<CopyButtonProps> = ({ onCopy, onSuccess, ...rest }) => {
    const [isCopied, setIsCopied] = useState(false);
    const copyCommand = async (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const data = onCopy();

        setIsCopied(false);
        // ToDo: think about animation
        if (data) await navigator.clipboard.writeText(data);
        setTimeout(() => {
            setIsCopied(true);
            if (typeof onSuccess === "function") onSuccess();
        }, 200);
    };

    return <ClickButton
        addClass={isCopied ? "copied" : ""}
        icon={ isCopied ? (
            <ClipboardCheck size={20} aria-hidden="true" />
        ) : (
            <Clipboard size={20} aria-hidden="true" />
        )}
        onClick={copyCommand}
        {...rest}
    />;
};
