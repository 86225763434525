if (typeof TextEncoderStream === "undefined") {
    const tes = {
        start() {
            this.encoder = new TextEncoder();
        },
        transform(chunk, controller) {
            controller.enqueue(this.encoder.encode(chunk));
        },
    };

    let _jstes_wm = new WeakMap(); /* info holder */ // eslint-disable-line

    class TextEncoderStream extends TransformStream {
        constructor() {
            let t = { ...tes };

            super(t);
            _jstes_wm.set(this, t); // eslint-disable-line
        }
        get encoding() {
            return _jstes_wm.get(this).encoder.encoding; // eslint-disable-line
        }
    }

    global.TextEncoderStream = TextEncoderStream;
}

if (typeof TextDecoderStream === "undefined") {
    const tds = {
        start() {
            this.decoder = new TextDecoder(this.encoding, this.options);
        },
        transform(chunk, controller) {
            controller.enqueue(this.decoder.decode(chunk, { stream: true }));
        },
    };

    let _jstds_wm = new WeakMap(); /* info holder */ // eslint-disable-line

    class TextDecoderStream extends TransformStream {
        constructor(encoding = "utf-8", { ...options } = {}) {
            let t = { ...tds, encoding, options };

            super(t);
            _jstds_wm.set(this, t); // eslint-disable-line
        }
        get encoding() {
            return _jstds_wm.get(this).decoder.encoding; // eslint-disable-line
        }
        get fatal() {
            return _jstds_wm.get(this).decoder.fatal; // eslint-disable-line
        }
        get ignoreBOM() {
            return _jstds_wm.get(this).decoder.ignoreBOM; // eslint-disable-line
        }
    }

    global.TextDecoderStream = TextDecoderStream;
}
