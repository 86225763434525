import { CheckCircleFill, CloudUploadFill, Folder, XCircleFill } from "react-bootstrap-icons";
import CircleLoader from "../CircleLoader";
import React from "react";

const textClasses = "upload-sequence__text body-1";
const labelText = "2. Upload your sequence";
const DraggedTmplState = () => <>
    <CloudUploadFill className="upload-sequence__icon" />
    <div className="upload-sequence__text body-1">Drop the file here</div>
</>;
const UploadingTmplState = () => <>
    <div className={textClasses}>Uploading your package</div>
    <CircleLoader />
</>;
const SuccessTmplState = () => <>
    <div className={textClasses}>Uploaded successfully!</div>
    <CheckCircleFill className="upload-sequence__icon" />
</>;
const ErrorTmplState: React.FC<{error: string}> = ({ error }) => <>
    <div className={textClasses}>Sequence not uploaded</div>
    <XCircleFill className="upload-sequence__icon upload-sequence__icon--error" />
    {error && <div className="upload-sequence__text upload-sequence__text--small">{error}</div>}
</>;
const IdleTmplState = () => <>
    <Folder className="upload-sequence__icon" />
    <div className={textClasses}>
        Drag & Drop or Select file to upload <br />
        <span className="upload-sequence__text--hint">tar.gz archive</span>
    </div>
</>;

export {
    labelText,
    UploadingTmplState,
    SuccessTmplState,
    ErrorTmplState,
    IdleTmplState,
    DraggedTmplState
};
