import { useEffect, useState } from "react";
import { differenceInSeconds } from "date-fns";
import { useSpaceContext } from "../../SpacesPage";
import AccessibleImg from "../../../layout/AccessibleImg";
import { InternalLinkButton } from "../../../components/Buttons";
import BootstrapIcon from "../../../components/BootstrapIcon";
import { StatusModal } from "../../../components/Modal";
import ConfettiImg from "../../../assets/images/confetti.svg";
import "./CongratulationModal.scss";
import { useHubsBySpaceId } from "../../../store/hubs/hubsSlice";

const CongratulationModal: React.FC = () => {
    const { space } = useSpaceContext();
    const { hubs } = useHubsBySpaceId(space.id);
    const [showNewHubName, setShowNewHubName] = useState("");

    useEffect(() => {
        if (!hubs.length) return;

        const newHubAdded = hubs.filter((hub) => differenceInSeconds(
            new Date(),
            new Date(hub.info.created)) < 10
        );

        if (newHubAdded.length > 0) {
            setShowNewHubName(newHubAdded[0].id);
        }
    }, [hubs]);

    return (
        <StatusModal
            title="Congratulations!"
            onClose={() => setShowNewHubName("")}
            open={showNewHubName !== ""}
            image={<AccessibleImg className="margin--b-2 margin--t-1" standardImg={ConfettiImg} contrastImg={ConfettiImg} alt="Exploding Confetti"/>}
            buttons={[
                <InternalLinkButton
                    key="go-to-space"
                    text="Go to Space"
                    variant="secondary"
                    icon={<BootstrapIcon ico="dashboard" size={15} fill="icon-primary" />}
                    to="/space/hubs"
                    addClass="congratulation-modal__content"
                />
            ]}
        >
            <p className="body-1 margin--b-1">
                The <strong>{ showNewHubName }</strong> Self Hosted Hub{" "}
                has been&nbsp;connected to your space
            </p>
        </StatusModal>
    );
};

export default CongratulationModal;
