import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "..";
import { getMiddlewateClient } from "../../components/MiddlewareClientProvider";
import fastDeepEqual from "fast-deep-equal";

const sliceName = "spaces";
const spacesAdapter = createEntityAdapter<Space>();
const initialState: SpacesState = spacesAdapter.getInitialState({
    status: "idle",
});
const selectSelf = (state: RootState): SpacesState => state[sliceName];

export const { selectAll: selectSpaces, selectById: selectSpaceById } =
    spacesAdapter.getSelectors<RootState>(selectSelf);
export const selectSpacesState = createSelector(selectSelf, state => state.status);

export const fetchAllSpaces = createAsyncThunk<Space[], undefined, { state: RootState }>(
    `${sliceName}/fetchAll`,
    async () => {
        const spaces = (await getMiddlewateClient().getManagers()) as Space[];

        return spaces;
    },
    {
        // Cache between page loads
        condition: (_, { getState }) => {
            const loading = selectSpacesState(getState() as RootState);

            return loading !== "fulfilled" && loading !== "pending";
        },
    }
);

export const spaceSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllSpaces.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(fetchAllSpaces.fulfilled, (state, action) => {
                state.status = "fulfilled";
                spacesAdapter.upsertMany(state, action.payload);
            })
            .addCase(fetchAllSpaces.rejected, (state, action) => {
                state.status = "rejected";
            });
    },
});

export const useSpaces = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAllSpaces());
    }, []);

    const status = useSelector(selectSpacesState, fastDeepEqual);
    const spaces = useSelector(selectSpaces, fastDeepEqual);

    return { status, spaces };
};

export const useSpaceByIndex = (index: number) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAllSpaces());
    }, []);

    const status = useSelector(selectSpacesState, fastDeepEqual);
    const space = useSelector<RootState, Space | undefined>((state: RootState) => {
        const spaces = selectSpaces(state);

        return spaces[index] || undefined;
    }, fastDeepEqual);

    return { status, space };
};

export default spaceSlice.reducer;
