import { ClientUtils } from "@scramjet/client-utils";
import { middlewareUrl } from "../components/MiddlewareClientProvider";
import { useEffect } from "react";
import { useAuthentication } from "../components/AuthenticationProvider";

export async function useLogoutDeletedAccount(status: string) {
    const { logout, user } = useAuthentication();

    useEffect(() => {
        if (user === undefined) {
            return;
        }
        (async () => {
            try {
                const clientUtils = new ClientUtils(middlewareUrl);

                await clientUtils.get<User>(`/api/v1/user/${user?.id}`);

            } catch (e: any) {
                status = e.status;
                if (status === "403") {
                    logout({ logoutParams: { returnTo: window.location.origin } });
                }
            }
        })();
    }, [status]);
}
