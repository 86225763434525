import { ClientUtils } from "@scramjet/client-utils";
import { sendErrorEvent, sendEvent } from "../utils/gtmEvents";
import { middlewareUrl } from "../components/MiddlewareClientProvider";

export async function useAddPayment() {
    try {
        const clientUtils = new ClientUtils(middlewareUrl);
        const result = await clientUtils.get<{ paymentUrl: string }>("/api/v1/add-payment-method");

        sendEvent("console_add_payment_method");

        if (result) window.location.href = result.paymentUrl;
    } catch (e) {
        console.error(e);
        sendErrorEvent("console_add_payment_method", e);
    }
}
