import {
    Navigate,
    Route,
    RouterProvider,
    createBrowserRouter,
    createRoutesFromElements,
} from "react-router-dom";
import React, { PropsWithChildren, Suspense, lazy } from "react";
import Base from "../layout/Base";
import Headless from "../layout/Headless";
import NotFoundPage from "../pages/NotFoundPage";
import SpacesPage from "../pages/SpacesPage";
import InstanceDetails from "../pages/InstancePage/InstanceDetails";
import SequenceAdd from "../pages/SequencePage/AddSequence";
import SequenceDetails from "../pages/SequencePage/SequenceDetails";
import SequenceStart from "../pages/SequencePage/StartSequence";
import SpaceLogs from "../pages/SpacesPage/SpaceLogs";
import SpaceTabs from "../pages/SpacesPage/SpaceTabs";
import HubAdd from "../pages/HubPage/AddHub";
import HubDetails from "../pages/HubPage/HubDetails";
import ProductPricingPage from "../pages/ProductPricingPage";
import { HubLoader, InstanceLoader, SequenceLoader } from "./RouteLoader";
import ProtectedRoute from "./ProtectedRoute";
import {
    HubAddCrumb,
    HubDetailsCrumb,
    HubsCrumb,
    InstanceDetailsCrumb,
    InstancesCrumb,
    SequenceAddCrumb,
    SequenceDetailsCrumb,
    SequenceStartCrumb,
    SequencesCrumb,
    SpaceCrumb,
    TopicsCrumb
} from "./Crumbs";

const PageLoader: React.FC<PropsWithChildren> = ({ children }) =>
    <Suspense fallback={<div>Loading...</div>}>{children}</Suspense>;
const ProfilePage = lazy(() => import("../pages/ProfilePage"));
const SubscriptionPage = lazy(() => import("../pages/SubscriptionPage"));
const DocsPage = lazy(() => import("../pages/DocsPage"));
const SupportPage = lazy(() => import("../pages/SupportPage"));
const SettingsPage = lazy(() => import("../pages/CloudPlatformCliPage"));
const SpaceAccessKeys = lazy(() => import("../pages/SpaceAccessKeysPage"));
const AppRouter = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                <Route
                    element={<ProtectedRoute isNotAllowed="inactive" redirectPath="/subscription" layout={<Base />} />}
                >
                    <Route path="/" element={<Navigate to="/space/hubs" replace />} />
                    <Route path="/cloud-platform-cli" element={<PageLoader> <SettingsPage /> </PageLoader>} />
                    <Route path="/space-access-keys" element={<PageLoader><SpaceAccessKeys /></PageLoader>} />
                    <Route path="/space" element={<SpacesPage />} handle={{ crumb: SpaceCrumb }}>
                        <Route path="logs" element={<SpaceLogs />} />
                        <Route path="hubs" element={<SpaceTabs />} handle={{ crumb: HubsCrumb, breadcrumbs: true }}>
                            <Route path="add" element={<HubAdd />} handle={{ crumb: HubAddCrumb, breadcrumbs: true }}/>
                            <Route path=":hubId" element={<HubLoader />}>
                                {/* hubTabName is just to not repeat same line multiple times */}
                                <Route path=":hubTabName" element={<HubDetails />} handle={{ crumb: HubDetailsCrumb, breadcrumbs: true }}/>
                            </Route>
                        </Route>
                        <Route path="sequences" element={<SpaceTabs />} handle={{ crumb: SequencesCrumb, breadcrumbs: true }}>
                            <Route path="add" element={<SequenceAdd />} handle={{ crumb: SequenceAddCrumb, breadcrumbs: true }}/>
                            <Route path=":sequenceId" element={<SequenceLoader />}>
                                <Route path="start" element={<SequenceStart />} handle={{ crumb: SequenceStartCrumb, breadcrumbs: true }}/>
                                {/* sequenceTabName is just to not repeat same line multiple times */}
                                <Route path=":sequenceTabName" element={<SequenceDetails />} handle={{ crumb: SequenceDetailsCrumb, breadcrumbs: true }} />
                            </Route>
                        </Route>
                        <Route path="instances" element={<SpaceTabs />} handle={{ crumb: InstancesCrumb, breadcrumbs: true }}>
                            <Route path=":instanceId" element={<InstanceLoader />}>
                                {/* instanceTabName is just to not repeat same line multiple times */}
                                <Route path=":instanceTabName" element={<InstanceDetails />} handle={{ crumb: InstanceDetailsCrumb, breadcrumbs: true }}/>
                            </Route>
                        </Route>
                        <Route path="topics" element={<SpaceTabs />} handle={{ crumb: TopicsCrumb, breadcrumbs: true }}/>
                    </Route>
                </Route>
                <Route element={
                    <ProtectedRoute
                        isNotAllowed="active"
                        redirectPath="/subscription"
                        layout={<Headless />}
                    />
                }>
                    <Route path="/start-trial" element={<ProductPricingPage />} />
                </Route>
                <Route path="/" element={<Base />}>
                    <Route index path="/profile" element={<PageLoader><ProfilePage /></PageLoader>} />
                    <Route index path="/subscription" element={<PageLoader><SubscriptionPage /></PageLoader>} />
                    <Route index path="/docs" element={<PageLoader><DocsPage /></PageLoader>} />
                    <Route index path="/support" element={<PageLoader><SupportPage /></PageLoader>} />
                </Route>
                <Route index path="*" element={<NotFoundPage />} />
            </>
        )
    );

    return <RouterProvider router={router} />;
};

export default AppRouter;
