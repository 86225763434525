import { Download, Upload } from "react-bootstrap-icons";
import JSONInput from "react-json-editor-ajrm";
import { useSelector } from "react-redux";
import locale from "../../data/JsonEditor.json";
import { RootState } from "../../store";
import ActionBar from "../ActionBar";
import { ExternalLinkButton } from "../Buttons";
import { CopyButton } from "../CopyButton";
import { useToast } from "../Toast/Toasts";
import { UploadButton } from "../UploadButton";
import { UploadLayer } from "../UploadLayer";

const JSONEditor: React.FC<JSONEditorParams> = ({
    data,
    className,
    onChange = () => {},
    filename = "data.json",
    readonly = false,
    height = "50vh",
    labelText = ""
}) => {
    const themeMode = useSelector((state: RootState) => state.common.mode);
    const showToast = useToast();

    let stringifiedData = "";
    let downloadUrl = "";

    try {
        stringifiedData = JSON.stringify(data);
        downloadUrl = URL.createObjectURL(new Blob([stringifiedData]));
    } catch (e: any) {
        console.log(e.message);
    }

    function handleCopy() {
        return stringifiedData;
    }

    function handleCopySuccess() {
        // TODO: show small popover
    }

    async function handleFileUpload(files: FileList | null) {
        if (!files) return;

        if (files.length !== 1) {
            showToast({ text: "You can send only one file at a time.", variant: "error" });
            return;
        }

        const filename = files[0].name;
        const extension = filename.substring(filename.length - 5, filename.length);
        const type = files[0].type;
        const file = files[0];

        if (!["application/json", "application/x-json"].includes(type) || extension !== ".json") {
            showToast({ text: "File should be json format.", variant: "error" });
            return;
        }

        try {
            onChange(JSON.parse(await file.text()));
            showToast({ text: "Overwritten with the file provided.", variant: "success" });
        } catch (e: any) {
            showToast({ text: `Error while parsing the file provided. ${e.message}`, variant: "error" });
        }
    }

    function handleChange(data: JSONEditorOnChangeParams) {
        onChange(data.jsObject);
    }

    return (
        <>
            <UploadLayer className={className} onSuccess={handleFileUpload} clickable={false} readonly={readonly} label={labelText} id="id-sequence">
                {({ isDragged }) => (
                    <div style={{ opacity: isDragged ? "0.5" : "1" }}>
                        <ActionBar title="JSON">
                            {readonly === false && (
                                <UploadButton
                                    icon={<Upload size={20}/>}
                                    variant="action"
                                    text="Upload"
                                    onSuccess={handleFileUpload}
                                />
                            )}
                            <ExternalLinkButton
                                variant="action"
                                text="Download"
                                icon={<Download size={20} />}
                                href={downloadUrl}
                                download={filename}
                            />
                            <CopyButton
                                variant="action"
                                text="Copy"
                                onCopy={handleCopy}
                                onSuccess={handleCopySuccess}
                            />
                        </ActionBar>
                        <JSONInput
                            id="a_unique_id"
                            placeholder={data}
                            locale={locale}
                            height={height}
                            theme={themeMode === "dark" ? "dark_vscode_tribute" : "light_mitsuketa_tribute"}
                            colors={{
                                default: "var(--json-default)",
                                background: "var(--bg-json)",
                            }}
                            style={{
                                body: { fontSize: "1.4rem" },
                                outerBox: { width: "100%" },
                                container: { width: "100%" },
                            }}
                            onChange={handleChange}
                            waitAfterKeyPress={3000}
                            confirmGood={!readonly}
                            viewOnly={readonly}
                        />
                    </div>
                )}
            </UploadLayer>
        </>
    );
};

export default JSONEditor;
