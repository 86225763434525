import "./FullscreenLoader.scss";
import DogAstronautImg from "../../assets/images/dog-astronaut.svg";
import { PropsWithChildren } from "react";

const FullscreenLoader: React.FC<PropsWithChildren<FullscreenLoaderProps>> = ({ children, minHeightRatio = 0 }) => {
    return (
        <div className="fullscreen-loader">
            {!!minHeightRatio && <div style={{ paddingTop: `${minHeightRatio}%` }}></div>}
            <img src={DogAstronautImg} className="fullscreen-loader__image" alt="loader" />
            {children && <div className="fullscreen-loader__children body-1">{children}</div>}
        </div>
    );
};

export default FullscreenLoader;
