import "./Label.scss";

const Label: React.FC<LabelProps> = ({ className, position = "t-center", text, color = "blue", scale = "big" }) => {

    return (
        <span
            className={`label label--${color} label--${scale} label--${position} ${className}`}>
            {text}
        </span>
    );
};

export default Label;
