import { SequenceInfo } from "@scramjet/types";

export default class SequenceMap {
    static toDTO(source: SequenceInfo, spaceId: SpaceId, isStarting: boolean = false): Sequence {
        return {
            uuid: `${source.location.trim()}-${source.id}`,
            id: source.id,
            config: source.config,
            name: source?.name,
            location: source.location,
            instances: Array.from(source.instances),
            spaceId,
            isStarting,
        };
    }
    static filterInvalidSequences(sequence: SequenceInfo): boolean {
        return !!sequence.id;
    }
}
