import CmdCommand from "../../../components/CmdCommand";

const StartHubParams: React.FC<StartHubParamsProps> = ({ platform }) => {
    const { api, space, apiKey } = platform;
    const sthCommand = `scramjet-transform-hub \
        --platform-api "${api}" \
        --platform-space "${space}" \ 
        --platform-api-key "${apiKey}" \ 
        --description "Some description" \ 
        --tags "tag1, tag2" \ 
        --id "SELF_HOSTED_STH-1"`;

    return (
        <>
            <CmdCommand
                className="margin--t-2"
                description="Copy and paste command with Accesses Key into terminal"
                command={sthCommand}
                wrap
            />
        </>
    );
};

export default StartHubParams;
