import { PropsWithChildren } from "react";
import { usePopover } from "../../store/onboarding/onboardingSlice";
import { InternalLinkButton } from "../../components/Buttons";

const MainNavPopover: React.FC<PropsWithChildren> = ({ children }) => {
    const { popoverData, displayPopover, nextPopover, Popover } = usePopover("start");

    return (
        <Popover
            title={popoverData?.title as string}
            text={popoverData?.text as string}
            position={popoverData?.position as PositionProps}
            content={
                <InternalLinkButton variant="primary" text="Next" to="/cloud-platform-cli" onClick={() => {
                    nextPopover();
                }} />
            }
            counter={1}
            display={displayPopover}
        >
            {children}
        </Popover>
    );
};

export default MainNavPopover;
