import Table from "../../components/Table";
import { useTopicsBySpaceId } from "../../store/topics/topicsSlice";

const TopicsTab: React.FC<TopicsTabProps> = ({ spaceId }) => {
    const { topics } = useTopicsBySpaceId(spaceId);

    return (
        <Table rows={["No.", "Topic name", "Content type", "Actors"]}>
            {topics.map((topic, index) => {
                const { name, contentType, actors } = topic;

                return (
                    <tr key={index} data-qa={`topics/${index}`}>
                        <td className="table__id">{index + 1}</td>
                        <td className="table__name">
                            <p className="subtitle-2">{name}</p>
                        </td>
                        <td className="table__name">
                            <p>{contentType}</p>
                        </td>
                        <td className="table__actors">
                            <p>{actors.map(actor => actor.hostId).join(", ")}</p>
                        </td>
                    </tr>
                );
            })}
        </Table>
    );
};

export default TopicsTab;
