import ProductTrial from "./ProductTrial";
import FullscreenLoader from "../../components/FullscreenLoader";
import { useStartTrial } from "../../hooks/useStartTrial";
import { getDefaultPrice, useProducts } from "../../hooks/useProducts";
import { useToast } from "../../components/Toast/Toasts";

const ProductPricing: React.FC = () => {
    const showToast = useToast();
    const { nativeProduct, shhProduct, status: productsStatus } = useProducts();
    const { callback: startTrial, status: trialStatus, error: trialError } = useStartTrial("/");

    if (trialError) {
        showToast({ variant: "error", text: trialError });
    }

    if (trialStatus === "success") {
        showToast({ variant: "success", text: "Trial started. Setting up the environment." });
    }

    if (productsStatus === "error") {
        showToast({ variant: "error", text: "Error while retrieving products list. Please try again later." });
    }

    // TODO: handle error here
    if (!nativeProduct || !shhProduct) return <FullscreenLoader />;

    const nativePriceId = getDefaultPrice(nativeProduct);
    const shhPriceId = getDefaultPrice(shhProduct);
    const handleStartTrial = () => {
        if (!nativePriceId || !shhPriceId) {
            showToast({ variant: "error", text: "Error while retrieving prices. Please try again later." });
            return;
        }
        startTrial(nativePriceId.id, shhPriceId.id);
    };

    return (
        <div className="products-view">
            <ProductTrial callback={handleStartTrial} />
        </div>
    );
};

export default ProductPricing;
