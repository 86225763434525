import Table from "../../components/Table";
import { useSequencesBySpaceId } from "../../store/sequences/sequencesSlice";
import SequencesTable from "./SequencesTable";
import TableLoader from "./TableLoader";

const SequencesTab: React.FC<SequencesTabProps> = ({ spaceId }) => {
    const { sequences, status } = useSequencesBySpaceId(spaceId);

    switch (status) {
        case "idle":
        case "pending":
            return <TableLoader />;
        case "rejected":
        case "fulfilled":
        default:
            return (
                <Table rows={["Sequence", "Engine", "Location", "Instances", "Actions"]}>
                    {sequences.map((seq, index) => <SequencesTable sequence={seq} key={index} index={index}/>)}
                </Table>
            );
    }
};

export default SequencesTab;
