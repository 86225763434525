import BootstrapIcon from "../../../components/BootstrapIcon";
import Container from "../../../components/Container";
import FullscreenLoader from "../../../components/FullscreenLoader";
import TopSection from "../../../layout/TopSection";

const SpacesPending: React.FC = () => (
    <Container className="space">
        <TopSection
            title="Scramjet Cloud Platform&nbsp;Space"
            icon={<BootstrapIcon ico="dashboard" size={25} fill="icon-primary" />}
        />
        <h2 className="subtitle-2 margin--b-2">Loading your space environment...</h2>
        <FullscreenLoader />
    </Container>
);

export default SpacesPending;
