import CopyText from "../../components/CopyText";
import Heading from "../../components/Heading";
import "./TopSection.scss";

const TopSection: React.FC<TopSectionProps> = ({
    title,
    spaceId,
    variant = "title",
    icon,
    children,
    className = "",
    align = "",
    marginBottom = "",
    marginTop = "",
}) => {
    return (
        <section
            className={
                `top ${className && className}${align && " top--" + align}` +
                `${marginTop && " margin--t-" + marginTop}` +
                `${marginBottom && " margin--b-" + marginBottom}`
            }
        >
            <div className="top__headings">
                <div className="title">
                    {icon} {variant === "title" ? <h1>{title}</h1> : <Heading text={title} level="h2" size="subtitle-1" />}
                </div>
                {spaceId && <h2 className="subtitle-2 margin--b-2 margin--t-3">Space ID: {spaceId}<CopyText text={spaceId} /></h2>}
            </div>
            {children}
        </section>
    );
};

export default TopSection;
