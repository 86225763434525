
import { Link } from "react-router-dom";
import Quote from "../../../components/Quote";
import { useSubscriptions } from "../../../store/subscriptinos/subscriptionsSlice";

const PricingInfoBox: React.FC = () => {
    const { selfHostedLimit, latestSubscription } = useSubscriptions();
    const trialSubsInfo =
        <p className="body-1">During Trial period you can have <strong>1 active</strong>{" "}
            Self-Hosted hub. Enjoying hubs? Get more self-hosted hubs for&nbsp;200$/per unit. {" "}
            <Link className="quote__link" to="/subscription">Pricing details here</Link>.
        </p>;
    const nativeSubsInfo =
        <p className="body-1"><strong>You don&#39;t have access to Self-Hosted Hubs.</strong> {" "}
            Bring your work to the next level, get self-hosted hubs for&nbsp;200$/per unit. {" "}
            <Link className="quote__link" to="/subscription">Pricing details here</Link>.
        </p>;
    const hybridSubsInfo =
        <p className="body-1">Bring your work to the next level with more Self-Hosted Hubs. {" "}
            <Link className="quote__link" to="/subscription">Pricing details here</Link>.
        </p>;

    if (latestSubscription?.status === "trialing") return <Quote icon={null} marginBottom={3} text={trialSubsInfo} wide />;
    if (selfHostedLimit === null || selfHostedLimit === 0) // TODO: Add to Buy Hub btn popup with calc
        return <Quote icon={null} marginBottom={3} text={nativeSubsInfo} btnText="Buy Hub" btnLink="/subscription" wide />;
    return <Quote icon={null} marginBottom={3} text={hybridSubsInfo} btnText="Check Details" btnLink="/subscription" wide />;
};

export default PricingInfoBox;
