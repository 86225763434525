import { PayloadAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState, useAppDispatch } from "..";
import { ClientUtils } from "@scramjet/client-utils";
import { middlewareUrl } from "../../components/MiddlewareClientProvider";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import fastDeepEqual from "fast-deep-equal";
import { deleteSequence, sendSequence, startSequence } from "../sequences/sequencesSlice";
import { sendItemToStore } from "../storeItems/storeItemsSlice";

const sliceName = "entities";
const entitiesAdapter = createEntityAdapter<Entities>({
    selectId: (entities: Entities) => entities.spaceId,
});
const initialState: EntitiesState = entitiesAdapter.getInitialState({
    status: {},
});
const selectSelf = (state: RootState): EntitiesState => state[sliceName];

export const { selectAll, selectById } = entitiesAdapter.getSelectors<RootState>(selectSelf);
export const selectEntitiesBySpaceId = (spaceId: SpaceId) =>
    createSelector(
        (state: RootState) => selectById(state, spaceId),
        entities => entities
    );
export const selectEntitiesStatus = (spaceId: SpaceId) =>
    createSelector(selectSelf, state => state.status[spaceId] || "idle");

export const fetchEntitiesBySpaceId = createAsyncThunk<
    Entities,
    { spaceId: SpaceId; refresh?: boolean },
    { state: RootState }
>(
    `${sliceName}/fetchAll`,
    async ({ spaceId }) => {
        const clientUtils = new ClientUtils(middlewareUrl);
        const data = await clientUtils.get<EntitiesResponse>(`/api/v1/space/${spaceId}/api/v1/entities`);

        return {
            spaceId,
            ...data,
        };
    },
    {
        // Cache between page loads
        condition: ({ spaceId, refresh }, { getState }) => {
            const status = selectEntitiesStatus(spaceId)(getState() as RootState);

            return refresh || ["idle", "rejected"].includes(status);
        },
    }
);

export const entitiesSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        syncUpdate: (state, action: PayloadAction<Entities[]>) => {
            entitiesAdapter.upsertMany(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchEntitiesBySpaceId.pending, (state, action) => {
                state.status[action.meta.arg.spaceId] = "pending";
            })
            .addCase(fetchEntitiesBySpaceId.fulfilled, (state, action) => {
                state.status[action.meta.arg.spaceId] = "fulfilled";
                entitiesAdapter.upsertOne(state, action.payload);
            })
            .addCase(fetchEntitiesBySpaceId.rejected, (state, action) => {
                state.status[action.meta.arg.spaceId] = "rejected";
            });
        builder.addCase(startSequence.fulfilled, (state, action) => {
            const spaceId = action.meta.arg.spaceId;
            const newInstanceId = action.payload.id;
            const newInstances = Array.from(new Set(state.entities[spaceId]?.instances || []).add(newInstanceId));

            entitiesAdapter.updateOne(state, {
                id: spaceId,
                changes: {
                    instances: newInstances,
                },
            });
        });
        builder.addCase(sendSequence.fulfilled, (state, action) => {
            const spaceId = action.meta.arg.spaceId;
            const addedSequence = action.payload.id;
            const currentSequences = state.entities[spaceId]?.sequences || [];
            const newSequences = Array.from(new Set(currentSequences).add(addedSequence));

            entitiesAdapter.updateOne(state, {
                id: spaceId,
                changes: {
                    sequences: newSequences,
                },
            });
        });
        builder.addCase(sendItemToStore.fulfilled, (state, action) => {
            const spaceId = action.meta.arg.spaceId;
            const addedSequence = action.payload.id;
            const currentSequences = state.entities[spaceId]?.sequences || [];
            const newSequences = Array.from(new Set(currentSequences).add(addedSequence));

            entitiesAdapter.updateOne(state, {
                id: spaceId,
                changes: {
                    sequences: newSequences,
                },
            });
        });
        builder.addCase(deleteSequence.fulfilled, (state, action) => {
            const spaceId = action.meta.arg.spaceId;
            const deletedSequenceId = action.meta.arg.sequenceId;
            const currentSequences = state.entities[spaceId]?.sequences || [];
            const deletedSequenceIndex = currentSequences.indexOf(deletedSequenceId);

            if (currentSequences.length === 0 || deletedSequenceIndex === -1) return;

            entitiesAdapter.updateOne(state, {
                id: spaceId,
                changes: {
                    sequences: currentSequences.splice(deletedSequenceIndex, 1),
                },
            });
        });
    },
});

export const useEntitiesBySpaceId = (spaceId: SpaceId) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchEntitiesBySpaceId({ spaceId }));
    }, []);

    const entities = useSelector(selectEntitiesBySpaceId(spaceId), fastDeepEqual);
    const status = useSelector(selectEntitiesStatus(spaceId), fastDeepEqual);

    return { entities, status };
};

export default entitiesSlice.reducer;
