import { Link } from "react-router-dom";
import "./Box.scss";

const Box: React.FC<BoxProps> = ({ children, title, titleChildren, className, linkTo, variant, outline, dataQA }) => {
    const classes = `box ${variant ? "box--" + variant : ""} ${outline ? "box--" + outline : ""} ${className ? className : ""} `;
    const Content = () => <>
        {
            (title || titleChildren) && (
                <div className="box__title">
                    {titleChildren && <div className="box__title-children">{titleChildren}</div>}
                    {title && <span>{title}</span>}
                </div>
            )
        }
        {children}
    </>;

    return (
        <>
            {
                linkTo
                    ? <Link className={`${classes} box--link`} to={linkTo} data-qa={dataQA}>
                        <Content />
                    </Link>
                    : <div className={classes} data-qa={dataQA}>
                        <Content />
                    </div>
            }
        </>
    );
};

export const BoxList: React.FC<BoxListProps> = ({ children, className, marginTop = 3, direction = "row", gap = 3 }) => {
    const classes = `${className ? className : ""} margin--t-${marginTop} box-list--${direction} flex--gap-${gap}`;

    return <div className={`box-list ${classes}`}>{children}</div>;
};

export default Box;
