import { useLocation } from "react-router-dom";
import { useState } from "react";
import MainNavSkeleton from "./MainNavSkeleton";
import MainNavLinkHref from "./MainNavLinkHref";
import MainNavLinkTo from "./MainNavLinkTo";
import MainNavLinkClick from "./MainNavLinkClick";
import { makeLinkOnClickAttributes } from "../../utils/gtmEvents";
import { MainNavLink, MainNavProps } from "./MainNav";
import "./MainNav.scss";
import { usePopover } from "../../store/onboarding/onboardingSlice";
import MainNavPopover from "./MainNavPopover";

const Nav: React.FC<MainNavProps> = ({ links }) => {
    const [showNav, setShowNav] = useState("");
    const toggleNav = () => (showNav === "" ? setShowNav("open") : setShowNav(""));
    const { pathname } = useLocation();
    const { popoverData, displayPopover } = usePopover("start");

    function renderSkeleton(link: MainNavLink, index: number) {
        if (link.status === "rejected") {
            return <div key={index}></div>;
        }

        if (link.status === "idle" || link.status === "pending") {
            return <MainNavSkeleton key={index} />;
        }

        return undefined;
    }

    // eslint-disable-next-line complexity
    function renderLink(link: MainNavLink, index: number) {
        // TODO: use provided space id instead of just hubs
        const destination = link.homepage ? "/space/hubs" : link.to || `/${link.label.toLowerCase()}`;
        const match = destination === pathname && pathname !== "";
        const onClickAttributes = makeLinkOnClickAttributes(link);

        let classes = match ? "nav__link is-active" : "nav__link";

        if (link.disabled) {
            classes += " disabled";
        }

        if (displayPopover && link.popoverId === popoverData?.id) {
            classes += " popover-animation";
        }

        if (link.href) {
            return (
                <MainNavLinkHref
                    key={index}
                    className={classes}
                    href={link.href}
                    {...onClickAttributes}
                    label={link.label}
                    icon={link.icon}
                    target={link.target}
                />
            );
        }

        if (link.to) {
            return (
                <MainNavLinkTo
                    key={index}
                    {...onClickAttributes}
                    className={classes}
                    to={destination}
                    label={link.label}
                    icon={link.icon} />
            );
        }

        if (onClickAttributes.onClick) {
            return (
                <MainNavLinkClick
                    key={index}
                    className={classes}
                    {...onClickAttributes}
                    label={link.label}
                    icon={link.icon}
                    disabled={link.disabled}
                />
            );
        }

        throw new Error("Missing one of the props: 'to' or 'href'");
    }

    return (
        <nav id="nav" className={`nav ${showNav}`}>
            <button className="nav__toggler" onClick={toggleNav}>
                <div className="nav__burger">
                    <span className="line"></span>
                    <span className="line"></span>
                    <span className="line"></span>
                </div>
                <div className="nav__text">Menu</div>
            </button>
            <div className="nav__links">
                {links.map((link, index) => (
                    renderSkeleton(link, index) ||
                    (link.popoverId === popoverData?.id ? (
                        <MainNavPopover key={index}>
                            {renderLink(link, index)}
                        </MainNavPopover>
                    ) : (
                        renderLink(link, index)
                    ))
                ))}
            </div>
        </nav>
    );
};

export default Nav;
