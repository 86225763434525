import Heading from "../../../components/Heading";

const MoreInfo: React.FC = () =>
    <>
        <hr />
        <Heading level="h3" text="Learn more:" size="subtitle-1" marginBottom={1} marginTop={3} />
        <p> How to {" "}
            <a href="https://docs.scramjet.org/platform/get-started" target="_blank">
                get started
            </a>
        </p>
        <p> How to {" "}
            <a href="https://docs.scramjet.org/platform/sequence-guide-js" target="_blank">
                write and run a Sequence
            </a>
        </p>
    </>;

export default MoreInfo;
