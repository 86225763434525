import { CheckCircleFill, ExclamationTriangleFill } from "react-bootstrap-icons";
import { ReactComponent as Connected } from "../../assets/images/connected.svg";
import { ReactComponent as Disconnected } from "../../assets/images/disconnected.svg";
import Tooltip from "../Tooltip";
import "./Status.scss";

const health = (status: boolean) => {
    return {
        text:  status ? "Healthy" : "Not healthy",
        desc: status ? "Healthy" : "Check logs for more info",
        icon: status ? <CheckCircleFill className="icon--healthy" size="2rem" /> : <ExclamationTriangleFill className="icon--unhealthy" size="2rem" />
    };
};
const connection = (status: boolean) => {
    return {
        text:  status ? "Connected" : "Disconnected",
        desc: status ? "Connected" : "Disconnected",
        icon: status ? <Connected /> : <Disconnected />
    };
};
const Status: React.FC<StatusProps> = ({ variant, status, tooltip = true, position = "above" }) => {
    let variantData;

    switch (variant) {
        case "health":
            variantData = health(status);
            break;
        case "connection":
            variantData = connection(status);
            break;
        default:
            variantData = health(status);
    }
    const { text, desc, icon } = variantData;

    return (
        <>
            {
                tooltip
                    ? <Tooltip text={desc} position={position}>{icon}</Tooltip>
                    : <p className="status">{icon}<span>&nbsp;{text}</span></p>
            }

        </>
    );
};


export default Status;
