import { EyeFill } from "react-bootstrap-icons";
import ButtonCircle from "../../../components/ButtonCircle";
import DateTime from "../../../components/DateTime";
import Table from "../../../components/Table";
import Tooltip from "../../../components/Tooltip";
import CopyText from "../../../components/CopyText";
import { useInstancesBySpaceId } from "../../../store/instances/instancesSlice";
import TableLoader from "../../SpacesPage/TableLoader";

const SequenceInstancesList: React.FC<SequenceInstancesListProps> = ({ sequence }) => {
    const spaceId = sequence.spaceId;
    const { instances, status } = useInstancesBySpaceId(spaceId);
    const instanceBySeqId = instances.filter(instance => instance.sequence.id === sequence.id);

    if (["idle", "pending"].includes(status)) {
        return <TableLoader />;
    }

    return (
        <div className="tab-menu__content">
            {!instanceBySeqId || instanceBySeqId.length === 0 ? (
                "No Instances of the Sequence"
            ) : (
                <Table rows={["Location", "Instance ID", "Created at", "Actions"]} variant="light">
                    {instanceBySeqId.map((instance) => {
                        const { id, hubId, started, created } = instance;

                        return (
                            <tr key={`${id}`}>
                                <td>{hubId}</td>
                                <td>{id}<CopyText text={id}/></td>
                                <td>
                                    <DateTime started={started} created={created} />
                                </td>
                                <td>
                                    <div className="flex">
                                        <Tooltip text="Details" position="above">
                                            <div>
                                                <ButtonCircle to={`/space/instances/${id}/basic-info`}>
                                                    <EyeFill className="icon--error" size="2rem" />
                                                </ButtonCircle>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </Table>
            )}
        </div>
    );
};

export default SequenceInstancesList;
