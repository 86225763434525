import { NavLink } from "react-router-dom";
import SkipNav from "../SkipNav";
import { useAuthentication } from "../../components/AuthenticationProvider";
import BootstrapIcon from "../../components/BootstrapIcon";
import Tooltip from "../../components/Tooltip";
import UserAvatar from "../../components/UserAvatar";
import AccessibleImg from "../AccessibleImg";
import "./TopBar.scss";
import { memo } from "react";
import ScramjetLogoWhite from "../../assets/images/logo-white.svg";
import ScramjetLogo from "../../assets/images/logo.svg";

const TopBar = () => {
    const { user } = useAuthentication();

    return (
        <header className="top-bar">
            <div className="top-bar__container">
                <div className="top-bar__left">
                    <NavLink
                        className={({ isActive }) => `top-bar__link ${isActive ? "is-active" : ""}`}
                        to="/"
                        aria-label="Home Page"
                    >
                        <AccessibleImg standardImg={ScramjetLogo} contrastImg={ScramjetLogoWhite} className="top-bar__logo" alt="Scramjet Logo" />
                    </NavLink>
                </div>
                <div className="top-bar__center">
                    <SkipNav />
                </div>
                <div className="top-bar__right">
                    {!user?.emailVerified && (
                        <Tooltip text="Email address not verified" position="below">
                            <div className="user-avatar__info">
                                <BootstrapIcon ico="error" size={20} />
                            </div>
                        </Tooltip>
                    )}
                    <UserAvatar />
                </div>
            </div>
        </header>
    );
};

export default memo(TopBar);
