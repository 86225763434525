import { Outlet, useLocation, useOutletContext, useParams } from "react-router-dom";
import Error404 from "../components/ErrorInfo/Error404";
import { useSpaceContext } from "../pages/SpacesPage";
import { useSequencesBySpaceId } from "../store/sequences/sequencesSlice";
import { useInstancesBySpaceId } from "../store/instances/instancesSlice";
import { useHubsBySpaceId } from "../store/hubs/hubsSlice";

type LoaderProps = {
    isLoading: boolean;
    valid: boolean;
    path?: string;
    context?: Record<string, any>;
    errorTitle?: string;
    errorDescription?: string;
};

const errorTitle = (name: string)=>`${name} not found`;
const errorDescription = (name: string)=>`Oops...seems like the ${name} you are looking for is not available. `;

export const Loader: React.FC<LoaderProps> = ({ isLoading, valid, path, context = {}, errorTitle, errorDescription })=>{
    const { pathname } = useLocation();
    const lastPathElement = pathname.split("/").pop();
    const hasSubRoute = Boolean(lastPathElement) && lastPathElement !== path;

    if (isLoading)
        return <></>; // TODO: Add a loader?
    if (!valid || !hasSubRoute)
        return <Error404 title={errorTitle} description={errorDescription}/>;
    return <Outlet context={context}/>;
};

export const HubLoader: React.FC = () => {
    const { hubId } = useParams();
    const { space } = useSpaceContext();
    const { hubs, status } = useHubsBySpaceId(space?.id);
    const hub = hubs.find(hub => hub.id === hubId);
    const isLoading = ["idle", "pending"].includes(status);
    const hubExist = !!hub;
    const context: HubContext = { hub } as HubContext;

    return <Loader isLoading={isLoading} valid={hubExist} path={hubId} context={{ space, ...context }} errorTitle={errorTitle("Hub")} errorDescription={errorDescription("hub")} />;
};
type HubContext = { hub: Hub;}

export function useHubContext() {
    return useOutletContext<HubContext>();
}

export const SequenceLoader: React.FC = () => {
    const { sequenceId } = useParams();
    const spaceContext = useSpaceContext();
    const { sequences, status } = useSequencesBySpaceId(spaceContext.space.id);
    const isLoading = ["idle", "pending"].includes(status);
    const sequence = sequences.find(sequence=>sequence.id === sequenceId);
    const sequenceExist = sequence !== undefined;
    const context: SequenceContext = { sequence } as SequenceContext;

    return <Loader isLoading={isLoading} valid={sequenceExist} path={sequenceId} context={{ ...spaceContext, ...context }} errorTitle={errorTitle("Sequence")} errorDescription={errorDescription("sequence")} />;
};
type SequenceContext = {sequence: Sequence, spaceId: SpaceId}

export function useSequenceContext() {
    return useOutletContext<SequenceContext>();
}

export const InstanceLoader: React.FC = () => {
    const { instanceId } = useParams();
    const spaceContext = useSpaceContext();
    const { instances, status } = useInstancesBySpaceId(spaceContext.space.id);
    const isLoading = ["idle", "pending"].includes(status);
    const instance = instances.find(instance=>instance.id === instanceId);
    const instanceExist = !!instance;
    const context: InstanceContext = { instance } as InstanceContext;

    return <Loader isLoading={isLoading} valid={instanceExist} path={instanceId} context={{ ...spaceContext, ...context }} errorTitle={errorTitle("Instance")} errorDescription={errorDescription("instance")} />;
};
type InstanceContext = {instance: Instance, spaceId: SpaceId}

export function useInstanceContext() {
    return useOutletContext<InstanceContext>();
}
