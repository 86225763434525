import { useLayoutEffect } from "react";

type UseScrollCondition = (
    check: (scroll: number) => boolean,
    callback: (condition: boolean) => void,
    initialCheck?: boolean
) => void;

const useScrollCondition: UseScrollCondition = (check, callback, initialCheck = false) => {
    let condition = false;

    const getScrollTop = () => window.document.documentElement.scrollTop || window.document.body.scrollTop;
    const handleScroll = () => {
        if (condition !== check(getScrollTop())) {
            condition = !condition;
            callback(condition);
        }
    };

    useLayoutEffect(() => {
        if (window) {
            window.addEventListener("scroll", handleScroll);
        }

        if (initialCheck) {
            condition = check(getScrollTop());
            callback(condition);
        }

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    });
};

export default useScrollCondition;
