import Select from "../../../components/Select";
import ErrorInfo from "../../../components/ErrorInfo";
import Box from "../../../components/Box";
import ContentLoader from "react-content-loader";
import { useHubsBySpaceId } from "../../../store/hubs/hubsSlice";
import { useEffect } from "react";

const SelectStartLocation: React.FC<SelectStartLocationProps> = ({ spaceId, currentValue, onChange }) => {
    const { status, hubs } = useHubsBySpaceId(spaceId);
    const options = hubs.map((hub) => hub.isConnectionActive
        ? { opsName: hub.id, disabled: false }
        : { opsName: hub.id, disabled: true });

    useEffect(() => {
        if (status !== "fulfilled"){
            return;
        }

        if (!currentValue && hubs?.length) {
            onChange(hubs[0].id);
        }
    }, [status]);

    function handleChangeValue(newValue: string) {
        if (currentValue !== newValue) {
            onChange(newValue);
        }
    }

    if (status !== "fulfilled")
        return <Box outline="shadow"><ContentLoader /></Box>;

    return hubs.length !== 0 ? <Select
        variant="big"
        marginTop={2}
        name="select-hub"
        label="Select where to start sequence"
        currentValue={currentValue}
        options={options}
        onChange={handleChangeValue} />
        : <ErrorInfo title="Couldn't find any hubs" description="Please contact the administrator"/>;
};

export default SelectStartLocation;

