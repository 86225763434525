import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import fastDeepEqual from "fast-deep-equal";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { AppDispatch, RootState, useAppDispatch } from "..";
import { getMiddlewateClient } from "../../components/MiddlewareClientProvider";
import StoreItemsMap from "./StoreItemsMap";
import { useSpaces } from "../spaces/spacesSlice";
import { Readable } from "stream";
const sliceName = "storeItems";
const StoreItemsAdapter = createEntityAdapter<StoreItem>();
const initialState: StoreItemState = StoreItemsAdapter.getInitialState({
    status: {},
});
const selectSelf = (state: RootState): StoreItemState => state[sliceName];

export const { selectAll: selectStoreItems, selectById: selectStoreItemById } =
    StoreItemsAdapter.getSelectors<RootState>(selectSelf);

export const selectStoreItemsStatus = (spaceId: SpaceId) => createSelector(selectSelf, state => state.status[spaceId]);

export const selectStoreItemsBySpaceId = (spaceId: SpaceId) =>
    createSelector(selectStoreItems, keys => keys.filter(key => key.spaceId === spaceId));

export const fetchSpaceStoreItems = createAsyncThunk<
    StoreItem[],
    { spaceId: SpaceId; refetch?: boolean },
    { state: RootState }
>(
    `${sliceName}/fetchBySpaceId`,
    async ({ spaceId }) => {
        const spaceClient = getMiddlewateClient().getManagerClient(spaceId);
        const storeItems = (await spaceClient.getStoreItems()).map(storeItem =>
            StoreItemsMap.toDTO(storeItem, spaceId)
        );

        return storeItems;
    },
    {
        // Cache between page loads and until refetch
        condition: ({ spaceId, refetch }, { getState }) => {
            const status = selectStoreItemsStatus(spaceId)(getState());

            return !!spaceId && (refetch || (status !== "fulfilled" && status !== "pending"));
        },
    }
);

export const sendItemToStore = createAsyncThunk<StoreItem, { spaceId: SpaceId; file: File }, { state: RootState }>(
    `${sliceName}/sendStoreItem`,
    async ({ spaceId, file }, { rejectWithValue }) => {
        try {
            const spaceClient = getMiddlewateClient().getManagerClient(spaceId);
            const sendResults = await spaceClient.putStoreItem(file as unknown as Readable);

            return StoreItemsMap.toDTO(sendResults, spaceId);
        } catch (err: unknown) {
            return rejectWithValue(err);
        }
    }
);

export const deleteStoreItem = createAsyncThunk<{}, { spaceId: SpaceId; sequenceId: SequenceId }, { state: RootState }>(
    `${sliceName}/deleteStoreItem`,
    async ({ spaceId, sequenceId }, { rejectWithValue }) => {
        try {
            const spaceClient = getMiddlewateClient().getManagerClient(spaceId);
            const deleteResults = await spaceClient.deleteStoreItem(sequenceId);

            return deleteResults;
        } catch (err: unknown) {
            return rejectWithValue(err);
        }
    }
);

export const storeItemsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchSpaceStoreItems.pending, (state, action) => {
                state.status[action.meta.arg.spaceId] = "pending";
            })
            .addCase(fetchSpaceStoreItems.fulfilled, (state, action) => {
                state.status[action.meta.arg.spaceId] = "fulfilled";
                StoreItemsAdapter.upsertMany(state, action.payload);
            })
            .addCase(fetchSpaceStoreItems.rejected, (state, action) => {
                state.status[action.meta.arg.spaceId] = "rejected";
            })
            .addCase(deleteStoreItem.fulfilled, (state, action) => {
                StoreItemsAdapter.removeOne(state, action.meta.arg.sequenceId);
            })
            .addCase(deleteStoreItem.rejected, (state, action) => {
                StoreItemsAdapter.updateOne(state, {
                    id: action.meta.arg.sequenceId,
                    changes: {
                        isStarting: false,
                    },
                });
            })
            .addCase(sendItemToStore.fulfilled, (state, action) => {
                StoreItemsAdapter.addOne(state, action.payload);
            })
            .addCase(sendItemToStore.rejected, (state, action) => {
                state.status[action.meta.arg.spaceId] = "rejected";
            });
    },
});

export const useStoreItemsBySpaceId = (spaceId: SpaceId) => {
    const { spaces } = useSpaces();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchSpaceStoreItems({ spaceId }));
    }, [spaces]);

    const status = useSelector(selectStoreItemsStatus(spaceId), fastDeepEqual);
    const storeItems = useSelector(selectStoreItemsBySpaceId(spaceId), fastDeepEqual);

    return { status, storeItems };
};

export default storeItemsSlice.reducer;
