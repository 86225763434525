import { useTabsContext } from "@reach/tabs";
import { PropsWithChildren, memo } from "react";

const TabLazy: React.FC<PropsWithChildren<TabLazyProps>> = memo(({ index, children }) => {
    const { selectedIndex } = useTabsContext();
    const isVisible = selectedIndex === index;

    return <>{isVisible && children}</>;
});

export default TabLazy;
