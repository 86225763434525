import "./BasicInfo.scss";
import { Server } from "react-bootstrap-icons";
import JSONEditor from "../../../components/JSONHighlighter";
import Tag from "../../../components/Tags";
import { useSequencesByGroupId } from "../../../store/sequences/sequencesSlice";
import CopyText from "../../../components/CopyText";

const BasicInfo: React.FC<{ sequence:Sequence }> = ({ sequence }) => {
    const { id, config } = sequence;
    const sequencesByGroupId = useSequencesByGroupId(id);
    const engines = Object.entries(config.engines).map(value => value.join(": "))[0];
    const sequencesLocation = sequencesByGroupId.map(({ location }) => location).join(", ");
    const content = {
        ID: id,
        Location: sequencesLocation,
        Name: sequence.name ? sequence.name : config.name,
        Version: config.version ? config.version : "",
        Description: config.description ? config.description : "",
        Engines: engines ? engines : "",
        Author: config.author ? config.author : "",
    };
    const item = (key: string, value: string) => {
        return (
            value.length > 0 && (
                <div className="margin--t-1" key={key}>
                    <h2 className="subtitle-2">{key}</h2>
                    <p className="">{value}</p>
                </div>
            )
        );
    };

    return (
        <div className="flex tab-menu__context basic-info">
            <div className="tab-menu__content">
                {Object.entries(content).map(([key, value]) => (
                    key === "ID" ? (
                        <div key={key} className="basic-info__id-container">
                            {item(key, value)}
                            <CopyText text={value} className="basic-info__copy-btn"/>
                        </div>
                    ) : (
                        item(key, value)
                    )
                ))}
                <div className="margin--t-1">
                    <h2 className="subtitle-2 margin--t-1">No. of Instances</h2>
                    <p className="basic-info__inst">
                        <Server width="2rem" height="2rem" />
                        <span>({sequence.instances.length})</span>
                    </p>
                </div>
                {config.keywords && (
                    <div className="margin--t-1">
                        <h2 className="subtitle-2 margin--t-1">Tags</h2>
                        <Tag margin="margin--t-1" tags={config.keywords} />
                    </div>
                )}
            </div>
            <div className="tab-menu__content">
                <JSONEditor data={config} readonly={true} labelText="Package JSON Config"/>
            </div>
        </div>
    );
};

export default BasicInfo;
