
import { PropsWithChildren } from "react";
import BootstrapIcon from "../../BootstrapIcon";
import BasicModal from "../BasicModal";
import Heading from "../../Heading";

const StatusModal: React.FC<PropsWithChildren<StatusModalProps>> = ({
    title,
    children,
    variant = "info",
    alignContent = "center",
    image,
    overlay,
    buttons,
    onClose,
    hiddenX = false,
    open = false,
}) => {
    return (
        <BasicModal
            variant={variant}
            onClose={onClose}
            open={open}
            alignContent={alignContent}
            hiddenX= {hiddenX}
        >
            {image && typeof image === "string"
                ? <div className="modal__icon">
                    <BootstrapIcon ico={image} size={60} />
                </div>
                : <div className="modal__icon">
                    {image}
                </div>
            }
            <Heading level="h3" size="subtitle-1" id="dialog-title" text={title} className="modal__header no-margin"/>
            {overlay && <p className="modal__content overlay">{overlay}</p>}
            <div className="modal__content body-1">{children}</div>
            {buttons && <div className="modal__buttons">{buttons}</div>}
        </BasicModal>
    );
};

export default StatusModal;
