import { User } from "@auth0/auth0-react";

function formatName(user: User = {}): string | undefined {
    return (
        [user?.given_name, user?.middle_name, user?.family_name].join(" ").trim() ||
        user?.preferred_username ||
        user?.nickname ||
        user?.name
    );
}

export default class UserMap {
    static toDTO(user: User, userId: string | undefined): AuthenticationUser {
        return {
            id: userId,
            username: formatName(user),
            email: user?.email,
            emailVerified: user?.email_verified,
            picture: user?.picture,
        };
    }
}
