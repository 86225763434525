import { getMiddlewateClient } from "../MiddlewareClientProvider";

export const loadInstanceLogStream = async (spaceId: SpaceId, hubId: HubId, instanceId: InstanceId) => {
    const managerClient = getMiddlewateClient().getManagerClient(spaceId);
    const hostClient = managerClient.getHostClient(hubId);
    const instanceClient = hostClient.getInstanceClient(instanceId);
    const logStreamCurrent = (await instanceClient.getStream("log")) as unknown as ReadableStream;

    return logStreamCurrent;
};
