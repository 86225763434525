import { forwardRef } from "react";
import "./CircleLoader.scss";

type CircleLoaderProps = {
    numberVisible?: boolean;
    color?: "primary" | "secondary" | "success" | "error" | "warning";
    progress?: number;
};

const CircleLoader: React.FC<CircleLoaderProps> = forwardRef<HTMLDivElement, CircleLoaderProps>(
    ({ numberVisible, color = "primary", progress }, ref) => {
        const progressClass = progress ? `circle--${Math.round(progress)}` : "circle--animated";

        return (
            <div className={`circle-loader circle-loader--color-${color}`} ref={ref}>
                <div className="inner"></div>
                {numberVisible && <div className="number">0</div>}
                <div className={`circle ${progressClass}`}>
                    <div className="bar left">
                        <div className="progress"></div>
                    </div>
                    <div className="bar right">
                        <div className="progress"></div>
                    </div>
                </div>
            </div>
        );
    }
);

export default CircleLoader;
