import { createAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "..";
import { middlewareUrl } from "../../components/MiddlewareClientProvider";
import fastDeepEqual from "fast-deep-equal";
import { ClientUtils } from "@scramjet/client-utils";
import { getDefaultPrice } from "../../hooks/useProducts";

const RETRIES_TIMEOUT = 3000;
const RETRIES_MAX = 30;
const sliceName = "subscriptions";
const subscriptionsAdapter = createEntityAdapter<SubscriptionInfo>();
const initialState: SubscriptionsState = subscriptionsAdapter.getInitialState({
    status: "idle",
    paymentMethodInfo: null,
    paymentsStatus: "loading",
    selfHostedLimit: null,
    retries: 0,
});
const selectSelf = (state: RootState): SubscriptionsState => state[sliceName];

export const { selectAll: selectSubscriptions, selectById: selectSubscriptionById } =
    subscriptionsAdapter.getSelectors<RootState>(selectSelf);
export const selectStatus = createSelector(selectSelf, state => state.status);
export const selectRetries = createSelector(selectSelf, state => state.retries);
export const selectPaymentsStatus = createSelector(selectSelf, state => state.paymentsStatus);
export const selectPaymentMethods = createSelector(selectSelf, state => state.paymentMethodInfo);
export const selectSelfHostedLimit = createSelector(selectSelf, state => state.selfHostedLimit);
export const selectLatestSubscription = createSelector(selectSubscriptions, subscriptions =>
    [...subscriptions].sort((a, b) => a.created - b.created).pop()
);

export const retryIncrease = createAction(`${sliceName}/retryIncrease`);
export const fetchAllSubscriptions = createAsyncThunk<
    SubscriptionInfoResponse,
    { reload?: boolean },
    { state: RootState }
>(
    `${sliceName}/fetchAll`,
    async (_, { dispatch, getState }) => {
        const clientUtils = new ClientUtils(middlewareUrl);
        const result = await clientUtils.get<SubscriptionInfoResponse>("/api/v1/subscription-info");

        if (result.paymentsStatus !== "inprogress") return result;

        const retries = selectRetries(getState());

        if (retries >= RETRIES_MAX) return result;

        setTimeout(() => {
            dispatch(retryIncrease());
            dispatch(fetchAllSubscriptions({ reload: true }));
        }, RETRIES_TIMEOUT);

        return {
            ...result,
            paymentsStatus: "inprogress-retry",
        };
    },
    {
        // Cache between page loads
        condition: ({ reload = false }, { getState }) => {
            const loading = selectStatus(getState() as RootState);

            return reload || (loading !== "fulfilled" && loading !== "pending");
        },
    }
);
export const updateProductQuantity = createAsyncThunk<
    SubscriptionInfo,
    { subscriptionId: SubscriptionId; product: Product; quantity: number },
    { state: RootState }
>(`${sliceName}/updateProductQuantity`, async ({ subscriptionId, product, quantity }, { dispatch, getState }) => {
    const clientUtils = new ClientUtils(middlewareUrl);
    const price = getDefaultPrice(product);
    const priceId = price?.id;
    const result = await clientUtils.post<SubscriptionInfo>(
        "/api/v1/subscription-item",
        {
            quantity,
            subscriptionId,
            priceId,
        },
        {},
        { json: true, parse: "json" }
    );

    return result;
});

export const subscriptionsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(retryIncrease, state => {
            state.retries += 1;
        });
        builder
            .addCase(fetchAllSubscriptions.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(fetchAllSubscriptions.fulfilled, (state, action) => {
                state.status = "fulfilled";
                state.paymentMethodInfo = action.payload.paymentMethodInfo;
                state.paymentsStatus = action.payload.paymentsStatus;
                state.selfHostedLimit = action.payload.selfHostedLimit;
                subscriptionsAdapter.upsertMany(state, action.payload.subscriptionInfo);
            })
            .addCase(fetchAllSubscriptions.rejected, (state, action) => {
                state.status = "rejected";
            });
        builder.addCase(updateProductQuantity.fulfilled, (state, action) => {
            state.selfHostedLimit = action.meta.arg.quantity;
        });
    },
});

export const useSubscriptions = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAllSubscriptions({}));
    }, []);

    const status = useSelector(selectStatus, fastDeepEqual);
    const subscriptions = useSelector(selectSubscriptions, fastDeepEqual);
    const paymentsStatus = useSelector(selectPaymentsStatus, fastDeepEqual);
    const paymentMethods = useSelector(selectPaymentMethods, fastDeepEqual);
    const selfHostedLimit = useSelector(selectSelfHostedLimit, fastDeepEqual);
    const latestSubscription = useSelector(selectLatestSubscription, fastDeepEqual);

    return {
        status,
        subscriptions,
        latestSubscription,
        paymentsStatus,
        paymentMethods,
        selfHostedLimit,
    };
};

export default subscriptionsSlice.reducer;
