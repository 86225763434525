import { conversions, configs, GTMEventNameType } from "../data/GtmConversions";
import { MainNavLink } from "../layout/MainNav/MainNav";
import { globalStateStore } from "./globalStateStore";

type primitive = number | string | boolean | undefined;
type GTMMethodConfig = ((tagId: string, values?: Partial<{
    "send_page_view": boolean,
    "user_id": string,
    [key: string]: primitive
}>) => void);
type GTMMethodGet = ((tagId: string, cb: (value: any) => void) => void);
type GTMMethodSet = ((value: { [key: string]: primitive }) => void);
type GTMMethodEvent = ((eventName: string, values?: Record<string, primitive>) => void);
type GTMMethodConsent = ((arg: "update" | "default", value: Partial<{
    "ad_storage": "granted" | "denied"
    "analytics_storage": "granted" | "denied"
    "wait_for_update": number
}>) => void)
    ;

type GTMFuncs = "config" | "get" | "set" | "event" | "consent";
type GTMMethod<Type extends GTMFuncs> =
    Type extends "config" ? GTMMethodConfig :
    Type extends "get" ? GTMMethodGet :
    Type extends "set" ? GTMMethodSet :
    Type extends "event" ? GTMMethodEvent :
    Type extends "consent" ? GTMMethodConsent :
    never;

function hasGtag<T extends Window>(arg: T): arg is T & { gtag: (...args: any[]) => void } {
    // @ts-nocheck
    return typeof window !== "undefined" && "gtag" in window && typeof (window as unknown as { gtag: any }).gtag === "function";
}

function getGtag<T extends GTMFuncs>(type: T): GTMMethod<T> {
    if (hasGtag(window)) {
        const gtag = window.gtag;

        return ((...args: any[]) => gtag(type, ...args)) as GTMMethod<T>;
    }
    return ((...args: any[]) => console.info("GTM", type, ...args)) as unknown as GTMMethod<T>;

}

export const updateUserId = async (userId: string) => getGtag("config")(configs.GA4, { send_page_view: false, user_id: userId });

export const sendEvent = async (eventName: GTMEventNameType, eventData?: Record<string, primitive>) => {
    const conversion = eventName in conversions ? conversions[eventName as keyof typeof conversions] : [];

    getGtag("event")(eventName, eventData);

    if (conversion)
        for (const conv of conversion) {
            getGtag("event")("conversion", conv);
        }
};

export function makeLinkOnClickAttributes(link: MainNavLink) {
    const clickActions: (() => Promise<void> | void)[] = [];

    if (link.onClick)
        clickActions.push(link.onClick);
    if (link.gtmEvent) {
        const event = link.gtmEvent;

        clickActions.push(() => sendEvent(event));
    }

    // performs all the actions
    return clickActions.length
        ? {
            onClick: () => {
                clickActions.reduce(
                    (prm, next) => prm.then(next),
                    Promise.resolve()
                );
                return 1;
            }
        }
        : {};
}

export async function sendAuthenticationEvent(stage: GTMEventNameType, params?: { screen: string; }) {
    if (globalStateStore.set("last_user_auth_state", stage)) {
        return await sendEvent(stage, params);
    }
    return Promise.resolve();
}

export async function sendGenericErrorEvent(place: string, error: any) {
    const message = error instanceof Error ? error.message : "not-set";

    return sendEvent("console_error", { on: "unknown", place, message });
}

export async function sendErrorEvent(stage: GTMEventNameType, error: any) {
    const message = error instanceof Error ? error.message : "not-set";

    return sendEvent("console_error", { on: stage, message });
}

