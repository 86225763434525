import { PropsWithChildren } from "react";
import BootstrapIcon from "../../BootstrapIcon";
import BasicModal from "../BasicModal";
import Heading from "../../Heading";
import "./DialogModal.scss";

const DialogModal: React.FC<PropsWithChildren<DialogModalProps>> = ({
    title,
    children,
    alignContent,
    image,
    overlay,
    buttons,
    onClose,
    hiddenX = false,
    open = false,
}) => {
    return (
        <BasicModal
            variant="dialog"
            onClose={onClose}
            open={open}
            alignContent={alignContent}
            hiddenX={hiddenX}
        >
            {image && typeof image === "string"
                ? <div className="modal__icon">
                    <BootstrapIcon ico={image} size={60} />
                </div>
                : <div className="modal__icon">
                    {image}
                </div>
            }
            <Heading text={title} marginBottom={1} level="h3" id="dialog-modal-title" size="subtitle-1" className="modal__header"/>
            {overlay && <p className="modal__content overlay">{overlay}</p>}
            <div className="modal__content body-1">{children}</div>
            {buttons && <div className="modal__buttons">{buttons}</div>}
        </BasicModal>

    );
};

export default DialogModal;
