import A11Panel from "./A11Panel";
import Footer from "./Footer";
import TopBar from "./TopBar";

import footerLinks from "../data/FooterLinks.json";

import { PropsWithChildren, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import PlatformMainNav from "./PlatformMainNav";

import "../scss/main.scss";
import { Outlet } from "react-router-dom";

let themeClass: string;

const Base: React.FC<PropsWithChildren> = () => {
    const themeMode = useSelector((state: RootState) => state.common.mode);

    useLayoutEffect(() => {
        document.documentElement.classList.remove(themeClass);
        document.documentElement.classList.add(themeMode);
        themeClass = themeMode;
    }, [themeMode]);

    return (
        <div className="layout">
            <TopBar />
            <A11Panel />
            <PlatformMainNav />
            <main id="main-content" className="main-content">
                <Outlet />
                <Footer links={footerLinks} />
            </main>
        </div>
    );
};

export default Base;
