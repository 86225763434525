import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState, useAppDispatch } from "..";
import { ClientUtils } from "@scramjet/client-utils";
import { getToken, middlewareUrl } from "../../components/MiddlewareClientProvider";
import { globalStateStore } from "../../utils/globalStateStore";
import { useAuthentication } from "../../components/AuthenticationProvider";
import { useSelector } from "react-redux";
import PopoverData from "../../data/popovers/Popovers.json";
import Popover from "../../components/Popover";

const sliceName = "onboardingAddSequence";
const OnboardingAdapter = createEntityAdapter<Popover>({ selectId: popover => popover.id });
const initialState: OnboardingAddSequenceState = OnboardingAdapter.getInitialState({
    currentPopover: "ended", // lets assume user has seen onboarding so no popup is visible for a fraction of a secon
    status: "idle",
    visible: true,
    popovers: [],
});
const selectSelf = (state: RootState): OnboardingAddSequenceState => state[sliceName];

export const selectOnboardingAddSequenceStatus = () =>
    createSelector(selectSelf, state => {
        return state.status;
    });

export const selectCurrentPopover = () =>
    createSelector(selectSelf, state => {
        return state.currentPopover;
    });

export const selectDisplayPopover = (popoverId: string) =>
    createSelector(selectSelf, state => {
        return state.currentPopover === popoverId && state.visible;
    });

export const { selectAll: selectOnboardingAddSequence, selectById: selectOnboardingAddSequenceByKey } =
    OnboardingAdapter.getSelectors<RootState>(selectSelf);

export const fetchOnboardingAddSequencePopovers = createAsyncThunk<
    { currentPopover: string | null; popovers: Popover[] },
    { userId: string; refetch?: boolean },
    { state: RootState }
>(
    `${sliceName}/fetchOnboardingAddSequence`,
    async ({ userId }) => {
        const clientUtils = new ClientUtils(middlewareUrl);
        const response = await clientUtils.get<User>(`/api/v1/user/${userId}`);
        const onboarding = response.user_metadata.onboarding;

        let currentPopover;

        if (!onboarding) {
            response.user_metadata.onboarding = { addSequence: { ended: false } };
        }

        if (response.user_metadata?.onboarding?.addSequence?.ended === true) {
            currentPopover = "ended";
        } else if (localStorage.getItem("SCPGLOBALSTORE::onboarding")) {
            currentPopover = (globalStateStore.getJSON("onboarding") as unknown as OnboardingState).addSequence;
        } else {
            currentPopover = PopoverData?.addSequence[0].id || "ended";
        }

        globalStateStore.setJSON("onboarding", { addSequence: currentPopover });

        return { currentPopover, popovers: [...(PopoverData.addSequence as unknown as Popover[])] };
    },
    {
        // Cache between page loads and until refetch
        condition: ({ userId, refetch }, { getState }) => {
            const status = selectOnboardingAddSequenceStatus()(getState() as RootState);

            return !!userId && (refetch || (status !== "fulfilled" && status !== "pending"));
        },
    }
);

export const endOnboardingAddSequence = createAsyncThunk<
    { onboardingState: OnboardingMetadata | null },
    { userId: string; refetch?: boolean },
    { state: RootState }
>(`${sliceName}/endOnboardingAddSequence`, async ({ userId }, { rejectWithValue }) => {
    try {
        await fetch(`${middlewareUrl}/api/v1/user/${userId}`, {
            method: "PATCH",
            body: JSON.stringify({ onboarding: { addSequence: { ended: true } } }),
            headers: {
                Authorization: `Bearer ${getToken()}`,
                "Content-type": "application/json",
            },
        });
        const onboardingState = { onboarding: { addSequence: { ended: true } } };

        return { onboardingState };
    } catch (e) {
        console.error(e);
        return rejectWithValue(e);
    }
});

export const onboardingSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        next: (state, action: PayloadAction<string>) => {
            let popoverID = "";

            for (let i = 0; i < state.popovers.length; i++) {
                if (state.popovers[i].id === action.payload) {
                    popoverID = state.popovers[i + 1].id;
                }
            }

            globalStateStore.setJSON("onboarding", { addSequence: popoverID });
            state.currentPopover = popoverID;
        },
        setVisible: (state, action: PayloadAction<boolean>) => {
            state.visible = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchOnboardingAddSequencePopovers.pending, state => {
                state.status = "pending";
            })
            .addCase(fetchOnboardingAddSequencePopovers.fulfilled, (state, action) => {
                state.status = "fulfilled";
                OnboardingAdapter.upsertMany(state, action.payload.popovers);
                state.currentPopover = action.payload.currentPopover;
                state.popovers = action.payload.popovers;
            })
            .addCase(fetchOnboardingAddSequencePopovers.rejected, state => {
                state.status = "rejected";
            })
            .addCase(endOnboardingAddSequence.pending, state => {
                state.status = "pending";
            })
            .addCase(endOnboardingAddSequence.fulfilled, state => {
                state.status = "fulfilled";
                globalStateStore.setJSON("onboarding", { addSequence: "ended" });
                state.currentPopover = "ended";
            })
            .addCase(endOnboardingAddSequence.rejected, state => {
                state.status = "rejected";
            });
    },
});

function dispatch(arg0: { payload: string; type: string }) {
    throw new Error("Function not implemented.");
}

export function useOnboarding() {
    const dispatch = useAppDispatch();
    const { user } = useAuthentication();
    const currentPopover = useSelector(selectCurrentPopover());

    function initiateOnboarding() {
        if (user) {
            dispatch(fetchOnboardingAddSequencePopovers({ userId: user?.id as string }));
        } else {
            Promise.resolve(null);
        }
    }

    function endOnboarding() {
        dispatch(endOnboardingAddSequence({ userId: user?.id as string }));
    }

    function setPopoversVisible(visible: boolean) {
        dispatch(onboardingSlice.actions.setVisible(visible));
    }

    return { initiateOnboarding, endOnboarding, setPopoversVisible, currentPopover };
}

export const usePopover = (popoverId: string) => {
    const { initiateOnboarding } = useOnboarding();
    const dispatch = useAppDispatch();
    const popoverData = useSelector<RootState, Popover | undefined>(state =>
        selectOnboardingAddSequenceByKey(state, popoverId)
    );
    const displayPopover = useSelector<RootState, boolean | undefined>(state =>
        selectDisplayPopover(popoverId)(state)
    ) as boolean;
    const currentPopover = useSelector(selectCurrentPopover());

    initiateOnboarding();

    function nextPopover() {
        dispatch(onboardingSlice.actions.next(popoverId));
    }

    return { popoverData, displayPopover, nextPopover, Popover };
};

export default onboardingSlice.reducer;
