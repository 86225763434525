import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";

import { RootState, useAppDispatch } from "..";

const sliceName = "tokens";
const tokensAdapter = createEntityAdapter<AccessToken>({ selectId: model => model });
const initialState: TokensState = tokensAdapter.getInitialState();
const selectSelf = (state: RootState): TokensState => state[sliceName];

export const { selectAll: selectTokens, selectById: selectTokensByKey } =
    tokensAdapter.getSelectors<RootState>(selectSelf);

export const tokensSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addToken: (state, action: PayloadAction<AccessToken>) => {
            tokensAdapter.addOne(state, action.payload);
        },
        removeToken: (state, action: PayloadAction<AccessToken>) => {
            tokensAdapter.removeOne(state, action.payload);
        },
    },
});

export const useTokens = () => {
    const tokens = useSelector(selectTokens);
    const dispatch = useAppDispatch();

    return {
        tokens,
        addToken: (token: string) => dispatch(tokensSlice.actions.addToken(token)),
        removeToken: (token: string) => dispatch(tokensSlice.actions.removeToken(token)),
    };
};

export default tokensSlice.reducer;
