import { ButtonGroup, ClickButton } from "../../components/Buttons";
import Container from "../../components/Container";
import List from "../../components/List";
import Box from "../../components/Box";
import TopSection from "../../layout/TopSection";
import { Check } from "react-bootstrap-icons";
import "./ProductTrial.scss";
import Video from "../../components/Video";
import AccountSetupProgress from "../../components/Progress";

const ProductTrial: React.FC<ProductTrialProps> = ({ callback }) => {
    return (
        <Container className="product-trial">
            <Box outline="shadow" className="product-trial__box">
                <TopSection
                    marginBottom={2}
                    className="product-trial__heading"
                    align="center"
                    title="Welcome to Scramjet Cloud Platform!"
                    variant="title"
                />
                <AccountSetupProgress currentStepId={3} />
                <p className="body-1 product-trial__subtitle">
                    We can't wait to see what we can build together. Before we take off on your free trial journey, take
                    52 seconds to hear from our CEO and have a look at what's included in your free trial.
                    3, 2, 1... Lift off!
                </p>
                <div className="product-trial__content">
                    <Video link="https://www.youtube.com/embed/9ZuxkTAWWFI" aria-label="A video featuring Scramjet's CEO welcoming a new user." className="product-trial__video"/>
                    <Box className="product-trial__container">
                        <p className="subtitle-1 product-trial__text">What do you get during 30 days of Trial period? </p>
                        <div className="product-trial__content-left">
                            <div className="product-trial__container-top">
                                <div>
                                    <p className="subtitle-2">Scramjet Cloud</p>
                                    <p className="subtitle-2 product-trial__label"><em className="product-trial__crossed">$25</em> $0</p>
                                </div>
                                <div>
                                    <p className="subtitle-2">Self Hosted hub</p>
                                    <p className="subtitle-2 product-trial__label"><em className="product-trial__crossed">$200</em> $0</p>
                                </div>
                            </div>
                            <List
                                textList={[
                                    "Create real-time event automations",
                                    "Process continuous data sequentially",
                                    "Integrate existing systems behind firewalls and VPNs",
                                    "Single point of orchestration for all your data pipelines"
                                ]}
                                icon={<Check className="list__icon" color="var(--radio-active-default)"/>}
                            />
                        </div>
                        <p className="body-2">See detailed information in our <a href="https://scramjet.org/pricing/" target="_blank">Pricing</a>.</p>
                    </Box>
                </div>
                <ButtonGroup align="center" className="margin--t-3">
                    <ClickButton onClick={callback} text="Start trial" />
                </ButtonGroup>
            </Box>
        </Container>
    );
};

export default ProductTrial;
