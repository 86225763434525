import { configureStore } from "@reduxjs/toolkit";
import commonSlice from "./common/commonSlice";
import spacesSlice from "./spaces/spacesSlice";
import entitiesSlice from "./entities/entitiesSlice";
import hubsSlice from "./hubs/hubsSlice";
import instancesSlice from "./instances/instancesSlice";
import sequencesSlice from "./sequences/sequencesSlice";
import invoicesSlice from "./invoices/invoicesSlice";
import { useState } from "react";
import toastsSlice from "./toasts/toastsSlice";
import tokensSlice from "./tokens/tokensSlice";
import topicsSlice from "./topics/topicsSlice";
import accessKeySlice from "./accessKeys/accessKeySlice";
import accessKeysDataSlice from "./accessKeys/accessKeysDataSlice";
import subscriptionsSlice from "./subscriptinos/subscriptionsSlice";
import storeItemsSlice from "./storeItems/storeItemsSlice";
import onboardingAddSequenceSlice from "./onboarding/onboardingSlice";
import promoCodesSlice from "./promoCodes/promoCodesSlice";

const store = configureStore({
    reducer: {
        common: commonSlice,
        spaces: spacesSlice,
        entities: entitiesSlice,
        hubs: hubsSlice,
        instances: instancesSlice,
        sequences: sequencesSlice,
        invoices: invoicesSlice,
        toasts: toastsSlice,
        tokens: tokensSlice,
        topics: topicsSlice,
        accessKey: accessKeySlice,
        accessKeysData: accessKeysDataSlice,
        subscriptions: subscriptionsSlice,
        storeItems: storeItemsSlice,
        onboardingAddSequence: onboardingAddSequenceSlice,
        promoCodes: promoCodesSlice
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: false,
    })
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => store.dispatch; // Export a hook that can be reused to resolve types
export const useAppState = () => useState<RootState>(); // Export a hook that can be reused to resolve types

export default store;
