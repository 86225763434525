import "./Video.scss";

const Video: React.FC<VideoProps> = ({
    link,
    width = "560",
    height = "315",
    className
}) => {
    return (
        <div className="video-container">
            <iframe
                width={width}
                height={height}
                src={link + "?autoplay=1&controls=1&showinfo=0&autohide=1&disablekb=1&iv_load_policy=3&modestbranding=1"}
                title="Youtube Player"
                allow="autoplay"
                className={`video + ${className}`}
                allowFullScreen
            />
        </div>
    );
};

export default Video;
