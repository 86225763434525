import { useNavigate } from "react-router-dom";
import { ButtonGroup, ExternalLinkButton, ClickButton } from "../Buttons";
import CircleLoader from "../CircleLoader";
import { useCheckoutSessions } from "../../hooks/useCheckoutSessions";
import { useExpireCheckoutSession } from "../../hooks/useExpireCheckoutSession";

import "./PaymentsLoaderContent.scss";

const NoSessionInfo = () => {
    return (
        <div className="payment-loader-content">
            <div className="payment-loader-content__title">Checking payments status</div>
            <div className="payment-loader-content__subtitle">Please wait</div>
        </div>
    );
};
const SessionInfo: React.FC<{paymentSession: PaymentSession}> = ({ paymentSession }) => {
    const { expire, status } = useExpireCheckoutSession();
    const navigate = useNavigate();

    if (status === "success") {
        navigate("/subscription");
        return <CircleLoader></CircleLoader>;
    }

    return (
        <div className="payment-loader-content">
            <div className="payment-loader-content__title">The checkout session is in progress...</div>

            <div className="payment-loader-content__subtitle">Continue by going back or cancel the session.</div>
            <ButtonGroup align="center">
                <ExternalLinkButton text="Go back" href={paymentSession.url} />
                <ClickButton text="Cancel" variant="secondary" onClick={() => expire(paymentSession.id)} disabled={status === "loading"} />
            </ButtonGroup>
        </div>
    );
};

export const PaymentsLoaderContent = () => {
    const { checkoutSessions, status } = useCheckoutSessions();
    const firstSession = checkoutSessions && checkoutSessions[0];

    switch (status) {
        case "success":
            if (!firstSession) {
                return <NoSessionInfo />;
            }

            return <SessionInfo paymentSession={firstSession} />;
        case "error":
            return (
                <div className="payment-loader-content">
                    <div className="payment-loader-content__title">Error while retrieving checkout session information</div>
                    <div className="payment-loader-content__subtitle">Your checkout session is in progress but we could not retrive information about it.</div>
                </div>
            );
        case "idle":
        case "loading":
        default:
            return <CircleLoader></CircleLoader>;
    }
};
