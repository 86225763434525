import { Outlet, useOutlet } from "react-router-dom";
import { useSpaceContext } from ".";
import { TabMenuWithRouter } from "../../components/TabMenu";
import HubsTab from "../HubPage/HubsTab";
import InstancesTab from "./InstancesTab";
import SequencesTab from "./SequencesTab";
import TopicsTab from "./TopicsTab";
import { ShowLogsAddSequenceButtons } from "./components/SpaceButtons";
import SpaceButtonsPortal from "./components/SpaceButtonsPortal";
import TabLazy from "../../components/TabMenu/TabLazy";
import Indicator from "./components/Indicator";

const SpaceTabs: React.FC = () => {
    const { space } = useSpaceContext();
    const spaceId = space.id;
    const outlet = useOutlet();

    if (outlet) {
        return <Outlet context={{ space }} />;
    }

    return (
        <>
            <SpaceButtonsPortal>
                <ShowLogsAddSequenceButtons />
            </SpaceButtonsPortal>
            <TabMenuWithRouter
                orientation="horizontal"
                variant="button"
                tabList={["Hubs", "Sequences", "Instances", "Topics"]}
                indicators={[
                    <Indicator spaceId={spaceId} entityName="hubs" />,
                    <Indicator spaceId={spaceId} entityName="sequences" />,
                    <Indicator spaceId={spaceId} entityName="instances" />,
                    <Indicator spaceId={spaceId} entityName="topics" />
                ]}
                tabPanels={[
                    <TabLazy index={0}><HubsTab spaceId={spaceId} /></TabLazy>,
                    <TabLazy index={1}><SequencesTab spaceId={spaceId} /></TabLazy>,
                    <TabLazy index={2}><InstancesTab spaceId={spaceId} /></TabLazy>,
                    <TabLazy index={3}><TopicsTab spaceId={spaceId} /></TabLazy>,
                ]}
                flex
            />
        </>
    );
};

export default SpaceTabs;

