import "@reach/tooltip/styles.css";
import "./Tooltip.scss";
import React, { PropsWithChildren, ReactElement } from "react";
import { TooltipPopup, useTooltip } from "@reach/tooltip";
import * as positions from "./positions";
import { Portal } from "@reach/portal";

const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
    children,
    title,
    text = <>text</>,
    color = "info",
    position = "below",
    "aria-label": ariaLabel,
}) => {
    const [trigger, tooltip] = useTooltip();
    const { isVisible, triggerRect } = tooltip;
    const prect = { width: 14, height: 10, bottom: 0, left: 0, right: 0, top: 0 };
    const rotation = { above: 180, right: 270, below: 0, left: 90 };

    return (
        <>
            {React.cloneElement(children as ReactElement, trigger)}

            {isVisible && (
                <Portal>
                    <div
                        className={`tooltip-triangle tooltip--${color}`}
                        style={{
                            position: "absolute",
                            ...positions[position](0, 0)(triggerRect, prect),
                            marginLeft: position === "left" ? 0 : "-4px",
                            width: 0,
                            height: 0,
                            borderStyle: "solid",
                            borderLeftWidth: "10px",
                            borderLeftColor: "transparent",
                            borderRightWidth: "10px",
                            borderRightColor: "transparent",
                            borderBottomWidth: "10px",
                            borderTop: "0px solid transparent",
                            transform: `rotate(${rotation[position]}deg)`,
                        }}
                    />
                </Portal>
            )}
            <TooltipPopup
                {...tooltip}
                label={
                    <>
                        {title && <p className="tooltip__title">{title}</p>}
                        {text && <p>{text}</p>}
                    </>
                }
                aria-label={ariaLabel}
                className={`tooltip tooltip--${color}`}
                position={positions[position](8, 2)}
            />
        </>
    );
};

export default Tooltip;
