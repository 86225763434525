import { useState } from "react";
import { ClientUtils } from "@scramjet/client-utils";
import { middlewareUrl } from "../../components/MiddlewareClientProvider";
import { ArrowClockwise } from "react-bootstrap-icons";
import "./VerifyEmail.scss";
import { globalStateStore } from "../../utils/globalStateStore";
import { sendAuthenticationEvent, sendGenericErrorEvent } from "../../utils/gtmEvents";
import { ClickButton } from "../../components/Buttons";

const VerifyEmail: React.FC<VerifyEmailProps> = ({ userId }) => {
    const [status, setStatus] = useState<SendStatus>("initial");
    const sendEmailVerification = async () => {
        try {
            if (globalStateStore.set("last_user_auth_state", "email_verification")) {
                sendAuthenticationEvent("console_registration_complete");
            }

            setStatus("loading");
            const clientUtils = new ClientUtils(middlewareUrl);

            await clientUtils.post<{ portalUrl: string }>(
                "/email-verification",
                { userId: userId },
                {},
                { json: true, parse: "json" }
            );

            setStatus("success");
        } catch (e) {
            console.error(e);
            sendGenericErrorEvent("verify-email", e);

            setStatus("error");
        }
    };

    switch (status) {
        case "loading":
            return (
                <p className="email__status">
                    <ArrowClockwise className="email__icon icon--success icon--rotating" size="3rem" />
                    Resending e-mail
                </p>
            );
        case "error":
            return <p className="email__status email__status--error">Something went wrong</p>;
        case "success":
            return <p className="email__status email__status--success">E-mail has been sent</p>;
        default:
            return (
                <ClickButton variant="secondary" addClass="email__link" onClick={sendEmailVerification} text="You didn't receive your mail? Resend e-mail confirmation"></ClickButton>
            );
    }
};

export default VerifyEmail;
