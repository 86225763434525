import { useMatches } from "react-router-dom";
import { ChevronRight } from "react-bootstrap-icons";
import "./Breadcrumbs.scss";

function insertChoveronsBetween(elements: (JSX.Element | null)[]) : (JSX.Element | null)[] {
    const result = [];

    for (let index = 0; index < elements.length; index++) {
        const element = elements[index];

        if (result.length > 0) {
            result.push(<ChevronRight key={`choveron-${index}`} className="breadcrumbs__chevron" />);
        }
        result.push(element);
    }

    return result;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = () => {
    const matches = useMatches() as BreadcrumbMatch[];
    const crumbs = (matches
        .filter((match) => typeof match?.handle?.crumb === "function") as BreadcrumbMatchFiltered[])
        .map((match) => match.handle.crumb(match.params));
    const crumbsWithChoverons = insertChoveronsBetween(crumbs);
    const breadcrumbsVisible = !!matches[matches.length - 1]?.handle?.breadcrumbs;

    return <div className="breadcrumbs">{breadcrumbsVisible ? crumbsWithChoverons : ""}</div>;
};

export default Breadcrumbs;
