import { ClientUtils } from "@scramjet/client-utils";
import { useEffect, useState } from "react";
import { sendErrorEvent } from "../utils/gtmEvents";
import { middlewareUrl } from "../components/MiddlewareClientProvider";

export function useCheckoutSessions() {
    const [status, setStatus] = useState<"idle" | "loading" | "success" | "error">("idle");
    const [error, setError] = useState<string | null>(null);
    const [checkoutSessions, setCheckoutSessions] = useState<PaymentSession[] | null>(null);

    useEffect(() => {
        (async () => {
            try {
                setStatus("loading");
                setError(null);

                const clientUtils = new ClientUtils(middlewareUrl);
                const checkoutSessions = await clientUtils.get<PaymentSession[]>("/api/v1/checkout-sessions");

                setCheckoutSessions(checkoutSessions);
                setStatus("success");
            } catch (e) {
                console.error(e);
                sendErrorEvent("console_checkout_begin", e);

                setStatus("error");
                setError("Error while retrieving checkout sessions.");
            }
        })();
    }, []);

    return { checkoutSessions, status, error };
}
