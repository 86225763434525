import "../Button.scss";

import { PropsWithChildren } from "react";

const ButtonContent: React.FC<PropsWithChildren<ButtonContentProps>> = ({
    text,
    icon,
    children,
    img
}) => {
    return (
        <>
            {icon && <span className="btn__icon">{icon}</span>}
            {img && <div className="btn__image">{img}</div>}
            {text}
            {children}
        </>
    );
};

export default ButtonContent;
