import { Instance as InstanceAPI } from "@scramjet/types";
export default class InstanceMap {
    static toDTO(source: InstanceAPI, hubId: HubId, spaceId: SpaceId): Instance {
        return {
            hubId,
            spaceId,
            appConfig: {},
            provides: "",
            args: [],
            sequenceArgs: [],
            created: "",
            started: "",
            ended: "",
            status: "initializing",
            hasLogs: source.terminated?.exitcode !== 24,
            ...source,
        };
    }
}
