import Bg404 from "../../assets/images/404-bg.svg";
import { ButtonGroup, ExternalLinkButton } from "../../components/Buttons";
import Error404 from "../../components/ErrorInfo/Error404";
import "./NotFoundPage.scss";

const NotFoundPage = () => (
    <div className="error-page" style={{ backgroundImage: `url(${Bg404})` }}>
        <Error404>
            <ButtonGroup align="center">
                <ExternalLinkButton text="Go to spaces" href="/space/hubs" variant="primary" />
            </ButtonGroup>
        </Error404>
    </div>
);

export default NotFoundPage;
