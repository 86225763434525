import "../Button.scss";

import { PropsWithChildren } from "react";
import ButtonContent from "../ButtonContent";

const ClickButton: React.FC<PropsWithChildren<ClickButtonProps>> = ({
    variant = "primary",
    type = "button",
    disabled = false,
    addClass = "",
    text,
    icon,
    onClick,
    margin = "",
    width,
    children,
    ...rest
}) => {
    const classes = `btn btn--${variant} ${margin} ${addClass}`;
    const style = width ? { width: `${width}em` } : undefined;

    return (
        <button
            className={classes}
            type={type}
            value={text}
            onClick={onClick}
            disabled={disabled}
            style={style}
            {...rest}
        >
            <ButtonContent text={text} icon={icon}>
                {children}
            </ButtonContent>
        </button>
    );
};

export default ClickButton;
