import { Position } from "@reach/tooltip";

type TooltipPosition = (offset: number, margin: number) => Position;

const above: TooltipPosition = (offset, margin) => (triggerRect, tooltipRect) => {
    if (!triggerRect || !tooltipRect) return {};

    const triggerCenter = triggerRect.left + triggerRect.width / 2;
    const left = triggerCenter - tooltipRect.width / 2;
    const maxLeft = window.innerWidth - tooltipRect.width - margin;

    return {
        left: Math.min(Math.max(margin, left), maxLeft) + window.scrollX,
        top: triggerRect.top - tooltipRect.height - offset + window.scrollY,
    };
};
const below: TooltipPosition = (offset, margin) => (triggerRect, tooltipRect) => {
    if (!triggerRect || !tooltipRect) return {};

    const triggerCenter = triggerRect.left + triggerRect.width / 2;
    const left = triggerCenter - tooltipRect.width / 2;
    const maxLeft = window.innerWidth - tooltipRect.width - margin;

    return {
        left: Math.min(Math.max(margin, left), maxLeft) + window.scrollX,
        top: triggerRect.bottom + offset + window.scrollY,
    };
};
const right: TooltipPosition = (offset, margin) => (triggerRect, tooltipRect) => {
    if (!triggerRect || !tooltipRect) return {};

    const triggerCenter = triggerRect.top + triggerRect.height / 2;
    const top = triggerCenter - tooltipRect.height / 2;

    return {
        left: triggerRect.left + triggerRect.width + offset,
        top: top + window.scrollY,
    };
};
const left: TooltipPosition = (offset, margin) => (triggerRect, tooltipRect) => {
    if (!triggerRect || !tooltipRect) return {};

    const triggerCenter = triggerRect.top + triggerRect.height / 2;
    const top = triggerCenter - tooltipRect.height / 2;

    return {
        left: triggerRect.left - tooltipRect.width - offset,
        top: top + window.scrollY,
    };
};

export { above, right, below, left };
