import { PropsWithChildren } from "react";
import { usePopover } from "../../../store/onboarding/onboardingSlice";
import { ClickButton } from "../../../components/Buttons";

const SequenceStorePopover: React.FC<PropsWithChildren> = ({ children }) => {
    const { popoverData: storePopoverData, displayPopover: displayStore, nextPopover: nextStorePopover, Popover } = usePopover("sequence-store");

    return (
        <Popover
            title={storePopoverData?.title as string}
            text={storePopoverData?.text as string}
            position={storePopoverData?.position as PositionProps}
            content={
                <ClickButton variant="primary" text="Next" onClick={() => {
                    nextStorePopover();
                }} />
            }
            counter={6}
            display={displayStore}
        >
            {children}
        </Popover>
    );
};

export default SequenceStorePopover;
