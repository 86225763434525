import { PropsWithChildren } from "react";
import { usePopover, useOnboarding } from "../../../store/onboarding/onboardingSlice";
import { InternalLinkButton } from "../../../components/Buttons";

type StartSequencePopoverType = {
    addedSequenceId: string;
}

const StartSequencePopover: React.FC<PropsWithChildren<StartSequencePopoverType>> = ({ children, addedSequenceId }) => {
    const { popoverData: sequencePopoverData, displayPopover: displaySequence, nextPopover: nextSequencePopover, Popover } = usePopover("start-sequence");

    return (
        <Popover
            title={sequencePopoverData?.title as string}
            text={sequencePopoverData?.text as string}
            position={sequencePopoverData?.position as PositionProps}
            content={
                <InternalLinkButton variant="primary" text="Next" to={`/space/sequences/${addedSequenceId}/start`} onClick={() => {
                    nextSequencePopover();
                }} />
            }
            counter={8}
            display={displaySequence}
        >
            {children}
        </Popover>
    );
};

export default StartSequencePopover;
