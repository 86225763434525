export const configs = {
    GTM: "GTM-KJCJX5G",
    GA4: "G-RJXN0HJQES",
    ADS: "AW-10787002371",
};
export type GTMEventNameType =
    | "console_error"
    | "console_user_unauthenticated"
    | "console_registration_complete"
    | "console_checkout_begin"
    | "console_checkout_cancel"
    | "console_add_payment_method"
    | "console_login_complete"
    | "console_profile_click"
    | "console_user_logout"
    | "console_user_remove"
    | "console_user_reset_password"
    | "console_generate_key"
    | "console_logout_click"
    | "console_copy_key"
    | "console_start_trial"
    | "console_trial_upgrade"
    | "console_pricing_click"
    | "console_payments_click"
    | "console_report_issue"
    | "console_suggest_feature"
    | "console_join_community"
    | "console_docs_click"
    | "console_open_space"
    | "console_open_space_no_account"
    | "console_support_click"
    | "console_add_hub_click"
    | "console_generate_hub_key"
    | "console_cli_settings_click"
    | "console_space_access_keys_click";

type primitive = number | string | boolean | undefined;

/**
 * Defines Google Tag Manager config, includes all data that GTM requires to record conversion
 * This might include stuff from google ads (for instance "send_to"), but there's no
 * specific type.
 */
type GTMConversionConfig = Partial<{
    send_page_view: boolean;
    user_id: string;
    [key: string]: primitive;
}>[];

export const conversions: Partial<Record<GTMEventNameType, GTMConversionConfig>> = {
    console_registration_complete: [{ send_to: "AW-10787002371/zJTfCISztooYEIOw0pco", value: 0.8, currency: "EUR" }],
    console_login_complete: [{ send_to: "AW-10787002371/HyqbCOGktooYEIOw0pco", value: 0.8, currency: "EUR" }],
    console_profile_click: [{ send_to: "AW-10787002371/SqA2CJDMs48YEIOw0pco", value: 1.0, currency: "EUR" }],
    console_generate_key: [{ send_to: "AW-10787002371/BO5PCJPMs48YEIOw0pco", value: 1.0, currency: "EUR" }],
    console_copy_key: [{ send_to: "AW-10787002371/3r3XCJbMs48YEIOw0pco", value: 1.0, currency: "EUR" }],
    console_add_payment_method: [{ send_to: "AW-10787002371/Bk3ACOCCzo8YEIOw0pco", value: 3, currency: "EUR" }],
    console_start_trial: [{ send_to: "AW-10787002371/vpPiCLPau48YEIOw0pco", value: 3, currency: "EUR" }],
    console_checkout_begin: [{ send_to: "AW-10787002371/gX9tCO_2zY8YEIOw0pco", value: 2, currency: "EUR" }],
    console_checkout_cancel: [{ send_to: "AW-10787002371/_18uCOzm0I8YEIOw0pco", value: 2, currency: "EUR" }],
    console_trial_upgrade: [{ send_to: "AW-10787002371/taKkCKbru48YEIOw0pco", value: 5, currency: "EUR" }],
    console_pricing_click: [{ send_to: "AW-10787002371/G3QoCOffvI8YEIOw0pco", value: 3, currency: "EUR" }],
    console_payments_click: [{ send_to: "AW-10787002371/DigVCJ3ru48YEIOw0pco", value: 3, currency: "EUR" }],
    console_report_issue: [{ send_to: "AW-10787002371/RiMwCKDru48YEIOw0pco", value: 0.1, currency: "EUR" }],
    console_suggest_feature: [{ send_to: "AW-10787002371/NU_KCJ-6yI8YEIOw0pco", value: 0.1, currency: "EUR" }],
    console_join_community: [{ send_to: "AW-10787002371/syg7CKK6yI8YEIOw0pco", value: 0.1, currency: "EUR" }],
    console_docs_click: [{ send_to: "AW-10787002371/89vmCKPru48YEIOw0pco", value: 0.1, currency: "EUR" }],
    console_open_space: [{ send_to: "AW-10787002371/od6NCKnru48YEIOw0pco", value: 0.1, currency: "EUR" }],
    console_support_click: [{ send_to: "AW-10787002371/fPtiCKzru48YEIOw0pco", value: 0.1, currency: "EUR" }],
    console_add_hub_click: [{ send_to: "AW-10787002371/ayCgCICGpK8YEIOw0pco", value: 0.5, currency: "EUR" }],
    console_generate_hub_key: [{ send_to: "AW-10787002371/7E1DCIOGpK8YEIOw0pco", value: 0.5, currency: "EUR" }],
};
