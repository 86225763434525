import { ChangeEventHandler, DragEventHandler, useState } from "react";
import "./UploadLayer.scss";

export const UploadLayer: React.FC<UploadLayerProps> = ({
    onSuccess,
    className = "",
    clickable = true,
    children,
    readonly,
    label,
    id,
}) => {
    const [isDragged, setIsDragged] = useState(false);
    const handleIsDragged: DragEventHandler<HTMLDivElement> = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        setIsDragged(true);
    };
    const handleDragEnd: DragEventHandler<HTMLDivElement> = evt => {
        evt.preventDefault();
        evt.stopPropagation();
        setIsDragged(false);
    };
    const handleDrop: DragEventHandler<HTMLDivElement> = evt => {
        evt.preventDefault();
        evt.stopPropagation();

        const files = evt.dataTransfer.files;

        if (typeof onSuccess === "function") onSuccess(files || null);
        setIsDragged(false);
    };
    const handleChange: ChangeEventHandler<HTMLInputElement> = evt => {
        const files = evt.target.files;

        if (files && files.length === 0) return;

        if (typeof onSuccess === "function") onSuccess(files || null);
        setIsDragged(false);
    };

    return (
        <div className="upload-layer__container">
            {readonly ? (
                <div className={`upload-layer ${className}`}>
                    <p className="upload-layer__label subtitle-1 margin--t-2 margin--b-1">{label}</p>
                    {typeof children === "function" ? children({ isDragged }) : children}
                </div>
            ) : (
                <>
                    <label htmlFor={id} className="upload-layer__label subtitle-1 margin--t-2 margin--b-1">{label}</label>
                    <div
                        className={`upload-layer ${className}`}
                        onDrop={handleDrop}
                        onDragOver={handleIsDragged}
                        onDragEnter={handleIsDragged}
                        onDragLeave={handleDragEnd}
                        onDragEnd={handleDragEnd}
                    >
                        {clickable && <input type="file" onChange={handleChange} id={id} name={id} className="upload-layer__input"></input>}
                        {typeof children === "function" ? children({ isDragged }) : children}
                    </div>
                </>
            )}
        </div>
    );
};
