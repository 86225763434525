import { createEntityAdapter, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "..";
import { nanoid } from "@reduxjs/toolkit";

const sliceName = "toasts";
const toastsAdapter = createEntityAdapter<ToastPropsWithKey>({ selectId: model => model.key });
const initialState: ToastsState = toastsAdapter.getInitialState();
const selectSelf = (state: RootState): ToastsState => state[sliceName];

export const { selectAll: selectToasts, selectById: selectToastsByKey } =
    toastsAdapter.getSelectors<RootState>(selectSelf);

export const toasts = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        addToast: {
            reducer: (state, action: PayloadAction<ToastPropsWithKey>) => {
                toastsAdapter.addOne(state, action.payload);
            },
            prepare: (toastProps: ToastProps) => {
                const key = nanoid();

                return { payload: { key, ...toastProps } };
            },
        },
    },
});

export default toasts.reducer;
