/* eslint-disable complexity */
/* eslint-disable indent */
import {
    ArrowRepeat,
    BarChartFill,
    Check2Circle,
    Clipboard,
    ColumnsGap,
    CreditCardFill,
    Download,
    EnvelopeFill,
    ExclamationTriangleFill,
    FileEarmarkCodeFill,
    FileEarmarkTextFill,
    GearFill,
    Pc,
    PeopleFill,
    QuestionOctagonFill,
    Server,
    WalletFill,
    X,
    BoxFill,
    KeyFill
} from "react-bootstrap-icons";

const BootstrapIcon: React.FC<BootstrapIconProps> = ({ ico, size = 16, fill }) => {
    const color = fill ? `var(--${fill})` : "inherit";

    switch (ico) {
        case "dashboard":
            return <ColumnsGap className="icon" size={size} color={color} />;
        case "hub":
            return <BoxFill className="icon icon--hub" size={size} color={color} />;
        case "sequence":
            return <FileEarmarkCodeFill className="icon icon--seq" size={size} color={color} />;
        case "run":
            return <FileEarmarkCodeFill className="icon" size={size} color={color} />;
        case "instance":
            return <Server className="icon icon--inst" size={size} color={color} />;
        case "reports":
            return <BarChartFill className="icon" size={size} color={color} />;
        case "payments":
            return <CreditCardFill className="icon" size={size} color={color} />;
        case "users":
            return <PeopleFill className="icon" size={size} color={color} />;
        case "docs":
            return <FileEarmarkTextFill className="icon" size={size} color={color} />;
        case "wallet":
            return <WalletFill className="icon" size={size} color={color} />;
        case "envelope":
            return <EnvelopeFill className="icon" size={size} color="var(--warning)" />;
        case "settings":
            return <GearFill className="icon" size={size} color={color} />;
        case "key":
            return <KeyFill className="icon" size={size} color={color} />;
        case "support":
            return <QuestionOctagonFill className="icon" size={size} color={color} />;
        case "error":
            return <ExclamationTriangleFill className="icon" size={size} color="var(--error)" />;
        case "success":
            return <Check2Circle className="icon" size={size} color="var(--success)" />;
        case "progress":
            return <ArrowRepeat className="icon" size={size} color={color} />;
        case "download":
            return <Download className="icon" size={size} color={color} />;
        case "copy":
            return <Clipboard className="icon" size={size} color={color} />;
        case "close":
            return <X className="icon" size={size} color={color} />;
        default:
            return <Server className="icon" size={size} color={color} />;
    }
};

export default BootstrapIcon;
