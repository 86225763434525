import { Link } from "react-router-dom";

export const SpaceCrumb: React.FC = () => {
    return <Link key="space-crumb" className="breadcrumbs__crumb" to="/space/hubs">Space</Link>;
};

/** First Level **/

export const InstancesCrumb: React.FC = () => {
    return <Link key="instances-crumb" className="breadcrumbs__crumb" to="/space/instances">Instances</Link>;
};
export const SequencesCrumb: React.FC = () => {
    return <Link key="sequences-crumb" className="breadcrumbs__crumb" to="/space/sequences">Sequences</Link>;
};
export const HubsCrumb: React.FC = () => {
    return <Link key="hubs-crumb" className="breadcrumbs__crumb" to="/space/hubs">Hubs</Link>;
};
export const TopicsCrumb: React.FC = () => {
    return <Link key="topics-crumb" className="breadcrumbs__crumb" to="/space/topics">Topics</Link>;
};

/** Second Level **/

export const HubAddCrumb: React.FC = () => {
    return <Link key="hub-add-crumb" className="breadcrumbs__crumb" to="/space/hubs/add">Add Self-Hosted Hub</Link>;
};
export const SequenceAddCrumb: React.FC = () => {
    return <Link key="sequence-add-crumb" className="breadcrumbs__crumb" to="/space/sequences/add">Add Sequence</Link>;
};
export const SequenceStartCrumb: React.FC<{sequenceId: string}> = (data) => {
    return <Link key="sequence-start-crumb" className="breadcrumbs__crumb" to={`/space/sequences/${data.sequenceId}/start`}>Start Sequence</Link>;
};
export const SequenceDetailsCrumb: React.FC<{sequenceId: string, sequenceTabName: string}> = (data) => {
    return <Link key="sequence-details-crumb" className="breadcrumbs__crumb" to={`/space/sequences/${data.sequenceId}/${data.sequenceTabName}`}>{data.sequenceId}</Link>;
};
export const InstanceDetailsCrumb: React.FC<{instanceId: string, instanceTabName: string}> = (data) => {
    return <Link key="instance-details-crumb" className="breadcrumbs__crumb" to={`/space/instances/${data.instanceId}/${data.instanceTabName}`}>{data.instanceId}</Link>;
};
export const HubDetailsCrumb: React.FC<{hubId: string, hubTabName: string}> = (data) => {
    return <Link key="hub-details-crumb" className="breadcrumbs__crumb" to={`/space/hubs/${data.hubId}/${data.hubTabName}`}>{data.hubId}</Link>;
};

