import { MRestAPI } from "@scramjet/types";

export default class TopicMap {
    static toDTO(source: MRestAPI.GetTopicsResponse[0], spaceId: SpaceId): Topic {
        return {
            spaceId,
            ...source,
        };
    }
}
