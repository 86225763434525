import { useEffect, useState } from "react";
import ReactVisibilitySensor from "react-visibility-sensor";
import ConsoleLog from "../../components/ConsoleLog";
import { loadInstanceLogStream } from "../../components/ConsoleLog/loadInstanceLogStream";
import DescriptionData from "../../data/Description.json";
import Quote from "../../components/Quote";
import ConsoleLogLoader from "../../components/ConsoleLog/ConsoleLogLoader";

const InstanceLogs: React.FC<InstanceLogsProps> = ({ spaceId, hubId, instanceId }) => {
    const [logStream, setLogStream] = useState<ReadableStream>();
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        if (!logStream && !!spaceId && !!hubId) {
            (async () => {
                try {
                    setLogStream(await loadInstanceLogStream(spaceId, hubId, instanceId));
                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, [spaceId, hubId]);

    return (
        <ReactVisibilitySensor onChange={setIsVisible} partialVisibility={true}>
            <div className="tab-menu__content">
                <Quote text={DescriptionData.InstanceLogs} />
                <h2 className="subtitle-2 margin--t-2 margin--b-1">Console with all type of logs and output</h2>
                {logStream && isVisible ? (
                    <ConsoleLog logStream={logStream} autoscroll={false} />
                ) : (
                    <ConsoleLogLoader/>
                )}
            </div>
        </ReactVisibilitySensor>
    );
};

export default InstanceLogs;
