import { createAsyncThunk, createEntityAdapter, createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "..";
import { useSpaces } from "../spaces/spacesSlice";
import { getMiddlewateClient } from "../../components/MiddlewareClientProvider";
import HubMap from "./HubMap";
import fastDeepEqual from "fast-deep-equal";

const sliceName = "hubs";
const hubsAdapter = createEntityAdapter<Hub>();
const initialState: HubsState = hubsAdapter.getInitialState({
    status: {},
});
const selectSelf = (state: RootState): HubsState => state[sliceName];

export const { selectAll, selectById } = hubsAdapter.getSelectors<RootState>(selectSelf);
export const selectHubById = (hubId: HubId) =>
    createSelector(
        (state: RootState) => selectById(state, hubId),
        hub => hub
    );
export const selectHubsStatus = (spaceId: SpaceId) =>
    createSelector(selectSelf, state => {
        return state.status[spaceId];
    });
export const selectHubsBySpaceId = (spaceId: SpaceId) =>
    createSelector(selectAll, hubs => hubs.filter(hub => hub.spaceId === spaceId));

export const fetchSpaceHubs = createAsyncThunk<Hub[], { spaceId: SpaceId; refetch?: boolean }, { state: RootState }>(
    `${sliceName}/fetchBySpaceId`,
    async ({ spaceId }, { getState }) => {
        const spaceClient = getMiddlewateClient().getManagerClient(spaceId);
        const spaceHubs = (await spaceClient.getHosts()).map(host => {
            return HubMap.toDTO(host, spaceId);
        });

        return spaceHubs;
    },
    {
        // Cache between page loads and until refetch
        condition: ({ spaceId, refetch }, { getState }) => {
            const status = selectHubsStatus(spaceId)(getState() as RootState);

            return !!spaceId && (refetch || (status !== "fulfilled" && status !== "pending"));
        },
    }
);

export const hubsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        syncUpdate: (state, action: PayloadAction<Hub[]>) => {
            hubsAdapter.upsertMany(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSpaceHubs.pending, (state, action) => {
                state.status[action.meta.arg.spaceId] = "pending";
            })
            .addCase(fetchSpaceHubs.fulfilled, (state, action) => {
                state.status[action.meta.arg.spaceId] = "fulfilled";
                hubsAdapter.upsertMany(state, action.payload);
            })
            .addCase(fetchSpaceHubs.rejected, (state, action) => {
                state.status[action.meta.arg.spaceId] = "rejected";
            });
    },
});

export const useHubsBySpaceId = (spaceId: SpaceId) => {
    const { spaces } = useSpaces();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchSpaceHubs({ spaceId }));
    }, [spaces]);

    const status = useSelector(selectHubsStatus(spaceId), fastDeepEqual);
    const hubs = useSelector(selectHubsBySpaceId(spaceId), fastDeepEqual);

    return { status, hubs };
};

export const useHubById = (hubId: HubId) => {
    const hub = useSelector(selectHubById(hubId), fastDeepEqual);

    return hub;
};

export default hubsSlice.reducer;
