import { ChevronDown, ChevronUp } from "react-bootstrap-icons";
import { ListboxOption, ListboxInput, ListboxPopover, ListboxList, ListboxButton } from "@reach/listbox";
import Heading from "../Heading";
import "./Select.scss";

const Select: React.FC<SelectProps> = (
    { variant, name, label, options, onChange, currentValue, disabled, marginBottom = 0, marginTop = 0 }
) => {
    const OptionItem: React.FC<OptionItemProps> = ({ opsName, disabled, icon }) =>
        <ListboxOption className="select__option" value={opsName} disabled={disabled}>
            {opsName}
            {icon}
        </ListboxOption>;

    return (
        <div className={`select select--${variant} margin--t-${marginTop} margin--b-${marginBottom}`}>
            {label && (
                <Heading id={name} className={variant === "big" ? "subtitle-1" : "subtitle-2"} text={label}/>
            )}
            <ListboxInput className="select__input" value={currentValue} aria-labelledby={name} onChange={onChange} disabled={disabled}>
                <ListboxButton className="select__button"
                    arrow={<><ChevronDown className="select__arrow--down" /><ChevronUp className="select__arrow--up" /></>}/>
                <ListboxPopover className="select__container">
                    <ListboxList className="select__list">
                        {options.map((option, index) =>
                            <OptionItem key={`${index}-${option.opsName}`} opsName={option.opsName} icon={option.icon ? option.icon : <></>} disabled={option.disabled}/>)
                        }
                    </ListboxList>
                </ListboxPopover>
            </ListboxInput>
        </div>
    );
};

export default Select;
