import "./polyfills";

import { Provider } from "react-redux";

import { createRoot } from "react-dom/client";
import AuthenticationProvider from "./components/AuthenticationProvider";
import FullscreenLoader from "./components/FullscreenLoader";
import { MiddlewareClientProvider } from "./components/MiddlewareClientProvider";
import SyncStore from "./components/SyncStore";
import { Toasts } from "./components/Toast/Toasts";
import AppRouter from "./routers/AppRouter";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import store from "./store";
import ErrorPage from "./pages/ErrorPage";
import React from "react";

const domain = process.env.REACT_APP_AUTH_DOMAIN || "";
const audience = process.env.REACT_APP_AUTH_AUDIENCE || "";
const scope = process.env.REACT_APP_AUTH_SCOPE || "openid profile email offline_access";
const clientId = process.env.REACT_APP_AUTH_CLIENT_ID || "";
const container = document.getElementById("root");
const root = createRoot(container!);
const panelParams = new URLSearchParams(window.location.search);

type MiddlewareClientProps = { token: string };

const MiddlewareProvider: React.FC<MiddlewareClientProps> = ({ token }) => (
    <MiddlewareClientProvider token={token}>
        <Provider store={store}>
            <SyncStore>
                <Toasts />
                <AppRouter />
            </SyncStore>
        </Provider>
    </MiddlewareClientProvider>
);
const ErrorHandler: React.FC<ErrorPageProps> = (props) => {
    return (
        <Provider store={store}>
            <ErrorPage {...props} />
        </Provider>
    );
};

root.render(
    <AuthenticationProvider
        domain={domain}
        clientId={clientId}
        redirectUri={window.location.origin}
        screen={panelParams.get("screen")}
        audience={audience}
        scope={scope}
        loader={<FullscreenLoader />}
        ErrorComponent={ErrorHandler}
    >
        {({ token }) => {
            return <MiddlewareProvider token={token} />;
        }}
    </AuthenticationProvider>
);

serviceWorkerRegistration.register();
