import React from "react";
import "./RadioGroup.scss";

const RadioItem: React.FC<RadioItemProps> = ({
    id,
    name,
    label,
    disabled = false,
    attentionAnimation,
    ...rest
}) => {
    return (
        <div className="radio__item">
            {attentionAnimation && <div className="attention-animation" />}
            <input className="radio__input" type="radio" name={name} id={id} aria-disabled={disabled} {...rest}/>
            <label className="radio__label" htmlFor={id}>{label}</label>
        </div>
    );
};
const RadioGroup: React.FC<RadioGroupProps> = ({
    name,
    legend,
    items,
    checkedOpt,
    attentionAnimation = false,
    onChange = () => {}
}) => {
    return (
        <fieldset className="radio__group">
            <legend className="radio__legend subtitle-1">{legend}</legend>
            {
                items.map((item, key) => {
                    const { id, label, disabled } = item;

                    return (<RadioItem key={`${key}-${id}`} id={id} name={name} label={label} disabled={disabled} attentionAnimation={id === "hub" && attentionAnimation} checked={checkedOpt === id} onChange={onChange} />);
                })
            }
        </fieldset>
    );
};

export default RadioGroup;
