import { useState } from "react";
import { EyeFill, StopFill, X } from "react-bootstrap-icons";
import { ButtonGroup, ClickButton } from "../../components/Buttons";
import ButtonCircle from "../../components/ButtonCircle";
import DateTime from "../../components/DateTime";
import { DialogModal } from "../../components/Modal";
import Table from "../../components/Table";
import Tooltip from "../../components/Tooltip";
import dialogs from "../../data/modals/Dialogs.json";
import { useAppDispatch } from "../../store";
import { killInstance, stopInstance, useInstancesBySpaceId } from "../../store/instances/instancesSlice";
import InstanceRenderStatus from "../InstancePage/InstanceRenderStatus";
import InstanceTabPopover from "./InstancesTabPopover";

import CopyText from "../../components/CopyText";
import TableLoader from "./TableLoader";
import { Link } from "react-router-dom";

const InstancesTab: React.FC<InstancesTabProps> = ({ spaceId }) => {
    const { instances, status: instancesStatus } = useInstancesBySpaceId(spaceId);
    const { title, desc, variant, buttons } = dialogs["kill-instance"];
    const [instanceToKill, setInstanceToKill] = useState<Instance | null>(null);
    const dispatch = useAppDispatch();
    const handleStop = (instance: Instance) => {
        dispatch(stopInstance({ spaceId: instance.spaceId, hubId: instance.hubId, instanceId: instance.id }));
    };
    const handleOpenKillModal = (instance: Instance) => {
        setInstanceToKill(instance);
    };
    const handleCloseKillModal = () => {
        setInstanceToKill(null);
    };
    const handleKill = () => {
        if (instanceToKill) {
            dispatch(
                killInstance({
                    spaceId: instanceToKill.spaceId,
                    hubId: instanceToKill.hubId,
                    instanceId: instanceToKill.id,
                })
            );
            handleCloseKillModal();
        }
    };

    function actionsDisabled(status: InstanceStatus) {
        return status !== "running";
    }

    if (["idle", "pending"].includes(instancesStatus)) {
        return (<TableLoader />);
    }

    if (instancesStatus === "rejected") {
        return (<>There was an error while retrieving instances from the space. If the problem persists, {" "}
            <Link to="/support">please contact the support team.</Link></>);
    }

    return (
        <>
            <Table rows={["Instance", "Location", "Status", "Actions"]}>
                {instances.map((inst, instIndex) => {
                    const { started, created } = inst;

                    return (
                        <tr key={instIndex} wd-data="instance" data-qa={`instances/${instIndex}`}>
                            {instIndex === (instances.length - 1) ? (
                                <td className="table__id-inst">
                                    <InstanceTabPopover>
                                        <strong>ID:</strong> {inst.id}<CopyText text={inst.id} /> <br />
                                        <strong>Created at: </strong>
                                        <DateTime started={started} created={created} />
                                    </InstanceTabPopover>
                                </td>
                            ) : (
                                <td className="table__id-inst">
                                    <strong>ID:</strong> {inst.id}<CopyText text={inst.id} /> <br />
                                    <strong>Created at: </strong>
                                    <DateTime started={started} created={created} />
                                </td>
                            )}
                            <td className="table__id-hub">{inst.hubId}</td>
                            <td className="table__status" wd-data={inst.status}>
                                <InstanceRenderStatus
                                    instanceId={inst.id}
                                    status={inst.status}
                                    terminated={inst.terminated}
                                    hasLogs={inst.hasLogs}
                                />
                            </td>
                            <td>
                                <ButtonGroup align="left">
                                    <Tooltip text="Stop" position="above">
                                        <div>
                                            <ButtonCircle
                                                onClick={() => handleStop(inst)}
                                                disabled={actionsDisabled(inst.status)}
                                            >
                                                <StopFill className="icon--error" size="2rem" />
                                            </ButtonCircle>
                                        </div>
                                    </Tooltip>
                                    <Tooltip text="Kill" position="above">
                                        <div>
                                            <ButtonCircle
                                                onClick={() => handleOpenKillModal(inst)}
                                                disabled={actionsDisabled(inst.status)}
                                            >
                                                <X className="icon--error" size="2.5rem" />
                                            </ButtonCircle>
                                        </div>
                                    </Tooltip>
                                    <Tooltip text="Details" position="above">
                                        <div>
                                            <ButtonCircle to={`/space/instances/${inst.id}/basic-info`}>
                                                <EyeFill className="icon--error" size="2rem" />
                                            </ButtonCircle>
                                        </div>
                                    </Tooltip>
                                </ButtonGroup>
                            </td>
                        </tr>
                    );
                })}
            </Table>
            <DialogModal
                title={title}
                variant={variant as ModalVariant}
                onClose={handleCloseKillModal}
                open={!!instanceToKill}
                buttons={
                    <>
                        <ClickButton
                            text={buttons.cancel.text}
                            variant={buttons.cancel.variant as ButtonVariant}
                            onClick={handleCloseKillModal}
                        />
                        <ClickButton
                            text={buttons.kill.text}
                            variant={buttons.kill.variant as ButtonVariant}
                            onClick={handleKill}
                        />
                    </>
                }
            >
                {desc.replaceAll("${name}", instanceToKill?.id || "")}
            </DialogModal>
        </>
    );
};

export default InstancesTab;
