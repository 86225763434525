import { PropsWithChildren } from "react";
import { usePopover } from "../../store/onboarding/onboardingSlice";
import AccessibleImg from "../../layout/AccessibleImg";
import ConfettiImg from "../../assets/images/confetti.svg";

const InstanceTabPopover: React.FC<PropsWithChildren> = ({ children }) => {
    const { popoverData, displayPopover, Popover } = usePopover("congrats");

    return (
        <Popover
            title={popoverData?.title as string}
            text={popoverData?.text as string}
            position={popoverData?.position as PositionProps}
            content={<></>}
            image={<AccessibleImg className="margin--b-2 margin--t-1" standardImg={ConfettiImg} contrastImg={ConfettiImg} alt="Exploding Confetti" />}
            display={displayPopover}
        >
            {children}
        </Popover>
    );
};

export default InstanceTabPopover;
