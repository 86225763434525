import { useSelector } from "react-redux";
import Toast from ".";
import { RootState, useAppDispatch } from "../../store";
import { selectToasts, toasts } from "../../store/toasts/toastsSlice";
import { useState } from "react";
import useScrollCondition from "../../hooks/useScrollCondition";

export const Toasts: React.FC = () => {
    const toasts = useSelector<RootState, ToastPropsWithKey[]>(state => selectToasts(state));
    const [scrolled, setScrolled] = useState(false);

    useScrollCondition(scroll => scroll > 60, setScrolled, true);

    return (
        <div className={`toast-placeholder ${scrolled ? "scrolled" : ""}`}>
            {toasts.map(toast => {
                const { key, ...toastProps } = toast;

                return <Toast key={key} {...toastProps} />;
            })}
        </div>
    );
};

export function useToast() {
    const dispatch = useAppDispatch();

    return (toastProps: ToastProps) => dispatch(toasts.actions.addToast(toastProps));
}
