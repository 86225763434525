import { Check2Circle } from "react-bootstrap-icons";
import AccountSetupProgress from "../../components/Progress";

const VerifyEmailContent: React.FC<WelcomePropos> = ({ email }) => {
    return (
        <>
            <div className="error__verify-email-success">
                <Check2Circle size={20} className="error__verify-email-icon"/>
                <p className="body-2">Your account has been successfully created</p>
            </div>
            <AccountSetupProgress currentStepId={2} />
            <p className="body-1">Open mailbox {email} to verify your account</p>
        </>
    );

};

export default VerifyEmailContent;
