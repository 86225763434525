import React, { PropsWithChildren } from "react";
import { TabMenuSimple } from "../TabMenu";
import { useTokens } from "../../store/tokens/tokensSlice";
import { useSpaceByIndex } from "../../store/spaces/spacesSlice";
import CmdCommand from "../CmdCommand";
import * as RadixPopover from "@radix-ui/react-popover";
import "./CLITooltip.scss";
import { X } from "react-bootstrap-icons";

const CliTab: React.FC = () => {
    return (
        <div className="margin--l-2 margin--r-2">
            <p className="body-1 margin--b-1">1. Choose storage location and send sequence</p>
            <p className="body-2 margin--b-1">Store (Cloud Object Storage)</p>
            <CmdCommand
                command="si store send <path/to/filename.tar.gz>"
                wrap
                variant="dark"
                fullWidth={true}
            />
            <p className="body-2 margin--b-1">Hub (Running Container)</p>
            <CmdCommand
                command="si hub use <name|hub id>"
                wrap
                variant="dark"
                fullWidth={true}
            />
            <CmdCommand
                command="si seq send <path/to/filename.tar.gz> --progress"
                wrap
                variant="dark"
                fullWidth={true}
            />
            <p className="body-1 margin--b-1">2. Display info about sequence</p>
            <CmdCommand
                command="si seq info <Sequence-id>"
                wrap
                variant="dark"
                fullWidth={true}
            />
        </div>

    );
};
const ApiTab: React.FC = () => {
    const { tokens } = useTokens();
    const { space } = useSpaceByIndex(0);

    return (
        <div className="margin--l-2 margin--r-2">
            <CmdCommand
                description="HTTP Request"
                command={`curl -v --location --request PUT --upload-file "Path/to/filename.tar.gz" "https://api.scramjet.cloud/api/v1/space/${space ? space?.id : "<SPACE-ID>"}/api/v1/s3" -H "accept: */*" -H "Authorization: Bearer ${tokens.length ? tokens[tokens.length - 1] : "<GENERATED-TOKEN>"} -H "cache-control: no-cache" -H "Content-Type: application/octet-stream" -H "Transfer-Encoding: chunked"`}
                wrap
                variant="dark"
                fullWidth={true}
            />
        </div>

    );
};
const CLITooltip: React.FC<PropsWithChildren<CLITooltipProps>> = ({ children, position = "bottom" }) => {
    return (
        <RadixPopover.Root>
            <RadixPopover.Trigger asChild>
                <RadixPopover.Anchor>{children}</RadixPopover.Anchor>
            </RadixPopover.Trigger>
            <RadixPopover.Portal>
                <RadixPopover.Content
                    side={position}
                    className="CLItooltip"
                >
                    <TabMenuSimple
                        variant="transparent"
                        orientation="horizontal"
                        tabList={[
                            "CLI",
                            "API"
                        ]}
                        tabPanels={[
                            <CliTab />,
                            <ApiTab />
                        ]}
                    />
                    <RadixPopover.Close aria-label="Close" className="modal__btn--icon">
                        <X size={30} fill="var(--tooltip-onboarding-text)" aria-hidden onClick={() => {}} />
                    </RadixPopover.Close>
                    <RadixPopover.Arrow className="CLItooltip__arrow"/>
                </RadixPopover.Content>
            </RadixPopover.Portal>
        </RadixPopover.Root>
    );
};

export default CLITooltip;
