import "./ButtonGroup.scss";

import { PropsWithChildren } from "react";

const ButtonGroup: React.FC<PropsWithChildren<ButtonGroupProps>> = ({
    align = "left",
    marginTop = "0",
    marginBottom = "0",
    gap = "1",
    className = "",
    float,
    children,
    id
}) => {
    const classes = `btn-group btn-group--${align} btn-group--gap-${gap} ${className}`;
    const margins = `margin--t-${marginTop} margin--b-${marginBottom}`;
    const floats = `${float ? "btn-group--float-" + float : ""}`;

    return (
        <div className={`${margins} ${classes} ${floats}`} id={id ? id : ""}>
            {children}
        </div>
    );
};

export default ButtonGroup;

