import { EyeFill } from "react-bootstrap-icons";
import ButtonCircle from "../../../components/ButtonCircle";
import DateTime from "../../../components/DateTime";
import Table from "../../../components/Table";
import Tooltip from "../../../components/Tooltip";
import { useInstancesByHubId } from "../../../store/instances/instancesSlice";
import TableLoader from "../../SpacesPage/TableLoader";

const HubInstancesList: React.FC<HubInstancesListProps> = ({ hubId }) => {
    const { instances: instanceByHubId, status } = useInstancesByHubId(hubId);

    if (["idle", "pending"].includes(status)) {
        return <TableLoader />;
    }

    return (
        <div className="tab-menu__content">
            {!instanceByHubId || instanceByHubId.length === 0 ? (
                "No Instances on Hub"
            ) : (
                <Table rows={["No.", "Id", "Created at", "Actions"]} variant="light">
                    {instanceByHubId.map((hub, index) => {
                        const { id, started, created } = hub;

                        return (
                            <tr key={`${id}`}>
                                <td>{index + 1}</td>
                                <td>{id}</td>
                                <td>
                                    <DateTime started={started} created={created} />
                                </td>
                                <td>
                                    <div className="flex">
                                        <Tooltip text="Details" position="above">
                                            <div>
                                                <ButtonCircle to={`/space/instances/${id}/basic-info`}>
                                                    <EyeFill className="icon--error" size="2rem" />
                                                </ButtonCircle>
                                            </div>
                                        </Tooltip>
                                    </div>
                                </td>
                            </tr>
                        );
                    })}
                </Table>
            )}
        </div>
    );
};

export default HubInstancesList;
