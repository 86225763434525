import React from "react";
import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import "@reach/tabs/styles.css";
import "./TabMenu.scss";
import wdslug from "../../utils/wdslug";

export const TabMenuSimple: React.FC<TabMenuProps> = ({ orientation, variant = "default", tabList, tabPanels, flex = false }) => {
    const tab = (id: number, name: string) => {
        let slug = wdslug(name);

        return (
            <Tab key={id} className="tab-menu__item" wd-data={slug}>
                {name}
            </Tab>
        );
    };

    return (
        <Tabs orientation={orientation} className={`tab-menu tab-menu--${orientation} tab-menu--${variant}`}>
            <TabList className="tab-menu__list">{tabList.map((name, id) => tab(id, name))}</TabList>
            <TabPanels className="tab-menu__container">
                {tabPanels.map((context, index) => {
                    return (
                        <TabPanel key={index} wd-data={wdslug(tabList[index])}>
                            <div className={`${flex ? "flex" : ""} tab-menu__context`}>{context}</div>
                        </TabPanel>
                    );
                })}
            </TabPanels>
        </Tabs>
    );
};
