import { createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";
import { ClientUtils } from "@scramjet/client-utils";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "..";
import { middlewareUrl } from "../../components/MiddlewareClientProvider";

const sliceName = "invoices";
const invoicesAdapter = createEntityAdapter<Invoice>();
const initialState: InvoicesState = invoicesAdapter.getInitialState({
    status: "idle",
});
const selectSelf = (state: RootState): InvoicesState => state[sliceName];

export const { selectAll: selectInvoices, selectById: selectInvoiceById } =
    invoicesAdapter.getSelectors<RootState>(selectSelf);
export const selectInvoicesState = createSelector(selectSelf, state => state.status);

export const fetchAllInvoices = createAsyncThunk<Invoice[], undefined, { state: RootState }>(
    `${sliceName}/fetchAll`,
    async () => {
        const clientUtils = new ClientUtils(middlewareUrl + "/api/v1");
        const invoices = await clientUtils.get<Invoice[]>("/invoices");

        return invoices;
    },
    {
        // Cache between page loads
        condition: (_, { getState }) => {
            const loading = selectInvoicesState(getState() as RootState);

            return loading !== "fulfilled" && loading !== "pending";
        },
    }
);

export const invoiceSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchAllInvoices.pending, (state, action) => {
                state.status = "pending";
            })
            .addCase(fetchAllInvoices.fulfilled, (state, action) => {
                state.status = "fulfilled";
                invoicesAdapter.upsertMany(state, action.payload);
            })
            .addCase(fetchAllInvoices.rejected, (state, action) => {
                state.status = "rejected";
            });
    },
});

export const useInvoices = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAllInvoices());
    }, []);

    const status = useSelector(selectInvoicesState);
    const invoices = useSelector(selectInvoices);

    return { status, invoices };
};

export const useInvoiceById = (id: string) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAllInvoices());
    }, []);

    const status = useSelector(selectInvoicesState);
    const invoice = useSelector<RootState, Invoice | undefined>(state => selectInvoiceById(state, id));

    return { status, invoice };
};

export const useInvoiceByIndex = (index: number) => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchAllInvoices());
    }, []);

    const status = useSelector(selectInvoicesState);
    const invoice = useSelector<RootState, Invoice | undefined>((state: RootState) => {
        const invoices = selectInvoices(state);

        return invoices[index] || undefined;
    });

    return { status, invoice };
};

export default invoiceSlice.reducer;
