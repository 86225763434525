import { ButtonGroup } from "../../../components/Buttons";

const SpaceButtonsContainer: React.FC = () => {
    return (
        <ButtonGroup className="space__buttons" id="spaceButtonsContainer">
        </ButtonGroup>
    );
};

export default SpaceButtonsContainer;
