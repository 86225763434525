type AllowedSetValues = string;
type AllowedGetValues = AllowedSetValues | undefined;
type AllowedKeys = "last_user_auth_state" | "onboarding";

export type GlobalStateStorage = {
  /**
     * Fetches a key from store
     *
     * @param key stored data identifier
     */
    get(key: AllowedKeys): AllowedGetValues;
    /**
     * Fetches sets a key in store
     *
     * @param key stored data identifier
     * @returns true if the key was changed
     */
    set(key: AllowedKeys, value: AllowedSetValues): boolean;
    setJSON(key: AllowedKeys, value: AllowedSetValues | object): boolean;
    getJSON(key: AllowedKeys): AllowedSetValues;
};

const currentState: Record<string, AllowedGetValues> = {};
const makeKey = (key: string) => `SCPGLOBALSTORE::${key}`;

export const globalStateStore: GlobalStateStorage = Object.assign(
    typeof window !== "undefined" && window.localStorage
        ? {
            set(_key: AllowedKeys, value: AllowedSetValues) {
                const key = makeKey(_key);
                const prevValue = window.localStorage.getItem(key);

                if (prevValue === value) return false;

                window.localStorage.setItem(key, value);
                currentState[key] = value;
                return true;
            },
            get(key: AllowedKeys) {
                return window.localStorage.getItem(makeKey(key)) || undefined;
            },
            setJSON(key: AllowedKeys, value: AllowedSetValues): boolean {
                const stringifiedValue = JSON.stringify(value);

                return this.set(key, stringifiedValue);
            },
            getJSON(key: AllowedKeys): AllowedSetValues {
                return JSON.parse(this.get(key) as string);
            },
        }
        : {
            set(key: AllowedKeys, value: AllowedSetValues) {
                const prevValue = currentState[key];

                currentState[key] = value;
                return prevValue !== value;
            },
            get(key: AllowedKeys) {
                return currentState[key];
            },
            setJSON(key: AllowedKeys, value: AllowedSetValues | object): boolean {
                const stringifiedValue = JSON.stringify(value);

                return this.set(key, stringifiedValue);
            },
            getJSON(key: AllowedKeys): AllowedSetValues {
                return JSON.parse(this.get(key) as string);
            },
        },
    {}
);
