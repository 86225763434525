import { ArrowRight } from "react-bootstrap-icons";
import BootstrapIcon from "../../components/BootstrapIcon";
import { MainNavLink } from "./MainNav";

const MainNavLinkClick: React.FC<
    Omit<MainNavLink, "to" | "href" | "target"> & { className: string; onClick: () => void }
> = ({ className, onClick, label, icon, disabled }) => (
    <button className={className} onClick={() => onClick()} aria-label={label} disabled={disabled}>
        <BootstrapIcon ico={icon} />
        <span className="nav__label">{label}</span>
        <ArrowRight className="arrow-icon"/>
    </button>
);

export default MainNavLinkClick;
