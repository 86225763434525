import { PlusLg } from "react-bootstrap-icons";
import { InternalLinkButton } from "../../../components/Buttons";
import { usePopover } from "../../../store/onboarding/onboardingSlice";
import AddSeqButtonPopover from "./AddSequenceButtonPopover";

const AddSequenceButton: React.FC<{ disabled: boolean }> = ({ disabled }) => {
    const { displayPopover } = usePopover("first-sequence");

    return (
        <AddSeqButtonPopover>
            {displayPopover && <div className="attention-animation" />}
            <InternalLinkButton variant="primary" text={"Add Sequence"} icon={<PlusLg />} to="/space/sequences/add" disabled={disabled}/>
        </AddSeqButtonPopover>
    );
};

export default AddSequenceButton;
