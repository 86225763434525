import MainNav from "../MainNav";
import mainNavLinks from "../../data/MainNav.json";
import { useSpaces } from "../../store/spaces/spacesSlice";
import { MainNavLink } from "../MainNav/MainNav";
import { useInvoices } from "../../store/invoices/invoicesSlice";
import { useSubscriptions } from "../../store/subscriptinos/subscriptionsSlice";

const PlatformMainNav: React.FC = () => {
    const { paymentsStatus } = useSubscriptions();
    const additionalLinks: MainNavLink[] = [];
    const { spaces, status: spacesStatus } = useSpaces();
    const { invoices } = useInvoices();

    if (paymentsStatus === "active") {
        // Add Spaces
        if (spacesStatus === "fulfilled") {
            spaces.forEach((space, index) => {
                additionalLinks.push(
                    {
                        ...space,
                        homepage: index === 0,
                        label: "Space",
                        icon: "dashboard",
                        gtmEvent: "console_open_space",
                        to: `/space/${space.id}`,
                        status: spacesStatus,
                        popoverId: "",
                    },
                    {
                        ...space,
                        label: "Cloud Platform CLI",
                        icon: "settings",
                        gtmEvent: "console_cli_settings_click",
                        to: "/cloud-platform-cli",
                        popoverId: "start",
                    },
                    {
                        ...space,
                        label: "Space Access Keys",
                        icon: "key",
                        gtmEvent: "console_space_access_keys_click",
                        to: "/space-access-keys",
                    }
                );
            });
        } else {
            additionalLinks.push({
                icon: "dashboard",
                gtmEvent: "console_open_space_no_account",
                label: "Space",
                href: "#",
                status: spacesStatus,
            });
        }
    }

    // Start Trial
    if (invoices.length === 0 && paymentsStatus === "inactive") {
        additionalLinks.push({
            label: "Trial",
            icon: "dashboard",
            gtmEvent: "console_pricing_click",
            to: "start-trial",
            status: "fulfilled",
        });
    }

    // Add static links
    additionalLinks.push(...(mainNavLinks as MainNavLink[]));

    return <MainNav links={additionalLinks} />;
};

export default PlatformMainNav;
