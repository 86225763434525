import ImgError500 from "../../assets/images/error-500.svg";
import ErrorInfo from ".";
import { ButtonGroup, InternalLinkButton } from "../Buttons";

const ErrorInfoDefault: React.FC = () => {
    return (
        <ErrorInfo
            standardImg={ImgError500}
            contrastImg={ImgError500}
            title="We have a problem. Engine failure."
            description="Try to refresh the page and in case the issue persists, please contact us."
        >
            <ButtonGroup align="center">
                <InternalLinkButton text="Get support" to="/support" />
            </ButtonGroup>
        </ErrorInfo>
    );
};

export default ErrorInfoDefault;
