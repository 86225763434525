import { DoorOpenFill, PersonCircle, BoxArrowRight } from "react-bootstrap-icons";
import { useAuthentication } from "../../../components/AuthenticationProvider";
import { memo } from "react";
import Tooltip from "../../../components/Tooltip";
import "./UserAvatarError.scss";

const UserAvatarError : React.FC<WelcomePropos> = ({ email }) => {
    const { logout } = useAuthentication();
    const handleLogoutClick = async () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    function extractUsername(email: string) {
        const atIndex = email.indexOf("@");

        if (atIndex !== -1) {
            const username = email.substring(0, atIndex);

            return username;
        }
        return email;
    }

    return (
        <div className="top-panel-error">
            <div className="user-avatar-error" >
                <PersonCircle />
                <div className="user-avatar-error__text">
                    <div className="user-avatar-error__name">{extractUsername(email)}</div>
                    <div className="user-avatar-error__role">Root</div>
                </div>
            </div>

            <button onClick={handleLogoutClick} className="logout-error">
                <Tooltip text="Logout" aria-label="Logout" position="below">
                    <span tabIndex={0} >
                        <DoorOpenFill className="logout-error__icon" />
                    </span>
                </Tooltip>
            </button>
        </div>
    );
};

export default memo(UserAvatarError);
