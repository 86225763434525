import "./StartSequence.scss";
import { useState } from "react";
import { ChevronLeft, PlayFill } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import Box from "../../../components/Box";
import { ButtonGroup, ClickButton } from "../../../components/Buttons";
import { useAppDispatch } from "../../../store";
import { startSequence } from "../../../store/sequences/sequencesSlice";
import { StartSequencePayload } from "@scramjet/types/rest-api-sth";
import JSONEditor from "../../../components/JSONEditor";
import { useToast } from "../../../components/Toast/Toasts";
import Quote from "../../../components/Quote";
import Heading from "../../../components/Heading";
import { useSequenceContext } from "../../../routers/RouteLoader";
import SelectStartLocation from "./SelectStartLocation";
import { useOnboarding } from "../../../store/onboarding/onboardingSlice";
import CopyText from "../../../components/CopyText";
import SpaceButtonsPortal from "../../SpacesPage/components/SpaceButtonsPortal";
import { ShowLogsAddSequenceButtons } from "../../SpacesPage/components/SpaceButtons";
import formatSequenceError from "../../../utils/formatSequenceError";

const initialPayload: StartSequencePayload = { appConfig: {}, args: [] };
const SequenceStart: React.FC = () => {
    const navigate = useNavigate();
    const { sequence } = useSequenceContext();
    const dispatch = useAppDispatch();
    const showToast = useToast();
    const { endOnboarding, currentPopover } = useOnboarding();
    const [payload, setPayload] = useState<StartSequencePayload>(initialPayload);
    const initialHubId = sequence.location !== "store" ? sequence.location : "";
    const [hubToUpload, setHubToUpload] = useState<string>(initialHubId);
    const [isStarting, setIsStarting] = useState<boolean>(false);

    function handleCancel() {
        navigate(-1);
    }

    function handleDataChange(payload: Record<string, any>) {
        if (payload) {
            setPayload(payload as StartSequencePayload);
        }
    }

    async function handleSequenceStart() {
        try {
            setIsStarting(true);
            await dispatch(
                startSequence({
                    spaceId: sequence.spaceId,
                    hubId: hubToUpload,
                    sequenceId: sequence.id,
                    payload: payload,
                })
            ).unwrap();

            setIsStarting(false);
            showToast({
                text: `The sequence ${sequence.config.name} was successfully executed`,
                variant: "success"
            });

            navigate("/space/instances");
        } catch (err: any) {
            if (currentPopover !== "ended") endOnboarding();

            setIsStarting(false);
            showToast({
                text: formatSequenceError(sequence.config.name, err),
                variant: "error"
            });
        }
    }

    return (
        <>
            <SpaceButtonsPortal>
                <ShowLogsAddSequenceButtons />
            </SpaceButtonsPortal>
            <Box outline="shadow" className="sequence-start">
                <Heading level="h2" text="Start sequence" size="headline-2" />
                <Quote wide marginTop={2} text="Args (function arguments) are passed to Sequence during start and are used as Instance parameters, affecting how the instance works."/>
                <Quote wide marginTop={2} text="AppConfig can contain additional information needed in Sequence, such as large JSON structures, access keys, passwords, etc."/>
                <div className="sequence-start__info">
                    <p className="subtitle-1 margin--t-2">Name</p>
                    <p className="body-1">{sequence.config.name}</p>
                    <p className="subtitle-1 margin--t-1">ID</p>
                    <p className="body-1">{sequence.id}<CopyText text={sequence.id}/></p>
                    <p className="subtitle-1 margin--t-1">Location</p>
                    <p className="body-1"> {sequence.location}</p>
                    {
                        // not working due to the lack of uniq seq id
                        sequence.location === "store" &&
                        <SelectStartLocation
                            spaceId={sequence.spaceId}
                            currentValue={hubToUpload}
                            onChange={setHubToUpload}
                        />
                    }
                </div>
                <div className="sequence-start__args">
                    <JSONEditor
                        data={payload}
                        onChange={handleDataChange}
                        filename={`${sequence.id}.data.json`}
                        readonly={isStarting}
                        height="30vh"
                        labelText="Specify config and parameters (opt.)"
                    />
                </div>
                <div className="sequence-start__actions border--t">
                    <Heading level="h3" text="Learn more:" size="subtitle-1" marginBottom={1} />
                    <p> How to {" "}
                        <a
                            href="https://docs.scramjet.org/platform/sequence-context/"
                            target="_blank"
                        >
                            use Sequence Args and appConfig.
                        </a>
                    </p>
                    <ButtonGroup align="right">
                        <ClickButton
                            text="Cancel"
                            variant="secondary"
                            icon={<ChevronLeft />}
                            onClick={handleCancel}
                            disabled={isStarting}
                        />
                        <ClickButton
                            text="Start"
                            icon={<PlayFill />}
                            onClick={handleSequenceStart}
                            disabled={isStarting || hubToUpload === ""}
                        />
                    </ButtonGroup>
                </div>
            </Box>
        </>
    );
};

export default SequenceStart;
