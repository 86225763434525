import { PropsWithChildren } from "react";
import Img404 from "../../assets/images/404.svg";
import Img404White from "../../assets/images/404_white.svg";
import ErrorInfo from "../../components/ErrorInfo";

type Error404Props = {
    title?: string,
    description?: string
}

const Error404: React.FC<PropsWithChildren<Error404Props>> = ({
    title = "Page not found.",
    description = "Oops...seems like the page you are looking for is not available. ",
    children }) => (
    <ErrorInfo
        standardImg={Img404}
        contrastImg={Img404White}
        title={title}
        description={description}
    >
        {children}
    </ErrorInfo>
);

export default Error404;
