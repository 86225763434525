import * as RadixPopover from "@radix-ui/react-popover";
import { PropsWithChildren } from "react";
import { useOnboarding } from "../../store/onboarding/onboardingSlice";
import { X } from "react-bootstrap-icons";
import BootstrapIcon from "../BootstrapIcon";
import "./Popover.scss";


const Popover: React.FC <PropsWithChildren<PopoverProps>> = ({
    children,
    content,
    position,
    title,
    text,
    className = "",
    display = false,
    image,
    counter
}) => {
    const { endOnboarding } = useOnboarding();

    return (
        <RadixPopover.Root defaultOpen={true} open={display} modal={true}>
            <RadixPopover.Anchor className={`popover__anchor ${className}`}>{children}</RadixPopover.Anchor>
            <RadixPopover.Portal>
                <RadixPopover.Content
                    className="popover__content"
                    side={position}
                    onPointerDownOutside={e => e.preventDefault()}
                    onFocusOutside={e => e.preventDefault()}
                    onInteractOutside={e => e.preventDefault()}
                    onClick={e => e.preventDefault()}
                >
                    <div className="popover__elements">
                        {image && typeof image === "string"
                            ? <div className="popover__icon">
                                <BootstrapIcon ico={image} size={60} />
                            </div>
                            : <div className="popover__icon">{image}</div>
                        }
                        <div className="popover__text-container">
                            <p className="popover__title subtitle-2">{title}</p>
                            <p className="body-2">{text}</p>
                        </div>
                        <div className="popover__bottom-container">
                            {content}
                            {counter && (
                                <p className="body-1">{counter}/8</p>
                            )}
                        </div>
                    </div>
                    <RadixPopover.Close aria-label="Close" className="modal__btn--icon">
                        <X size={30} fill="var(--tooltip-onboarding-text)" aria-hidden onClick={() => { endOnboarding(); }} />
                    </RadixPopover.Close>
                    <RadixPopover.Arrow className="popover__arrow"/>
                </RadixPopover.Content>
            </RadixPopover.Portal>
        </RadixPopover.Root>
    );
};

export default Popover;
