import "./SkipNav.scss";

const SkipNav = () => {
    const nav = document.getElementById("nav");

    return (
        <div className="skip-nav">
            {nav ? <a className="skip-nav__link" href="#nav">Skip to navigation</a> : null}
            <a className="skip-nav__link" href="#main-content">Skip to content</a>
            <a className="skip-nav__link" href="#footer">Skip to footer</a>
        </div>
    );
};

export default SkipNav;
