import { Link } from "react-router-dom";
import { useGetMWVersion } from "../../hooks/useVersionInfo";
import { showYear } from "../../utils/formatDate";
import "./Footer.scss";

const Footer: React.FC<FooterProps> = ({ links }) => {
    const { version } = useGetMWVersion();

    return (
        <footer id="footer" className="footer">
            <div className="footer__version">
                <a href="https://scramjet.org" className="footer__copyright" target="_blank">
                    Copyright © 2020 - {showYear(Date.now())} Scramjet.
                </a>
                <p className="footer__verison">Scramjet Cloud Platform: v{version ? version : "X.X.X"}</p>
            </div>
            <div className="footer__links">
                {links.map((link, id) => {
                    return link.target === "external" ? (
                        <a href={link.path} className="footer__link" key={id} target="_blank">
                            {link.label}
                        </a>
                    ) : (
                        <Link to={link.path} className="footer__link" key={id}>
                            {link.label}
                        </Link>
                    );
                })}
            </div>
        </footer>
    );
};

export default Footer;
