import { useState } from "react";
import { Github, TrashFill } from "react-bootstrap-icons";
import BootstrapIcon from "../../../components/BootstrapIcon";
import { ButtonGroup, ClickButton, ExternalLinkButton } from "../../../components/Buttons";
import TopSection from "../../../layout/TopSection";
import DeleteModal from "../DeleteSequence/DeleteModal";
import { useSequencesByGroupId } from "../../../store/sequences/sequencesSlice";

const SeqTopSection: React.FC<SeqTopSectionProps> = ({ sequence }) => {
    const [openModal, setOpenModal] = useState<OpenDeleteSeqModal>({ modal: null, content: "delete-sequence" });
    const { name, isStarting, config } = sequence;
    const sequencesByGroupId = useSequencesByGroupId(sequence.id);
    const repoUrl = typeof config.repository === "string" ? config.repository : config.repository?.url;
    const chooseModalToOpen = () => {
        if (sequencesByGroupId.length > 1) {
            setOpenModal({ modal: "dialog", content: "choose-seq-to-delete" });
            return;
        }
        if (sequence.instances.length > 0) {
            setOpenModal({ modal: "error", content: "deny-sequence-delete" });
            return;
        }
        setOpenModal({ modal: "dialog", content: "delete-sequence" });
    };

    return (
        <TopSection
            variant="subtitle"
            marginTop={2}
            marginBottom={2}
            title={name ? name : config.name}
            icon={<BootstrapIcon ico="sequence" size={25} />}
        >
            <ButtonGroup>
                {repoUrl && (
                    <ExternalLinkButton
                        variant="secondary"
                        icon={<Github />}
                        href={repoUrl}
                        target="_blank"
                        text="Source code"
                    />
                )}
                <ClickButton
                    variant="danger"
                    icon={<TrashFill />}
                    text="Delete"
                    onClick={chooseModalToOpen}
                    disabled={isStarting}
                />
            </ButtonGroup>
            <DeleteModal
                openModal={openModal}
                setOpenModal={setOpenModal}
                sequences={sequencesByGroupId}
            />
        </TopSection>
    );
};

export default SeqTopSection;
