import { Unit, displayLongDateIfYearsDiffers } from "../../utils/formatDate";
import { formatDistanceToNowStrict, intervalToDuration } from "date-fns";

const EndOfTrialDateInfo: React.FC<EndOfTrialDateInfoProps> = ({ trialEnd, today }) => {
    const calcDistance = (timeUnit: Unit) => formatDistanceToNowStrict(new Date(trialEnd * 1000), { unit: timeUnit });
    const duration = intervalToDuration({
        start: new Date(trialEnd * 1000),
        end: new Date(today),
    });

    if (duration.years && duration.years > 0) {
        return (
            <div className="body-1 trial__trial-info">
                Around <em>{calcDistance("month")}</em> left, ends{" "}
                {displayLongDateIfYearsDiffers(trialEnd * 1000, today, trialEnd)}
            </div>
        );
    } else if (duration.months && duration.months > 0) {
        return (
            <div className="body-1 trial__trial-info">
                Around <em>{calcDistance("month")}</em> left, ends{" "}
                {displayLongDateIfYearsDiffers(trialEnd * 1000, today, trialEnd)}
            </div>
        );
    } else if (duration.days && duration.days > 0) {
        return (
            <div className="body-1 trial__trial-info">
                <em>{calcDistance("day")}</em> left, ends{" "}
                {displayLongDateIfYearsDiffers(trialEnd * 1000, today, trialEnd)}
            </div>
        );
    } else if (duration.hours && duration.hours > 0) {
        return (
            <div className="body-1 trial__trial-info">
                <em>{calcDistance("hour")}</em> left, upgrade your plan by&nbsp;adding payment method
            </div>
        );
    }
    return (
        <div className="body-1 trial__trial-info">
            <em>{calcDistance("minute")}</em> left, upgrade now by&nbsp;adding payment method
        </div>
    );
};

export default EndOfTrialDateInfo;
