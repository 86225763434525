import { useEffect } from "react";
import BootstrapIcon from "../../components/BootstrapIcon";
import Container from "../../components/Container";
import Box from "../../components/Box";
import VerifyEmail from "./VerifyEmail";
import ConfigModals from "../../data/modals/ModalsConfiguration.json";
import ErrorLayout from "../../layout/Error";
import VerifyEmailContent from "./VerifyEmailContent";
import TopBarError from "./TopBarError";
import "./ErrorPage.scss";

const ErrorPage: React.FC<ErrorPageProps> = ({ message, userId, email }) => {
    const errorMsg = Object.entries(ConfigModals).find(([key]) => key === message);

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);

        if (queryParams.has("error")) history.replaceState(null, "", "/");
    }, []);

    return (
        <>
            <TopBarError email={email ? email : "user"} />
            <ErrorLayout>
                <Container className="error__container">
                    <Box outline="shadow" className="error__box">
                        {errorMsg ? (
                            <>
                                <BootstrapIcon ico={errorMsg[1].image} size={70} />
                                <h1 className="headline-1 error__title">{errorMsg[1].title}</h1>
                                {errorMsg[0] === "verify-email" ? (
                                    <VerifyEmailContent email={email ? email : "user"} />
                                ) : null}
                                <p className="body-2 error__text">{errorMsg[1].text}</p>
                                {errorMsg[0] === "verify-email" && userId && <VerifyEmail userId={userId} />}
                            </>
                        ) : (
                            <>
                                <h1 className="headline-1 error__title">{message}</h1>
                            </>
                        )}
                    </Box>
                </Container>
            </ErrorLayout>
        </>
    );
};

export default ErrorPage;
