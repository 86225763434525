import { useState } from "react";
import { X } from "react-bootstrap-icons";
import BootstrapIcon from "../../../components/BootstrapIcon";
import { ButtonGroup, ClickButton, InternalLinkButton } from "../../../components/Buttons";
import { getMiddlewateClient } from "../../../components/MiddlewareClientProvider";
import { DialogModal } from "../../../components/Modal";
import { TabMenuWithRouter } from "../../../components/TabMenu";
import TopSection from "../../../layout/TopSection";
import HubBasicInfo from "./HubBasicInfo";
import HubInstancesList from "./HubInstancesList";
import HubSequenceList from "./HubSequenceList";
import Tooltip from "../../../components/Tooltip";
import { useToast } from "../../../components/Toast/Toasts";
import { useHubContext } from "../../../routers/RouteLoader";
import SpaceButtonsPortal from "../../SpacesPage/components/SpaceButtonsPortal";
import { ShowLogsAddSequenceButtons } from "../../SpacesPage/components/SpaceButtons";
import TabLazy from "../../../components/TabMenu/TabLazy";

const HubDetails: React.FC = () => {
    const { hub } = useHubContext();
    const showToast = useToast();
    const [disconnectPopupOpen, setDisconnectPopupOpen] = useState(false);
    const [deletePopupOpen, setDeletePopupOpen] = useState(false);
    const [kill] = useState(false);
    const [disconnectStatus, setDisconnectStatus] = useState<"idle" | "loading" | "success" | "error">("idle");
    const handleDisconnectButton = () => setDisconnectPopupOpen(true);
    const handleDeleteButton = () => setDeletePopupOpen(true);
    const handleCloseDisconnectPopup = () => setDisconnectPopupOpen(false);
    const handleCloseDeletePopup = () => setDeletePopupOpen(false);
    const canDisconnectMessages = ["Connection is not active.", "Already disconnecting"];
    const canDisconnectConditions = [hub?.isConnectionActive, ["idle", "success", "error"].includes(disconnectStatus)];
    const canDisconnect = canDisconnectConditions.reduce((prev, next) => prev && next, true);
    const canDisconnectReason = [
        "Cannot disconnect.",
        ...canDisconnectConditions
            .map((_condition, index) => canDisconnectMessages[index])
            .filter((_message, index) => !canDisconnectConditions[index]),
    ].join(" ");
    const handleDisconnect = async () => {
        const managerClient = getMiddlewateClient().getManagerClient(hub.spaceId);

        setDisconnectStatus("loading");

        try {
            if (kill) {
                await Promise.all(
                    hub.instances.map(instance => {
                        return managerClient.getHostClient(hub.id).getInstanceClient(instance).kill();
                    })
                );
            }

            await getMiddlewateClient().getManagerClient(hub.spaceId).disconnectHubs({ id: hub.id });

            setDisconnectStatus("success");
            showToast({ variant: "success", text: `Hub ${hub.id} successfully disconnected` });
        } catch (e: any) {
            setDisconnectStatus("error");
            showToast({ variant: "error", text: `There was an error while disconnecting hub ${hub.id}.` + e?.message ? e.message : "" });
        }

        setDisconnectPopupOpen(false);
    };
    const handleDelete = async () => {
        try {

            await getMiddlewateClient().getManagerClient(hub.spaceId).deleteHub(hub.id, false);

            showToast({ variant: "success", text: `Hub ${hub.id} successfully deleted` });
        } catch (e: any) {
            showToast({ variant: "error", text: `There was an error while deleting hub ${hub.id}.` + e?.message ? e.message : "" });
        }

        setDeletePopupOpen(false);
    };

    return (
        <>
            <SpaceButtonsPortal>
                <ShowLogsAddSequenceButtons />
            </SpaceButtonsPortal>
            <TopSection
                variant="subtitle"
                marginTop={2}
                marginBottom={2}
                title={hub.id}
                icon={<BootstrapIcon ico="hub" size={25} fill="icon-primary" />}
            >

                <div className="space__above-box">
                    {hub.selfHosted && (
                        <ButtonGroup className="space__buttons">
                            {canDisconnect ? (
                                <>
                                    <ClickButton
                                        variant="danger"
                                        text={"Disconnect"}
                                        icon={<X />}
                                        onClick={handleDisconnectButton}
                                        disabled={!canDisconnect}
                                    />
                                    <Tooltip text="Disconnect hub before deleting" position="right">
                                        <div>
                                            <ClickButton
                                                variant="danger"
                                                text={"Delete"}
                                                icon={<X />}
                                                onClick={handleDeleteButton}
                                                disabled={canDisconnect}
                                            />
                                        </div>
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    <Tooltip text={canDisconnectReason} position="left">
                                        <div>
                                            <ClickButton
                                                variant="danger"
                                                text={"Disconnect"}
                                                icon={<X />}
                                                onClick={handleDisconnectButton}
                                                disabled={!canDisconnect}
                                            />
                                        </div>
                                    </Tooltip>
                                    <ClickButton
                                        variant="danger"
                                        text={"Delete"}
                                        icon={<X />}
                                        onClick={handleDeleteButton}
                                        disabled={canDisconnect}
                                    />
                                </>
                            )}
                        </ButtonGroup>
                    )}
                </div>
            </TopSection>
            <TabMenuWithRouter
                orientation="horizontal"
                tabList={["Basic info", "List of Instances", "List of Sequences"]}
                tabPanels={[
                    <HubBasicInfo hub={hub} />,
                    <TabLazy index={1}><HubInstancesList hubId={hub.id} /></TabLazy>,
                    <TabLazy index={2}><HubSequenceList hubId={hub.id} /></TabLazy>,
                ]}
                flex
            />
            <DialogModal
                title="Disconnect Hub?"
                variant="dialog"
                onClose={handleCloseDisconnectPopup}
                open={disconnectPopupOpen}
                alignContent="left"
                buttons={
                    <>
                        <ClickButton
                            text="Cancel"
                            variant="secondary"
                            onClick={handleCloseDisconnectPopup}
                            disabled={!canDisconnect}
                        />
                        <ClickButton
                            text="Disconnect"
                            variant="danger"
                            onClick={handleDisconnect}
                            disabled={!canDisconnect}
                        />
                    </>
                }
            >
                Self Hosted Hub will be disconnected from the Cloud Platform
                <br />
                Visit <a target="_blank" href="https://docs.scramjet.org/platform/self-hosted-hub/">Self Hosted Hub</a> docs for more info on disconnecting a Hub
                <br />
                <br />
            </DialogModal>
            <DialogModal
                title="Delete Hub?"
                variant="dialog"
                onClose={handleCloseDeletePopup}
                open={deletePopupOpen}
                alignContent="left"
                buttons={
                    <>
                        <ClickButton
                            text="Cancel"
                            variant="secondary"
                            onClick={handleCloseDeletePopup}
                            disabled={canDisconnect}
                        />
                        <InternalLinkButton
                            text="Delete"
                            variant="danger"
                            onClick={handleDelete}
                            disabled={canDisconnect}
                            to={"/space/hubs"}
                        />
                    </>
                }
            >
                Self Hosted Hub will be permanently deleted from the Cloud Platform
                <br />
                Visit <a target="_blank" href="https://docs.scramjet.org/platform/self-hosted-hub/">Self Hosted Hub</a> docs for more info on Self Hosted Hub
                <br />
                <br />
            </DialogModal>
        </>
    );
};

export default HubDetails;
