import { PropsWithChildren, useEffect, useState } from "react";
import { createPortal } from "react-dom";

const SpaceButtonsPortal: React.FC<PropsWithChildren> = ({ children }) => {
    const [spaceButtonsContainer, setSpaceButtonsContainer] = useState<HTMLElement | null>(null);

    useEffect(() => {
        setSpaceButtonsContainer(document.getElementById("spaceButtonsContainer"));
    }, []);

    if (!spaceButtonsContainer) {
        return null;
    }

    return createPortal(children, spaceButtonsContainer);
};

export default SpaceButtonsPortal;


