import SkipNav from "../../../layout/SkipNav";
import AccessibleImg from "../../../layout/AccessibleImg";
import { memo } from "react";
import ScramjetLogoWhite from "../../../assets/images/logo-white.svg";
import ScramjetLogo from "../../../assets/images/logo.svg";
import UserAvatarError from "../UserAvatarError";
import "./TopBarError.scss";

const TopBarError : React.FC<WelcomePropos> = ({ email }) => {

    return (
        <header className="top-bar-error">
            <div className="top-bar-error__container">
                <div className="top-bar-error__left">
                    <AccessibleImg standardImg={ScramjetLogo} contrastImg={ScramjetLogoWhite} className="top-bar-error__logo" alt="Scramjet Logo" />
                </div>
                <div className="top-bar-error__center">
                    <SkipNav />
                </div>
                <div className="top-bar-error__right">
                    <UserAvatarError email={email ? email : "User" } />
                </div>
            </div>
        </header>
    );
};

export default memo(TopBarError);
