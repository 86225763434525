import { DoorOpenFill, PersonCircle, BoxArrowRight } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { sendEvent } from "../../utils/gtmEvents";
import { useAuthentication } from "../AuthenticationProvider";
import { memo } from "react";
import Tooltip from "../Tooltip";
import "./UserAvatar.scss";

const UserAvatar = () => {
    const { logout, user } = useAuthentication();
    const navigate = useNavigate();
    const handleLogoutClick = async () => {
        logout({ logoutParams: { returnTo: window.location.origin } });
    };
    const handleProfileClick = async () => {
        await sendEvent("console_profile_click");
        navigate("/profile");
    };

    return (
        <div className="top-panel">
            <Tooltip text="Profile" aria-label="Profile" position="below">
                <button className="user-avatar" onClick={handleProfileClick}>
                    <PersonCircle />
                    <div className="user-avatar__text">
                        <div className="user-avatar__name">{user?.username}</div>
                        <div className="user-avatar__role">Root</div>
                    </div>
                </button>
            </Tooltip>

            <Tooltip text="Logout" aria-label="Logout" position="below">
                <button onClick={handleLogoutClick} className="logout">
                    <DoorOpenFill className="logout__icon" />
                </button>
            </Tooltip>
        </div>
    );
};

export default memo(UserAvatar);
