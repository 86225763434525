import EventEmitter from "events";
import InstanceMap from "../../store/instances/InstanceMap";
import SequenceMap from "../../store/sequences/SequenceMap";
import TopicMap from "../../store/topics/TopicMap";
import HubMap from "../../store/hubs/HubMap";
import { getMiddlewateClient } from "../MiddlewareClientProvider";

export default class SourceFetch extends EventEmitter implements ISyncStoreSource {
    intervalId: undefined | ReturnType<typeof setTimeout>;

    constructor() {
        super();
        this.init();
    }

    init() {
        this.intervalId = setInterval(() => {
            (async () => {
                try {
                    const middlewareClient = getMiddlewateClient();
                    const spaces = (await middlewareClient.getManagers()) as Space[];
                    const hubs: Hub[] = [];
                    const instances: Instance[] = [];
                    const sequences: Sequence[] = [];
                    const topics: Topic[] = [];
                    const entitiesList: Entities[] = [];

                    await Promise.all(
                        spaces.map(async space => {
                            const entities: Entities = {
                                spaceId: space.id,
                                hubs: [],
                                instances: [],
                                sequences: [],
                                topics: [],
                            };
                            const spaceClient = middlewareClient.getManagerClient(space.id);
                            const spaceHubs = await spaceClient.getHosts();
                            const spaceSequences = (await spaceClient.getAllSequences()).filter(
                                SequenceMap.filterInvalidSequences
                            );
                            const spaceTopics = await spaceClient.getTopics();
                            const mapHub = (entity: any) => HubMap.toDTO(entity, space.id);
                            const seqMap = (entity: any) => SequenceMap.toDTO(entity, space.id);
                            const topMap = (entity: any) => TopicMap.toDTO(entity, space.id);

                            hubs.push(...spaceHubs.map(mapHub));
                            sequences.push(...spaceSequences.map(seqMap));
                            topics.push(...spaceTopics.map(topMap));

                            const update = async (hub: (typeof spaceHubs)[number]) => {
                                try {
                                    const hubClient = spaceClient.getHostClient(hub.id);
                                    const mapInstance = (entity: any) => InstanceMap.toDTO(entity, hub.id, space.id);

                                    instances.push(...(await hubClient.listInstances()).map(mapInstance));
                                    // sequences.push(...(await hubClient.listSequences()).map(mapSequence));
                                } catch (e) {
                                    console.warn(`Sync for hub (${hub.id}) was not possible due to error`, e);
                                }
                            };

                            await Promise.all(spaceHubs.map(update));

                            entities.hubs = hubs.map(hub => hub.id);
                            entities.instances = instances.map(instance => instance.id);
                            entities.sequences = sequences.map(sequence => sequence.id);
                            entities.topics = topics.map(topic => topic.name);
                            entitiesList.push(entities);
                        })
                    );

                    this.emit("entities", entitiesList);
                    this.emit("instances", instances);
                    this.emit("sequences", sequences);
                    this.emit("topics", topics);
                    this.emit("hubs", hubs);
                } catch (e) {
                    console.warn("Sync was not possible due to error", e);
                }
            })();
        }, 10 * 1000);
    }

    onEntitiesUpdate(callback: SyncStoreCallabck<Entities>) {
        this.on("entities", callback);
    }

    onInstancesUpdate(callback: SyncStoreCallabck<Instance>) {
        this.on("instances", callback);
    }

    onSequencesUpdate(callback: SyncStoreCallabck<Sequence>) {
        this.on("sequences", callback);
    }

    onTopicsUpdate(callback: SyncStoreCallabck<Topic>) {
        this.on("topics", callback);
    }

    onHubsUpdate(callback: SyncStoreCallabck<Hub>) {
        this.on("hubs", callback);
    }

    destroy() {
        clearInterval(this.intervalId);
    }
}
