import { Tab, TabList } from "@reach/tabs";
import wdslug from "../../utils/wdslug";

const tabNav = (id: number, name: string) => {
    let slug = wdslug(name);

    return (
        <Tab key={id} className="tab-menu__item" wd-data={slug}>
            {name}
        </Tab>
    );
};
const tabButton = (name: string, indicatorCount: (JSX.Element | string) | undefined) => {
    let slug = wdslug(name);

    return (
        <Tab key={name} className="tab-menu__item" wd-data={slug}>
            {<span className="tab-menu__item-text">{name}</span>}
            {indicatorCount && <span className="tab-menu__indicator">{indicatorCount}</span>}
        </Tab>
    );
};

type TabTitleBarProps = {tabList: string[], indicators: (JSX.Element | string)[], variant: string};

const TabTitleBar: React.FC<TabTitleBarProps> = ({ tabList, indicators, variant }) => {
    if (variant === "nav")
        return <TabList className="tab-menu__list">{tabList.map((name, index) => tabNav(index, name))}</TabList>;

    return <TabList className="tab-menu__list">{tabList.map((name, index) => tabButton(name, indicators[index]))}</TabList>;
};

export default TabTitleBar;
