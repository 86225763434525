import { useEffect, useState } from "react";
import { ClientUtils } from "@scramjet/client-utils";
import { middlewareUrl } from "../components/MiddlewareClientProvider";
import { sendGenericErrorEvent } from "../utils/gtmEvents";

export function useGetMWVersion() {
    const [version, setVersion] = useState<Version | null>(null);

    useEffect(() => {
        (async () => {
            try {
                const clientUtils = new ClientUtils(middlewareUrl);
                const getVersionInfo = await clientUtils.get<Version>("/api/v1/version");

                setVersion(getVersionInfo);
            } catch (e) {
                console.error("Mw version info cannot be retrieved", e);
                sendGenericErrorEvent("middleware-version", e);
            }
        })();
    }, []);

    return { ...version };
}
