import "./Progress.scss";
import { CheckCircleFill, RecordCircle, Circle } from "react-bootstrap-icons";

const AccountSetupProgress : React.FC <ProgressProps> = ({ currentStepId }) => {
    const steps : ProgressStep[] = [
        { id: 1, name: "Create Account", status: null },
        { id: 2, name: "Verify E-mail", status: null },
        { id: 3, name: "Start Trial", status: null },
    ];

    steps.forEach((step) => {
        if (step.id < currentStepId) {
            step.status = "filled";
        } else if (step.id === currentStepId) {
            step.status = "active";
        } else {
            step.status = "inactive";
        }
    });

    return (
        <div className="account-setup-progress">
            {steps.map((step) => (
                <>
                    <div
                        key={step.id}
                        className={`step ${step.status}`}
                    >
                        <div className={`account-setup-progress__element account-setup-progress__element__${step.id}`}>
                            {step.id > 1 && <div className={`account-setup-progress__line account-setup-progress__line-b__${step.status}`} />}
                            {step.status === "filled" && <CheckCircleFill size={20} className={`account-setup-progress__${step.status}`}/>}
                            {step.status === "active" && <RecordCircle size={20} className={`account-setup-progress__${step.status}`}/>}
                            {step.status === "inactive" && <Circle size={20} className={`account-setup-progress__${step.status}`}/>}
                            {step.id < steps.length && <div className={`account-setup-progress__line account-setup-progress__line__${step.status}`} />}
                        </div>
                        <p className={`account-setup-progress__name account-setup-progress__name__${step.status} body-2`}>{step.name}</p>
                    </div>

                </>
            ))}
        </div>
    );
};

export default AccountSetupProgress;
