import { useEntitiesBySpaceId } from "../../../store/entities/entitiesSlice";

type IndicatorProps = {
    spaceId: SpaceId,
    entityName: "hubs" | "sequences" | "instances" | "topics";
}

const Indicator: React.FC<IndicatorProps> = ({ spaceId, entityName }) => {
    const { entities } = useEntitiesBySpaceId(spaceId);

    if (!entities) return <></>;

    return (<>{entities[entityName].length}</>);
};

export default Indicator;
