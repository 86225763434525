import { PropsWithChildren } from "react";
import BootstrapIcon from "../../../components/BootstrapIcon";
import BasicModal from "../BasicModal";
import "./PromoModal.scss";
import Heading from "../../Heading";
import AccessibleImg from "../../../layout/AccessibleImg";

const PromoModal: React.FC<PropsWithChildren<PromoModalProps>> =
({
    title,
    text,
    legal,
    children,
    alignContent = "left",
    image,
    banner,
    promoCode,
    buttons,
    onClose,
    hiddenX = false,
    open = false,
    ...rest
}) => {
    return (
        <BasicModal
            open={open}
            image={image}
            variant="promo"
            alignContent={alignContent}
            onClose={onClose}
            buttons={buttons}
            {...rest}
        >
            <div className="modal__content">
                <Heading marginBottom={2} text={title} level="h2" size="headline-3"/>
                {image && typeof image === "string"
                    ? <div className="modal__icon">
                        <BootstrapIcon ico={image} size={60} />
                    </div>
                    : <div className="modal__icon">
                        {image}
                    </div>
                }
                {promoCode && <div className="modal__promo-code"><p>{promoCode}</p></div>}
                {text && (
                    <>
                        {text.map((item, key) => (
                            <p className="body-2 margin--b-2" key={key}>{item}</p>
                        ))}
                    </>
                )}
                {children}
                {buttons}
                {legal && <p className="modal__legal">{legal}</p>}
            </div>
            {banner && <AccessibleImg className="modal__image" standardImg={banner} contrastImg={banner} alt="" />}
        </BasicModal>
    );
};

export default PromoModal;
