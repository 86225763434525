import { useEffect, useState } from "react";
import { ClientUtils } from "@scramjet/client-utils";
import { middlewareUrl } from "../components/MiddlewareClientProvider";
import { sendGenericErrorEvent } from "../utils/gtmEvents";


/**
 * Removes inactive prices from products.
 * Filters out products without metadata public: true, variant and without prices.
 * @date 5/8/2023 - 3:08:08 PM
 *
 * @param {Product[]} products list of products from api
 * @returns {Product[]} filtered products
 */
function filterInvalidProductsAndPrices(products: Product[]): Product[] {
    const filteredProducts: Product[] = [];

    products.forEach(product => {
        if (!product.active) return true;
        if (product?.metadata?.public !== "true") return true;
        if (!product?.metadata?.variant) return true;

        const filteredPrices = product.prices.filter(price => price.active);

        if (!filteredPrices.length) return true;

        filteredProducts.push({
            ...product,
            prices: filteredPrices
        });

        return true;
    });

    return filteredProducts;
}


/**
 * Gets default price from provided product
 * @date 5/31/2023 - 8:08:02 PM
 *
 * @param {Product} product with prices
 * @returns {Price} default price of the product
 */
export const getDefaultPrice = (product: Product): Price | null => {
    return product.prices.filter(price => price.id === product.default_price)[0] || null;
};

export function useProducts() {
    const [status, setStatus] = useState<"idle" | "loading" | "success" | "error">("idle");
    const [nativeProduct, setNativeProduct] = useState<Product | null>(null);
    const [shhProduct, setShhProduct] = useState<Product | null>(null);

    useEffect(() => {
        if (!["idle", "error"].includes(status)) return;

        (async () => {
            try {
                setStatus("loading");

                const clientUtils = new ClientUtils(middlewareUrl);
                const products = filterInvalidProductsAndPrices(await clientUtils.get<Product[]>("/api/v1/products"));

                products.forEach(product => {
                    if (product.metadata.variant === "native") {
                        setNativeProduct(product);
                        return true;
                    }

                    if (product.metadata.variant === "self-hosted-hub") {
                        setShhProduct(product);
                        return true;
                    }

                    return true;
                });

                setStatus("success");
            } catch (e) {
                console.error("Products cannot be retrieved", e);
                sendGenericErrorEvent("products-retrieve", e);
                setStatus("error");
            }
        })();
    }, []);

    return { nativeProduct, shhProduct, status, getDefaultPrice };
}
