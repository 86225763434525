import "./AccessibleImg.scss";

const AccessibleImg: React.FC<AccessibleImgProps> = ({ className = "", standardImg, contrastImg, alt = "" }) => {
    return (
        <>
            <img
                className={`${className} accessibleImage--standard`}
                src={standardImg}
                alt={alt}
            />
            <img
                className={`${className} accessibleImage--contrast`}
                src={contrastImg}
                alt={alt}
            />
        </>
    );
};

export default AccessibleImg;
