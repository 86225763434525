import BootstrapIcon from "../../../components/BootstrapIcon";
import Box, { BoxList } from "../../../components/Box";
import Heading from "../../../components/Heading";
import { Download } from "react-bootstrap-icons";
import Label from "../../../components/Label";

const samplesData = [
    { lang:"JavaScript", text: "Hello World", url: "https://assets.scramjet.org/scp-store/hello-world-js.tar.gz" },
    { lang:"TypeScript", text: "Hello World", url: "https://assets.scramjet.org/scp-store/hello-world-ts.tar.gz" },
    { lang:"Python", text: "Hello World", url: "https://assets.scramjet.org/scp-store/hello-world-py.tar.gz" }
];
const UseSamples: React.FC = () => {
    return (
        <div className="sequence-add__samples">
            <p className="body-2">
                Don’t have your own sequence yet? Download our ready-to-use sample
            </p>
            <BoxList className="use-samples" marginTop={4} direction="row" gap={4}>
                {
                    samplesData.map((sample, key) =>
                        <Box key={key} className="sample" variant="download" outline="solid" linkTo={sample.url}>
                            <Label className="sample__label" scale="small" position="t-left" text={sample.lang}/>
                            <BootstrapIcon ico="sequence" size={28}/>
                            <p className="body-1 margin--r-2 margin--l-1">{sample.text}</p>
                            <Download size={18} fill="var(--icon-primary)"/>
                        </Box>)
                }
            </BoxList>
        </div>
    );
};

export default UseSamples;
