import { middlewareHybridUrl } from "../../../components/MiddlewareClientProvider";

export function getPlatformConfig(spaceId: string | undefined, accessKey: string) {
    return {
        apiKey: accessKey,
        api: `${middlewareHybridUrl}`,
        space: spaceId ? `${spaceId.replace(/-manager/g, "")}:${spaceId}` : "ORG_ID:SPACE_ID",
    };
}

export function getHubConfigFile(platform: PlatformConfig) {
    return {
        host: {
            hostname: "0.0.0.0",
            port: 9002,
            instancesServerPort: 9003,
            id: "SELF_HOSTED_STH-1",
        },
        description: "Some description",
        tags: ["tag-1", "tag-2"],
        timings: {
            instanceLifetimeExtensionDelay: 10000,
        },
        platform,
    };
}
