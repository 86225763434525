export default class HubMap {
    static toDTO(source: ConnectedSTHInfo, spaceId: SpaceId): Hub {
        return {
            id: source.id,
            healthy: source.healthy,
            isConnectionActive: source.isConnectionActive,
            selfHosted: source.selfHosted,
            description: source.description,
            tags: source.tags,
            spaceId,
            // TODO: update types? those proeprties exist in the response but not in the response type
            sequences: (source as ConnectedSTHInfo & { sequences: string[] })?.sequences,
            instances: (source as ConnectedSTHInfo & { instances: string[] })?.instances,
            info: {
                created: "",
                lastConnected: "",
                lastDisconnected: "",
                remoteAddress: "",
                ...source.info,
            },
        };
    }
}
