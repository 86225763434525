import { EyeFill, PlayFill, Server } from "react-bootstrap-icons";
import { ButtonGroup } from "../../components/Buttons";
import ButtonCircle from "../../components/ButtonCircle";
import Tag from "../../components/Tags";
import Tooltip from "../../components/Tooltip";
import CopyText from "../../components/CopyText";

const SequencesTable: React.FC<SequencesTableProps> = ({
    sequence, index
}) => {
    const keywordsNum = 3;
    const { id, location, instances, config } = sequence;
    const { engines } = config;
    const name = sequence?.name ? sequence?.name : config?.name;

    return <tr key={id} data-qa={`sequences/${index}`}>
        <td className="table__name">
            <p className="subtitle-2">{name}</p>
            <p>{id}<CopyText text={id}/></p>
            {config.description && <i>{config.description}</i>}
            {config.keywords && (
                <Tag margin="margin--t-05" tags={config.keywords?.slice(0, keywordsNum)} />
            )}
        </td>
        <td className="table__image">
            {Object.keys(engines).length !== 0 ? (
                <p>
                    {Object.keys(engines)}: <em>{Object.values(engines)}</em>
                </p>
            ) : (
                <em>not defined</em>
            )}
        </td>
        <td>
            {location}
        </td>
        <td className="table__instances">
            {location !== "store" ? (
                <>
                    <Server width="2rem" height="2rem" />
                    <span>({instances ? instances.length : "0"})</span>
                </>
            ) : (
                <Tooltip text={<>Not applicable.<br/>Instances can be<br/>runned on hubs only.</>} position="right">
                    <span>N/A</span>
                </Tooltip>
            )}
        </td>
        <td>
            <ButtonGroup>
                <Tooltip text="Start" position="above">
                    <div>
                        <ButtonCircle to={`/space/sequences/${id}/start`}>
                            <PlayFill className="icon--error" size="2.2rem" />
                        </ButtonCircle>
                    </div>
                </Tooltip>
                <Tooltip text="Details" position="above">
                    <div>
                        <ButtonCircle to={`/space/sequences/${id}/basic-info`}>
                            <EyeFill className="icon--error" size="2rem" />
                        </ButtonCircle>
                    </div>
                </Tooltip>
            </ButtonGroup>
        </td>
    </tr>;
};

export default SequencesTable;
