import cls from "classnames";
import "./Table.scss";

const Table: React.FC<TableProps> = ({ rows, variant = "default", children, marginTop, marginBottom, forceBreak }) => {
    return (
        <div className={`table__container margin--t-${marginTop} margin--b-${marginBottom}`}>
            <table className={cls(`table table--${variant}`, { "table--force-break": forceBreak })}>
                {rows?.length && (
                    <thead className={"table__head"}>
                        <tr>
                            {rows.map((rowName, id) => {
                                return <th key={id}>{rowName}</th>;
                            })}
                        </tr>
                    </thead>
                )}
                <tbody className="table__body">
                    {Array.isArray(children) && children.length !== 0 ? (
                        children
                    ) : (
                        <tr>
                            <td colSpan={rows?.length || 1}>There's nothing here, yet.</td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    );
};

export default Table;
