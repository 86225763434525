import { SequenceConfig } from "@scramjet/types";

export default class StoreItemsMap {
    static toDTO(source: SequenceConfig, spaceId: SpaceId, isStarting: boolean = false): StoreItem {
        return {
            id: source.id,
            location: "store",
            spaceId: spaceId,
            config: source,
            instances: [],
            isStarting,
        };
    }
}
