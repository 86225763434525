import { NavLink } from "react-router-dom";
import { ArrowRight } from "react-bootstrap-icons";
import BootstrapIcon from "../../components/BootstrapIcon";
import { MainNavLink } from "./MainNav";

const MainNavLinkTo: React.FC<
    Omit<MainNavLink, "href" | "onClick" | "disabled"> & { className: string; to: string }
> = ({ className, label, icon, to }) => (
    <NavLink
        className={className}
        to={to}
        aria-label={label}
        //exact={false}
        // TODO: check if everything is ok without this prop.
        // It was gone after upgrade to react 18
    >
        <div className="attention-animation" />
        <BootstrapIcon ico={icon} />
        <span className="nav__label">{label}</span>
        <ArrowRight className="arrow-icon"/>
    </NavLink>
);

export default MainNavLinkTo;
