import { PayloadAction, createAsyncThunk, createEntityAdapter, createSelector, createSlice } from "@reduxjs/toolkit";

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "..";
import { useSpaces } from "../spaces/spacesSlice";
import { getMiddlewateClient } from "../../components/MiddlewareClientProvider";
import TopicMap from "./TopicMap";
import fastDeepEqual from "fast-deep-equal";

const sliceName = "topics";
const topicsAdapter = createEntityAdapter<Topic>({ selectId: model => model.name });
const initialState: TopicsState = topicsAdapter.getInitialState({
    status: {},
});
const selectSelf = (state: RootState): TopicsState => state[sliceName];

export const { selectAll: selectTopics, selectById: selectTopicById } =
    topicsAdapter.getSelectors<RootState>(selectSelf);
export const selectTopicsStatus = (spaceId: SpaceId) =>
    createSelector(selectSelf, state => {
        return state.status[spaceId];
    });
export const selectTopicsBySpaceId = (spaceId: SpaceId) =>
    createSelector(selectTopics, topics => topics.filter(topic => topic.spaceId === spaceId));

export const fetchSpaceTopics = createAsyncThunk<Topic[], { spaceId: SpaceId; refetch?: boolean }>(
    `${sliceName}/fetchBySpaceId`,
    async ({ spaceId }) => {
        const spaceClient = getMiddlewateClient().getManagerClient(spaceId);
        const spaceTopics = (await spaceClient.getTopics()).map(topic => TopicMap.toDTO(topic, spaceId));

        return spaceTopics;
    },
    {
        // Cache between page loads and until refetch
        condition: ({ spaceId, refetch }, { getState }) => {
            const status = selectTopicsStatus(spaceId)(getState() as RootState);

            return !!spaceId && (refetch || (status !== "fulfilled" && status !== "pending"));
        },
    }
);

export const topicsSlice = createSlice({
    name: sliceName,
    initialState,
    reducers: {
        syncUpdate: (state, action: PayloadAction<Topic[]>) => {
            const topicsForRemoval = state.ids.filter(
                topicName => !action.payload.find(search => search.name === topicName)
            );

            topicsAdapter.removeMany(state, topicsForRemoval);
            topicsAdapter.upsertMany(state, action.payload);
        },
    },
    extraReducers: builder => {
        builder
            .addCase(fetchSpaceTopics.pending, (state, action) => {
                state.status[action.meta.arg.spaceId] = "pending";
            })
            .addCase(fetchSpaceTopics.fulfilled, (state, action) => {
                state.status[action.meta.arg.spaceId] = "fulfilled";
                topicsAdapter.upsertMany(state, action.payload);
            })
            .addCase(fetchSpaceTopics.rejected, (state, action) => {
                state.status[action.meta.arg.spaceId] = "rejected";
            });
    },
});

export const useTopicsBySpaceId = (spaceId: SpaceId) => {
    const { spaces } = useSpaces();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchSpaceTopics({ spaceId }));
    }, [spaces]);

    const status = useSelector(selectTopicsStatus(spaceId), fastDeepEqual);
    const topics = useSelector(selectTopicsBySpaceId(spaceId), fastDeepEqual);

    return { status, topics };
};

export default topicsSlice.reducer;
