import React from "react";
import ContentLoader from "react-content-loader";

const TableLoader: React.FC = (props) => (
    <ContentLoader
        speed={2}
        width={1000}
        height={170}
        viewBox="0 0 1000 170"
        backgroundColor="#e6e6e6"
        foregroundColor="#f2f2f2"
        {...props}
    >
        <rect x="0" y="0" rx="0" ry="0" width="1000" height="16" />
        <rect x="0" y="16" rx="0" ry="0" width="30" height="22" />
        <rect x="105" y="16" rx="0" ry="0" width="283" height="22" />
        <rect x="468" y="16" rx="0" ry="0" width="84" height="22" />
        <rect x="623" y="16" rx="0" ry="0" width="81" height="22" />
        <rect x="781" y="16" rx="0" ry="0" width="75" height="22" />
        <rect x="929" y="16" rx="0" ry="0" width="71" height="22" />
        <rect x="0" y="38" rx="0" ry="0" width="1000" height="16" />
        <rect x="0" y="42" rx="0" ry="0" width="6" height="120" />
        <rect x="994" y="48" rx="0" ry="0" width="6" height="120" />
        <rect x="0" y="162" rx="0" ry="0" width="1000" height="7" />
        <circle cx="881" cy="106" r="22" />
        <circle cx="938" cy="106" r="22" />
        <rect x="727" y="94" rx="0" ry="0" width="35" height="22" />
        <rect x="555" y="94" rx="0" ry="0" width="71" height="22" />
        <rect x="391" y="94" rx="0" ry="0" width="76" height="22" />
        <rect x="30" y="62" rx="0" ry="0" width="115" height="18" />
        <rect x="30" y="93" rx="0" ry="0" width="293" height="15" />
        <rect x="30" y="117" rx="0" ry="0" width="315" height="15" />
        <rect x="30" y="139" rx="0" ry="0" width="73" height="15" />
    </ContentLoader>
);

export default TableLoader;
