import { Auth0Provider, LogoutOptions, useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import { createContext, useContext, useEffect, useState } from "react";
import { sendAuthenticationEvent } from "../../utils/gtmEvents";
import UserMap from "./UserMap";

const AuthenticationContext = createContext<AuthenticationContext>({});

function sendTokenToServiceWorker(token: string): Promise<ServiceWorkerRegistration> {
    return new Promise<ServiceWorkerRegistration>((resolve, reject) => {
        try {
            navigator.serviceWorker.ready.then(registration => {
                registration?.active?.postMessage({
                    type: "TOKEN",
                    token,
                });
                resolve(registration);
            });
        } catch (e) {
            reject(e);
        }
    });
}

const AuthenticationLogic = ({
    children,
    screen,
    loader,
    ErrorComponent,
    scope,
    audience,
}: AuthenticationLogicProps) => {
    const { user, isAuthenticated, loginWithRedirect, isLoading, error, getAccessTokenSilently } = useAuth0();
    const [token, setToken] = useState<string>();
    const [authUser, setAuthUser] = useState<AuthenticationUser>();

    useEffect(() => {
        if (!isAuthenticated) return;
        (async () => {
            try {
                const token = await getAccessTokenSilently({
                    detailedResponse: true,
                    authorizationParams: { audience, scope },
                });

                if (typeof children !== "function") {
                    await sendTokenToServiceWorker(JSON.stringify(token));
                }

                setToken(token.access_token);
            } catch (e) {
                console.error(e);
                console.log("Error during getting token");
            }
        })();
    }, [isAuthenticated]);

    useEffect(() => {
        if (!user || !token) return;

        const decodedToken = jwtDecode(token) as any;

        if (!decodedToken?.sub) return;

        setAuthUser(UserMap.toDTO(user, decodedToken.sub));
    }, [user, token]);

    if (isLoading) return <>{loader}</>;

    if (error) {
        // Message pattern: `verify-email;${event.user.user_id}`
        const errMessage = error.message.split(";");

        return <ErrorComponent
            message={errMessage[0]}
            userId={errMessage[1]}
            email={errMessage[2]}
        />;
    }

    if (!isAuthenticated) {
        sendAuthenticationEvent("console_user_unauthenticated", { screen: screen || "none" }).finally(() =>
            loginWithRedirect(screen === "register" ? { authorizationParams: { screen_hint: "signup" } } : {})
        );

        return <>{loader}</>;
    }

    sendAuthenticationEvent("console_login_complete");

    if (!token) return <>{loader}</>;

    if (typeof children === "function") {
        return (
            <AuthenticationContext.Provider value={{ token, user: authUser, audience }}>
                {(children as (bag: AuthenticationProviderChildrenProps) => React.ReactNode)({ token })}
            </AuthenticationContext.Provider>
        );
    }

    return (
        <AuthenticationContext.Provider value={{ token, user: authUser, audience }}>
            {children}
        </AuthenticationContext.Provider>
    );
};
const AuthenticationProvider = ({
    children,
    ErrorComponent,
    loader,
    screen,
    audience,
    scope,
    redirectUri,
    ...options
}: AuthenticationProviderProps): JSX.Element => {
    return (
        <Auth0Provider
            useRefreshTokens={true}
            useRefreshTokensFallback={true}
            cacheLocation="localstorage"
            authorizationParams={{
                redirect_uri: redirectUri,
                audience,
                scope,
            }}
            {...options}
        >
            <AuthenticationLogic
                screen={screen}
                loader={loader}
                ErrorComponent={ErrorComponent}
                audience={audience}
                scope={scope}
            >
                {children}
            </AuthenticationLogic>
        </Auth0Provider>
    );
};

export const useAuthentication: AuthenticationHook = () => {
    const { logout: auth0Logout, getAccessTokenWithPopup } = useAuth0();
    const { user, token, audience } = useContext(AuthenticationContext);
    const getAccessToken: AuthenticationGetAccessToken = options => {
        return getAccessTokenWithPopup({
            authorizationParams: {
                audience,
                scope: options?.authorizationParams.scope,
            },
        });
    };
    const logout: typeof auth0Logout = async (options?: LogoutOptions) => {
        await sendAuthenticationEvent("console_user_logout");
        auth0Logout(options);
    };

    return {
        user,
        token,
        logout,
        getAccessToken,
    };
};

export default AuthenticationProvider;
