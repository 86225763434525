import "./Heading.scss";

const Heading: React.FC<HeadingProps> = ({
    id,
    level,
    text,
    size = "headline-2",
    align = "left",
    marginTop = "0",
    marginBottom = "0",
    className = "",
}) => {
    const classes = `${className} align-${align} margin--t-${marginTop} margin--b-${marginBottom} ${size}`;

    switch (level) {
        case "h1":
            return <h1 id={id} className={classes}>{text}</h1>;
        case "h2":
            return <h2 id={id} className={classes}>{text}</h2>;
        case "h3":
            return <h3 id={id} className={classes}>{text}</h3>;
        case "h4":
            return <h4 id={id} className={classes}>{text}</h4>;
        case "h5":
            return <h5 id={id} className={classes}>{text}</h5>;
        default:
            return <h2 id={id} className={classes}>{text}</h2>;
    }
};

export default Heading;
