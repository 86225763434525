import { ClientUtils } from "@scramjet/client-utils";
import { useCallback, useState } from "react";
import { sendErrorEvent, sendEvent } from "../utils/gtmEvents";
import { middlewareUrl } from "../components/MiddlewareClientProvider";

export function usePaymentsPortalCallback(): { portalUrlCallback: () => void; status: PaymentsPortalStatus } {
    const [status, setStatus] = useState<PaymentsPortalStatus>("idle");
    const getPortalUrl = async () => {
        try {
            setStatus("pending");

            const clientUtils = new ClientUtils(middlewareUrl);
            const result = await clientUtils.post<{ portalUrl: string }>(
                "/api/v1/create-portal-session",
                undefined,
                {},
                { json: true, parse: "json" }
            );

            if (result.portalUrl) {
                setStatus("fulfilled");
                sendEvent("console_payments_click");
                window.open(result.portalUrl);
            } else {
                throw new Error("Response missing portal url.");
            }
        } catch (e) {
            console.error(e);
            sendErrorEvent("console_payments_click", e);
            setStatus("rejected");
        }
    };
    const portalUrlCallback = useCallback(() => {
        getPortalUrl();
    }, []);

    return { portalUrlCallback, status };
}
