import AddSequenceButton from "./AddSequenceButton";
import { ClickButton, InternalLinkButton } from "../../../components/Buttons";
import { CaretDownFill, JournalText } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import CLITooltip from "../../../components/CLITooltip";
import { useAddSequenceAvailable } from "../../../hooks/useAddSequenceAvaliable";


export const ShowLogsAddSequenceButtons: React.FC = () => {
    const addSequenceAvailable = useAddSequenceAvailable();

    return (
        <>
            <InternalLinkButton variant="secondary" text={"Show logs"} icon={<JournalText />} to="/space/logs" />
            <AddSequenceButton disabled={!addSequenceAvailable}/>
        </>
    );
};

export const HideLogsAddSequenceButtons: React.FC = () => {
    const navigate = useNavigate();
    const addSequenceAvailable = useAddSequenceAvailable();

    return (
        <>
            <ClickButton variant="secondary" text={"Hide logs"} icon={<JournalText />} onClick={() => navigate(-1)} />
            <AddSequenceButton disabled={!addSequenceAvailable}/>
        </>
    );
};

export const CodeShowLogsButtons: React.FC = () => {

    return (
        <>
            <CLITooltip>
                <ClickButton variant="secondary" icon={<CaretDownFill />} text={"Code"} onClick={() => {}} />
            </CLITooltip>
            <InternalLinkButton variant="secondary" text={"Show logs"} icon={<JournalText />} to="/space/logs" />
        </>
    );
};

