import { useSelector } from "react-redux";
import { changeMode } from "../../store/common/commonSlice";

import { Mask, ZoomIn, ZoomOut } from "react-bootstrap-icons";
import "./A11-panel.scss";
import { RootState, useAppDispatch } from "../../store";

const A11Panel = () => {
    const themeMode = useSelector((state: RootState) => state.common.mode);
    const dispatch = useAppDispatch();

    return (
        <div className="a11-panel">
            <button onClick={() => dispatch(changeMode())} className="btn" aria-labelledby="contrast-label">
                <Mask className="icon--contrast" />
                <span id="contrast-label" className="text" hidden>
                    Set {themeMode ? "normal contrast" : "high contrast"}
                </span>
            </button>
            {/* <button className="btn" aria-labelledby="contrast-label">
                <ZoomIn className="icon--font-bigger" />
                <span id="contrast-label" hidden>
                    Set bigger font
                </span>
            </button>
            <button className="btn" aria-labelledby="contrast-label">
                <ZoomOut className="icon--font-smaller" />
                <span id="contrast-label" hidden>
                    Set smaller font
                </span>
            </button> */}
        </div>
    );
};

export default A11Panel;
