import { PropsWithChildren } from "react";
import "./Container.scss";

const Container: React.FC<PropsWithChildren<ContainerProps>> = ({ children, className, variant }) => {
    return (
        <div className={`container ${variant ? "container--" + variant : ""} ${className ? className : ""} `}>
            {children}
        </div>
    );
};

export default Container;
