import { PayloadAction, createSlice } from "@reduxjs/toolkit";

const initialState: CommonState = {
    mode: "light",
    zoom: 1,
};

export const commonSlice = createSlice({
    name: "common",
    initialState,
    reducers: {
        changeMode: state => {
            state.mode = state.mode === "dark" ? "light" : "dark";
        },
        changeZoom: (state, action: PayloadAction<number>) => {
            state.zoom = action.payload;
        },
    },
});

export const { changeMode, changeZoom } = commonSlice.actions;

export default commonSlice.reducer;
