import "@reach/dialog/styles.css";
import "./Modal.scss";

import { Dialog } from "@reach/dialog";

import { PropsWithChildren } from "react";
import { X } from "react-bootstrap-icons";

const BasicModal: React.FC<PropsWithChildren<BasicModalProps>> = ({
    children,
    variant = "dialog",
    alignContent = "center",
    onClose,
    hiddenX = false,
    open = false,
}) => {
    return (
        <Dialog
            aria-labelledby={`${variant}-modal-title`}
            className={`modal modal--${variant} modal--${alignContent}`}
            isOpen={open}
            onDismiss={onClose}
        >
            {!hiddenX && (
                <button className="modal__btn--icon" aria-label="close" onClick={onClose}>
                    <X size={40} fill="var(--border-dark)" aria-hidden />
                </button>
            )}
            { children }
        </Dialog>
    );
};

export default BasicModal;
