import ButtonGroup from "../Buttons/ButtonGroup";
import "./ActionBar.scss";

const ActionBar: React.FC<ActionBarProps> = ({ variant, title, children }) => {
    const colorVariant = variant ? `action-bar--${variant}` : "";

    return (
        <div className={`action-bar ${colorVariant}`}>
            <div className="action-bar__title">
                <p>{title}</p>
            </div>
            <ButtonGroup gap={1} className="action-bar__buttons">{children}</ButtonGroup>
        </div>
    );
};

export default ActionBar;
