import { Lightbulb } from "react-bootstrap-icons";
import "./Quote.scss";
import { InternalLinkButton } from "../Buttons";

const Quote: React.FC<QuoteProps> = ({ variant = "info", icon, text, wide = false, marginTop = "0", marginBottom = "0", btnText, btnLink = "/" }) => {
    const classes = `quote ${variant ? `quote--${variant} ` : " "}` +
    `${wide ? "quote--wide" : "quote--narrow"}` +
    `${marginTop && " margin--t-" + marginTop}` +
    `${marginBottom && " margin--b-" + marginBottom}`;

    return (
        <div className={classes}>
            {
                icon !== null && <div className="quote__icon">{icon ? icon : <Lightbulb size={25} />}</div>
            }
            <div className="quote__text">{text}</div>
            {
                btnText && <InternalLinkButton addClass="quote__btn" variant="secondary" text={btnText} to={btnLink}/>
            }
        </div>
    );
};

export default Quote;
