import { ClientUtils } from "@scramjet/client-utils";
import { useCallback, useState } from "react";
import { sendErrorEvent, sendEvent } from "../utils/gtmEvents";
import { middlewareUrl } from "../components/MiddlewareClientProvider";

export function useStartTrial(returnUrl: string) {
    const [status, setStatus] = useState<"idle" | "loading" | "success" | "error">("idle");
    const [error, setError] = useState<string | null>(null);
    const callback = useCallback(
        async (nativePriceId: PriceId, shhPriceId: PriceId) => {
            try {
                setStatus("loading");
                setError(null);

                const clientUtils = new ClientUtils(middlewareUrl);

                await clientUtils.post<{ subscription: string }>(
                    "/api/v1/start-trial",
                    { nativePriceId, shhPriceId },
                    {},
                    { json: true, parse: "json" }
                );

                sendEvent("console_start_trial");
                setStatus("success");

                window.location.href = returnUrl;
            } catch (e: any) {
                if (e.status === "409") {
                    sendEvent("console_start_trial");
                    setStatus("success");

                    window.location.href = returnUrl;
                } else {
                    setStatus("error");
                    sendErrorEvent("console_start_trial", e);
                    setError("Error while setting up the trial subscription.");
                }
            }
        },
        [returnUrl]
    );

    return { callback, status, error };
}
