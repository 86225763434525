/* eslint-disable complexity */
import "./AddSequence.scss";
import { useEffect, useState } from "react";
import ContentLoader from "react-content-loader";
import { useSpaceContext } from "../../SpacesPage";
import { useHubsBySpaceId } from "../../../store/hubs/hubsSlice";
import Box from "../../../components/Box";
import Heading from "../../../components/Heading";
import AddSequenceTips from "./AddSequenceTips";
import UseSamples from "./UseSamples";
import AddSeqActions from "./AddSeqActions";
import { UploadSequence } from "../../../components/UploadSequence";
import ErrorInfo from "../../../components/ErrorInfo";
import { useNavigate } from "react-router-dom";
import { ButtonGroup, ClickButton, InternalLinkButton } from "../../../components/Buttons";
import { TrashFill, EyeFill, PlayFill } from "react-bootstrap-icons";
import { RadioGroup } from "../../../components/Inputs";
import Select from "../../../components/Select";
import Table from "../../../components/Table";
import Tooltip from "../../../components/Tooltip";
import ButtonCircle from "../../../components/ButtonCircle";
import { useDeleteSequence } from "../../../hooks/useDeleteSequence";
import SequenceStorePopover from "./SequenceStorePopover";
import SequenceHubPopover from "./SequenceHubPopover";
import StartSequencePopover from "./StartSequencePopover";
import { useOnboarding } from "../../../store/onboarding/onboardingSlice";
import SpaceButtonsPortal from "../../SpacesPage/components/SpaceButtonsPortal";
import { CodeShowLogsButtons } from "../../SpacesPage/components/SpaceButtons";

const SequenceAdd: React.FC = () => {
    const { space } = useSpaceContext();
    const { status, hubs } = useHubsBySpaceId(space.id);
    const [addedSequence, setAddedSequence] = useState<Sequence | StoreItem | null>(null);
    const [addedSequencesArray, setAddedSequencesArray] = useState<(Sequence | StoreItem | null)[]>([]);
    const [hubToUpload, setHubToUpload] = useState<string | null>(null);
    const [uploadLocation, setUploadLocation] = useState("store");
    const { currentPopover } = useOnboarding();
    const { handleDeleteSequence } = useDeleteSequence();
    const navigate = useNavigate();

    useEffect(() => {
        setHubToUpload(hubs[0]?.id);
    }, [hubs]);

    useEffect(() => {
        if (addedSequence) setAddedSequencesArray([...addedSequencesArray, addedSequence]);
    }, [addedSequence]);

    if (status !== "fulfilled" || hubToUpload === null)
        return <Box outline="shadow"><ContentLoader /></Box>;

    const selectHub = (value: any) => setHubToUpload(value);

    return (
        <Box outline="shadow">
            <SpaceButtonsPortal><CodeShowLogsButtons/></SpaceButtonsPortal>
            <Heading size="headline-2" level="h2" text="Add Sequence" />
            <AddSequenceTips />
            {
                hubs.length === 0 && <ErrorInfo title="Couldn't find any hubs" description="Please contact the administrator"/>
            }
            <SequenceStorePopover>
                <SequenceHubPopover>
                    <RadioGroup
                        onChange={(e) => setUploadLocation(e.target.id)}
                        checkedOpt={uploadLocation}
                        legend="1. Choose storage location"
                        name="storage"
                        items={
                            [
                                { id: "store", label: "Store (Cloud Object Storage)" },
                                { id: "hub", label: "Hub (Running Container)" }
                            ]
                        }
                        attentionAnimation={currentPopover === "hubs"}
                    />
                </SequenceHubPopover>
            </SequenceStorePopover>
            {
                uploadLocation === "hub" && <Select
                    variant="big"
                    currentValue={hubToUpload}
                    options={hubs.map((hub) => hub.isConnectionActive
                        ? { opsName: hub.id, disabled: false } : { opsName: hub.id, disabled: true })}
                    label="Select Hub"
                    name="select-hub"
                    onChange={selectHub} />
            }
            <UseSamples />
            <UploadSequence
                spaceId={space.id}
                onSuccess={setAddedSequence}
                hubId={hubToUpload}
                uploadLocation={uploadLocation}
            />
            {
                addedSequencesArray.length !== 0 && <Table rows={["Sequence", "Location", "Actions"]} marginTop={2} marginBottom={5} variant="light">
                    {addedSequencesArray.map((addedSequenceItem, index) => (
                        <tr key={index}>
                            <td>
                                <p>
                                    {addedSequenceItem?.name ? addedSequenceItem.name : addedSequenceItem?.config.name}
                                </p>
                                <p>{addedSequenceItem?.id}</p>
                            </td>
                            <td>
                                <p>{addedSequenceItem?.location}</p>
                            </td>
                            <td>
                                <ButtonGroup gap={2}>
                                    <Tooltip text="Start Sequence" position="above">
                                        <StartSequencePopover addedSequenceId={addedSequenceItem?.id || ""}>
                                            {currentPopover === "start-sequence" && <div className="add_sequence__attention-animation" />}
                                            <ButtonCircle to={`/space/sequences/${addedSequenceItem?.id}/start`}>
                                                <PlayFill className="icon--error" size="2rem" />
                                            </ButtonCircle>
                                        </StartSequencePopover>
                                    </Tooltip>
                                    <Tooltip text="Details" position="above">
                                        <ButtonCircle to={`/space/sequences/${addedSequenceItem?.id}/basic-info`}>
                                            <EyeFill className="icon--error" size="1.8rem" />
                                        </ButtonCircle>
                                    </Tooltip>
                                    <Tooltip text="Delete" position="above">
                                        <ButtonCircle onClick={() => {
                                            handleDeleteSequence(addedSequenceItem?.id || "", space.id, addedSequenceItem?.location || "")
                                                .then(() => {
                                                    const updatedArray = [...addedSequencesArray];

                                                    updatedArray.splice(index, 1);
                                                    setAddedSequence(null);
                                                    setAddedSequencesArray(updatedArray);
                                                });
                                        }}>
                                            <TrashFill className="icon--error" size="1.5rem" />
                                        </ButtonCircle>
                                    </Tooltip>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                </Table>
            }
            <ButtonGroup marginTop={2} marginBottom={2} align="right">
                <ClickButton text="Cancel" variant="secondary" onClick={() => navigate(-1)}/>
                <InternalLinkButton text="Go to sequences" to={"/space/sequences"}/>
            </ButtonGroup>
            <AddSeqActions/>
        </Box>
    );
};

export default SequenceAdd;


