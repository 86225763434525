import A11Panel from "./A11Panel";

import { PropsWithChildren, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import "../scss/main.scss";

let themeClass: string;

const ErrorLayout: React.FC<PropsWithChildren> = ({ children }) => {
    const themeMode = useSelector((state: RootState) => state.common.mode);

    useLayoutEffect(() => {
        document.documentElement.classList.remove(themeClass);
        document.documentElement.classList.add(themeMode);
        themeClass = themeMode;
    }, [themeMode]);

    return (
        <div className="layout layout--headless">
            <A11Panel />
            <main id="main-content" className="main-content main-content--headless">
                { children }
            </main>
        </div>
    );
};

export default ErrorLayout;
