
import { Clipboard, ClipboardCheck } from "react-bootstrap-icons";
import React from "react";
import { useCopy } from "../../hooks/useCopy";
import "./CopyText.scss";
import Tooltip from "../../components/Tooltip";

const CopyText: React.FC<CopyTextProps> = ({ text, className = "" }) => {
    const { isCopied, copyCommand } = useCopy(text);

    return (
        <button onClick={copyCommand} aria-label="Copy the text" className={`copy-button ${className}`}>
            {isCopied ? (
                <Tooltip text="Copied" aria-label="Copied" position="above">
                    <ClipboardCheck size={20} aria-hidden="true" />
                </Tooltip>
            ) : (
                <Tooltip text="Copy" aria-label="Copy" position="above">
                    <Clipboard size={20} aria-hidden="true" />
                </Tooltip>
            )}
        </button>
    );
};

export default CopyText;
