import React from "react";
import ContentLoader from "react-content-loader";

const HubsTableLoader: React.FC = (props) => (
    <ContentLoader
        speed={2}
        width={940}
        height={230}
        viewBox="0 0 940 230"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
        {...props}
    >
        <rect x="0" y="30" rx="0" ry="0" width="282" height="200" />
        <rect x="312" y="30" rx="0" ry="0" width="282" height="200" />
    </ContentLoader>
);

export default HubsTableLoader;
