import { useEffect, useState } from "react";
import Box from "../../components/Box";
import { ButtonGroup, ClickButton, InternalLinkButton } from "../../components/Buttons";
import DialogModal from "../../components/Modal/DialogModal";
import SettingsModalsData from "../../data/modals/SettingsModals.json";
import { useSubscriptions } from "../../store/subscriptinos/subscriptionsSlice";
import HubAddBoxPopover from "./Hub/HubAddBoxPopover";

const HubAddBox: React.FC = () => {
    const [modalRefuseKeyOpen, setModalRefuseKeyOpen] = useState(false);
    const [showPopupBtn, setShowPopupBtn] = useState(false);
    const refuseAccessKeyGenerate = SettingsModalsData["refuse-access-key-generate"];
    const { latestSubscription, paymentsStatus, selfHostedLimit } = useSubscriptions();
    const checkIsAllowedToGenerateKey = () => {
        if (latestSubscription?.status === "trialing") setShowPopupBtn(false);
        else if (paymentsStatus === "inactive" || !selfHostedLimit) setShowPopupBtn(true);
    };

    // TODO:  doesn't refresh the value until reload, 
    // that should rerun if selfHostedLimit is has been changed
    useEffect(() => {
        checkIsAllowedToGenerateKey();
    }, [selfHostedLimit]);

    return (
        <>
            <Box variant="item" outline="dashed" className="hub" title="Self Hosted">
                <HubAddBoxPopover>
                    <ButtonGroup marginBottom={3} marginTop={3} align="center" gap={2} className="hub-add__buttons">
                        {
                            showPopupBtn
                                ? <ClickButton variant="secondary" text="Add Hub" onClick={()=>setModalRefuseKeyOpen(true)}/>
                                : <InternalLinkButton variant="secondary" text="Add Hub" to="/space/hubs/add" />
                        }
                    </ButtonGroup>
                </HubAddBoxPopover>
            </Box>
            <DialogModal
                alignContent="left"
                open={modalRefuseKeyOpen}
                onClose={() => setModalRefuseKeyOpen(false)}
                title={refuseAccessKeyGenerate.title}
                buttons={[
                    <InternalLinkButton
                        key={refuseAccessKeyGenerate.button.link}
                        text={refuseAccessKeyGenerate.button.text}
                        to={refuseAccessKeyGenerate.button.link}
                    />
                ]}
            ><p>{refuseAccessKeyGenerate.text}</p></DialogModal>
        </>
    );
};

export default HubAddBox;
