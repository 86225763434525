import { EyeFill, PlayFill } from "react-bootstrap-icons";
import ButtonCircle from "../../../components/ButtonCircle";
import Table from "../../../components/Table";
import Tooltip from "../../../components/Tooltip";
import { useSequencesByHubId } from "../../../store/sequences/sequencesSlice";
import ButtonGroup from "../../../components/Buttons/ButtonGroup";
import TableLoader from "../../SpacesPage/TableLoader";

const HubSequenceList: React.FC<HubSequenceListProps> = ({ hubId }) => {
    const { sequences: sequenceByHubId, status } = useSequencesByHubId(hubId);

    if (["idle", "pending"].includes(status)) {
        return <TableLoader />;
    }

    return (
        <div className="tab-menu__content">
            {!sequenceByHubId || sequenceByHubId.length === 0 ? (
                "No Sequences on Hub"
            ) : (
                <Table rows={["No.", "Id", "Name", "Actions"]} variant="light">
                    {sequenceByHubId.map((sequence, index) => {
                        const { id, config } = sequence;

                        return (
                            <tr key={`${id}`}>
                                <td>{index + 1}</td>
                                <td>{id}</td>
                                <td>
                                    <span>{config.name}</span>
                                </td>
                                <td>
                                    <ButtonGroup>
                                        <Tooltip text="Start" position="above">
                                            <div>
                                                <ButtonCircle to={`/space/sequences/${id}/start`}>
                                                    <PlayFill className="icon--error" size="2.2rem" />
                                                </ButtonCircle>
                                            </div>
                                        </Tooltip>
                                        <Tooltip text="Details" position="above">
                                            <div>
                                                <ButtonCircle to={`/space/sequences/${id}/basic-info`}>
                                                    <EyeFill className="icon--error" size="2rem" />
                                                </ButtonCircle>
                                            </div>
                                        </Tooltip>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        );
                    })}
                </Table>
            )}
        </div>
    );
};

export default HubSequenceList;
