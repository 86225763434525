import A11Panel from "./A11Panel";

import { PropsWithChildren, useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store";
import { Outlet } from "react-router-dom";
import "../scss/main.scss";
import TopBar from "./TopBar";
import Footer from "./Footer";
import footerLinks from "../data/FooterLinks.json";

let themeClass: string;

const Headless: React.FC<PropsWithChildren> = () => {
    const themeMode = useSelector((state: RootState) => state.common.mode);

    useLayoutEffect(() => {
        document.documentElement.classList.remove(themeClass);
        document.documentElement.classList.add(themeMode);
        themeClass = themeMode;
    }, [themeMode]);

    return (
        <div className="layout layout--headless">
            <TopBar />
            <A11Panel />
            <main id="main-content" className="main-content">
                <Outlet />
                <Footer links={footerLinks} />
            </main>
        </div>
    );
};

export default Headless;
