import { PropsWithChildren } from "react";
import { usePopover, useOnboarding } from "../../../store/onboarding/onboardingSlice";
import { ClickButton } from "../../../components/Buttons";

const SequenceHubPopover: React.FC<PropsWithChildren> = ({ children }) => {
    const { popoverData: hubsPopoverData, displayPopover: displayHub, nextPopover: nextHubPopover, Popover } = usePopover("hubs");

    return (
        <Popover
            title={hubsPopoverData?.title as string}
            text={hubsPopoverData?.text as string}
            position={hubsPopoverData?.position as PositionProps}
            content={
                <ClickButton variant="primary" text="Next" onClick={() => {
                    nextHubPopover();
                }} />
            }
            counter={7}
            display={displayHub}
        >
            {children}
        </Popover>
    );
};

export default SequenceHubPopover;
