import { Outlet, useOutletContext } from "react-router-dom";
import TopSection from "../../layout/TopSection";
import BootstrapIcon from "../../components/BootstrapIcon";
import Container from "../../components/Container";
import SubscriptionInfo from "../../components/InfoBox/SubscriptionInfoBox";
import { useSpaceByIndex } from "../../store/spaces/spacesSlice";
import SpaceButtonsContainer from "./components/SpaceButtonsContainer";
import SpacesRejected from "./components/SpacesRejected";
import SpacesPending from "./components/SpacesPending";
import "./SpacesPage.scss";
import Breadcrumbs from "../../components/Breadcrumbs";

const SpacePage = () => {
    const { status: spacesStatus, space } = useSpaceByIndex(0);

    if (["idle", "pending"].includes(spacesStatus)) {
        return <SpacesPending />;
    }

    if (spacesStatus === "rejected" || !space) {
        return <SpacesRejected />;
    }

    const context: SpaceContext = { space };

    return (
        <Container className="space">
            <TopSection
                spaceId={context.space.id}
                title="Scramjet Cloud Platform&nbsp;Space"
                icon={<BootstrapIcon ico="dashboard" size={25} fill="icon-primary" />}
            >
                <SubscriptionInfo />
            </TopSection>
            <div className="space__above-box">
                <Breadcrumbs />
                <SpaceButtonsContainer/>
            </div>
            <Outlet context={context} />
        </Container>
    );
};

export function useSpaceContext() {
    return useOutletContext<SpaceContext>();
}

export default SpacePage;
