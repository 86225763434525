import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useMatches } from "react-router-dom";
import { Tabs } from "@reach/tabs";
import "@reach/tabs/styles.css";
import "./TabMenu.scss";
import wdslug from "../../utils/wdslug";
import TabTitleBar from "./TabTitleBar";
import TabContent from "./TabContent";

function useRoutingMatchParts() {
    const matches = useMatches();
    const lastMatch = matches[matches.length - 1];
    const pathParts = lastMatch?.pathname.split("/").filter(part => !!part) || [];

    return pathParts;
}

export const TabMenuWithRouter: React.FC<TabMenuProps> = ({
    orientation,
    variant = "default",
    indicators = [],
    tabList,
    tabPanels,
    defaultTabName = "",
    flex = false,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [rootPath, setRootPath] = useState("/");
    const pathParts = useRoutingMatchParts();
    const slugs = tabList.map(wdslug);
    const defaultActiveTab = slugs.includes(defaultTabName) ? defaultTabName : slugs[0];
    const defaultTabIndex = slugs.indexOf(defaultActiveTab);
    const firstIntersection = slugs.filter(element => pathParts.includes(element))[0];
    const tabName = firstIntersection || defaultActiveTab;
    const tabIndex = slugs.indexOf(tabName);
    const tabExists = !!firstIntersection;

    useEffect(() => {
        const newRootPath = tabName ? location.pathname.split("/" + tabName)[0] + "/" : location.pathname + "/";

        setRootPath(newRootPath);
    }, []);

    const handleChange = (newTabIndex: number) => {
        if (tabIndex !== newTabIndex) {
            navigate(`${rootPath}${slugs[newTabIndex]}`);
        }
    };

    return (
        <Tabs
            orientation={orientation}
            className={`tab-menu tab-menu--${orientation} tab-menu--${variant}`}
            index={tabIndex}
            defaultIndex={defaultTabIndex}
            onChange={handleChange}
        >
            <TabTitleBar tabList={tabList} indicators={indicators} variant={variant} />
            <TabContent
                tabExists={tabExists}
                tabPanels={tabPanels}
                slugs={slugs}
                flex={flex}
            />
        </Tabs>
    );
};
