import { MMRestAPI } from "@scramjet/types";

export default class AccessKeysDataMap {
    static toDTO(source: MMRestAPI.GetAccessKeysResponse["accessKeys"][0], spaceId: SpaceId): AccessKeyData {
        return {
            spaceId,
            ...source,
        };
    }
}
