import { useEffect, useState } from "react";
import { useSpaceContext } from ".";
import ConsoleLog from "../../components/ConsoleLog";
import { loadSpaceLogStream } from "../../components/ConsoleLog/loadSpaceLogStream";
import SpaceButtonsPortal from "./components/SpaceButtonsPortal";
import { HideLogsAddSequenceButtons } from "./components/SpaceButtons";
import ConsoleLogLoader from "../../components/ConsoleLog/ConsoleLogLoader";

const SpaceLogs: React.FC = () => {
    const { space } = useSpaceContext();
    const [logStream, setLogStream] = useState<ReadableStream>();

    useEffect(() => {
        if (!logStream && !!space.id) {
            (async () => {
                try {
                    setLogStream(await loadSpaceLogStream(space.id));
                } catch (e) {
                    console.log(e);
                }
            })();
        }
    }, [space]);

    return (
        <>
            {logStream ? <ConsoleLog logStream={logStream} autoscroll={false} /> : <ConsoleLogLoader />}
            <SpaceButtonsPortal>
                <HideLogsAddSequenceButtons />
            </SpaceButtonsPortal>
        </>
    );
};

export default SpaceLogs;
