import "./UploadSequence.scss";
import { useState } from "react";
import { useAppDispatch } from "../../store";
import { fetchSpaceSequences, sendSequence } from "../../store/sequences/sequencesSlice";
import { sendItemToStore } from "../../store/storeItems/storeItemsSlice";
import { UploadLayer } from "../UploadLayer";
import { useToast } from "../Toast/Toasts";
import { labelText, SuccessTmplState, ErrorTmplState, IdleTmplState, UploadingTmplState, DraggedTmplState } from "./UploadTmplStates";

export const UploadSequence: React.FC<UploadSequenceProps> = ({ spaceId, hubId, uploadLocation, onSuccess, className = "" }) => {
    const [state, setState] = useState<UploadSequenceState>("idle");
    const [error, setError] = useState("");
    const showToast = useToast();
    const dispatch = useAppDispatch();
    const uploadFiles = async (files: FileList | null) => {
        setError("");
        setState("idle");

        if (!files) return;

        if (files.length !== 1) {
            showToast({ text: "File not uploaded. You can send only one file at a time.", variant: "error" });
            setError("You can send only one file at a time.");
            setState("error");
            return;
        }

        const filename = files[0].name;
        const extension = filename.substring(filename.length - 6, filename.length);
        const type = files[0].type;
        const file = files[0];

        if (!["application/gzip", "application/x-gzip"].includes(type) || extension !== "tar.gz") {
            showToast({ text: "File not uploaded. It has to be tar.gz archive.", variant: "error" });
            setError("File has to be tar.gz archive.");
            setState("error");
            return;
        }

        try {
            setState("uploading");

            const sendResults = uploadLocation === "store"
                ? await dispatch(sendItemToStore({ spaceId, file })).unwrap()
                : await dispatch(sendSequence({ spaceId, hubId, file })).unwrap();

            await dispatch(fetchSpaceSequences({ spaceId, refetch:true }));

            showToast({ text: "Sequence added to the sequences list.", variant: "success" });
            setState("success");
            if (typeof onSuccess === "function") onSuccess(sendResults);
        } catch (e: any) {
            showToast({ text: "Error while uploading sequence", variant: "error" });
            setError(e?.message || "Unknown error");
            setState("error");
        }
    };

    function renderDragged() { return <DraggedTmplState />; }

    function renderState() {
        switch (state) {
            case "uploading":
                return <UploadingTmplState />;
            case "success":
                return <SuccessTmplState />;
            case "error":
                return <ErrorTmplState error={error}/>;
            case "idle":
            default:
                return <IdleTmplState />;
        }
    }

    return (
        <UploadLayer clickable={true} onSuccess={uploadFiles} className={className} label={labelText} id="id-sequence">
            {({ isDragged }) => (
                <>
                    <div className={`upload-sequence upload-sequence--${state} ${
                        isDragged ? "upload-sequence--fade" : ""
                    }`}
                    >
                        {isDragged ? renderDragged() : renderState()}
                    </div>
                </>
            )}
        </UploadLayer>
    );
};
