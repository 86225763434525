import { format } from "date-fns";
import Tooltip from "../../components/Tooltip";

const DateTime: React.FC<DateTimeProps> = ({ label, started, created }) => {
    const createdDate = new Date(created);

    return (
        <div className="inline-flex">
            { label && <p>{label}&nbsp;</p> }
            <Tooltip text={`ISO: ${started}`} position="below">
                <p>{`${format(createdDate, "kk:mm:ss")} - ${format(createdDate, "P")}`}</p>
            </Tooltip>
        </div>
    );
};

export default DateTime;
