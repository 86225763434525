import Container from "../../../components/Container";
import ErrorInfoDefault from "../../../components/ErrorInfo/ErrorInfoDefault";

const SpacesRejected: React.FC = () => (
    <Container>
        <ErrorInfoDefault />
    </Container>
);

export default SpacesRejected;
